import React, {useState, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, set, push, get } from 'firebase/database';
import toast from '../utils/toast';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    boxShadow: 'none',
    backgroundColor: '#202024',
    width: '25.4375rem',
    height: '100%',
    maxHeight: '26rem'
  },
}));

const StyledForm = styled('form')({
  marginTop: '2rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

const StyledButton = styled(Button)(({ theme }) => ({
  height: '50px',
  border: 0,
  backgroundColor: '#005BDA',
  color: theme.palette.common.white,
  fontWeight: 'bold', boxShadow: 'none !important',

  padding: '0 1.25rem',
  borderRadius: '5rem',
  marginTop: '1rem',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transition: 'background-color 0.2s',
  },
}));

export function NewInvestmentModal({ open, onClose }) {
  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm();

  const [userId, setUserId] = React.useState(null);

  React.useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleCreateNewInvestment = async (data) => {
    const { date, value } = data;
  
    try {
      const db = getDatabase();
      const transactionsRef = ref(db, `users/${userId}/transactions`);
      const investmentSummaryRef = ref(db, `users/${userId}/investmentSummary`);
  
      const newInvestmentRef = push(transactionsRef);
      await set(newInvestmentRef, {
        description: 'Investimento',
        price: value,
        estimated: 0, 
        group: 'Investimentos',
        category: 'Investimento',
        type: 'Investment',
        date,
      });
      get(investmentSummaryRef).then((snapshot) => {
        if (snapshot.exists()) {
          const currentTotalInvested = snapshot.val().totalInvested || 0;
          const currentTotalDirected = snapshot.val().totalDirected || 0;
          const updatedTotalInvested = currentTotalInvested + parseFloat(value);
          const updatedBalance = updatedTotalInvested - currentTotalDirected;
          
          set(investmentSummaryRef, {
            ...snapshot.val(),
            totalInvested: updatedTotalInvested,
            balance: updatedBalance
          });
        } else {
          set(investmentSummaryRef, {
            totalInvested: parseFloat(value),
            totalDirected: 0,
            balance: parseFloat(value)
          });
        }
      });
  
      reset();
      onClose();
      toast({
        type: 'success',
        text: 'Investimento registrado com sucesso!'
      });
    } catch (e) {
      toast({
        type: 'error',
        text: 'Houve um erro durante a operação'
      });
    }
  };
  
  
  return (
    <StyledDialog
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'black',
            opacity: 0.75,
          },
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Novo Investimento
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ flexGrow: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <StyledForm
          onSubmit={handleSubmit(handleCreateNewInvestment)}
        >
          <TextField
            label="Data"
            type="date"
            fullWidth
            required
            defaultValue={new Date().toISOString().substr(0, 10)} 
            {...register('date')}
          />
          <TextField
            label="Valor"
            type="number"
            fullWidth
            required
            {...register('value')}
          />
          <StyledButton fullWidth type="submit" disabled={isSubmitting} variant="contained">
            Cadastrar
          </StyledButton>
        </StyledForm>
      </DialogContent>
    </StyledDialog>
  );
}
