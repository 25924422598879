import React from 'react';
import { Grid } from '@mui/material';
import Scrollbar from '../../components/scrollbar/Scrollbar';

export default function StandardLayout({ leftComponent, rightComponent, propMargin, isBackground = false }) {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      {isBackground && (
        <>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: 'url(assets/illustrations/graphic.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              zIndex: -1,
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              zIndex: -1,
            }}
          />
        </>
      )}
      <Scrollbar
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          width: '100%',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Grid container spacing={3} sx={{ width: '100%' }}>
          <Grid item xs={12} sm={12} md={6} sx={{ marginLeft: propMargin }}>
            {leftComponent}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {rightComponent}
          </Grid>
        </Grid>
      </Scrollbar>
    </div>
  );
}
