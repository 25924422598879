import React, { useState, useEffect } from 'react';
import { ref, get, update, set } from 'firebase/database';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useNavigate } from 'react-router-dom';

import {
  Card,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  useTheme,
  Paper,
  Box,
  Container,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material';
import { AccountBalanceOutlined, PeopleAlt } from '@mui/icons-material';
import { database } from '../../services/firebase';
import useResponsive from '../../hooks/useResponsive';


const Returns = () => {
  const navigate = useNavigate();

  const [returns, setReturns] = useState(0);

  const [realInterest, setRealInterest] = useState({});

  const [monthlyInterest, setMonthlyInterest] = useState({});

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    fetchReturns();
    fetchRealInterest();
    fetchMonthlyInterest();
  }, []);

  const fetchRealInterest = async () => {
    const jurosRealRef = ref(database, 'admin/jurosReal');
    const jurosRealSnapshot = await get(jurosRealRef);

    if (jurosRealSnapshot.exists()) {
      const jurosRealData = jurosRealSnapshot.val();
      setRealInterest(jurosRealData);
    }
  };

  const fetchReturns = async () => {
    const returnsRef = ref(database, 'admin/returns');
    const returnsSnapshot = await get(returnsRef);

    if (returnsSnapshot.exists()) {
      const returnsData = returnsSnapshot.val();
      setReturns(returnsData);
    }
  };

  const fetchMonthlyInterest = async () => {
    const monthlyInterestRef = ref(database, 'admin/monthlyInterestNominal');
    const monthlyInterestSnapshot = await get(monthlyInterestRef);

    if (monthlyInterestSnapshot.exists()) {
      const monthlyInterestData = monthlyInterestSnapshot.val();
      setMonthlyInterest(monthlyInterestData);
    }
  };

  const handleSaveReturns = async () => {
    const adminRef = ref(database, 'admin/returns');
    try {
      await set(adminRef, returns);
      updateRealInterest();
      updateMonthlyInterest();
      showSnackbar('success', 'Clusters salvos com sucesso!');
    } catch (error) {
      showSnackbar('error', 'Erro ao salvar os clusters. Tente novamente mais tarde.');
    }
  };

  const updateRealInterest = async () => {
    const annualInterestRate = +returns; // Pega o valor único do retorno
  
    const adminInflationRef = ref(database, 'admin/inflationRate');
    const adminInflationSnapshot = await get(adminInflationRef);
    const inflationRate = adminInflationSnapshot.val();
    const inflationRateValue = parseInt(inflationRate, 10);
  
    const nominalInterestRate = +(((1 + annualInterestRate / 100) - 1)).toFixed(3);
    const realInterestRate = ((1 + nominalInterestRate) / ((1 + (inflationRateValue / 100))) - 1).toFixed(3);
  
    const jurosRealRef = ref(database, 'admin/jurosReal');
    await set(jurosRealRef, realInterestRate);
    setRealInterest(realInterestRate);
  };

  const updateMonthlyInterest = async () => {
    const annualInterestRate = +returns; // Pega o valor único do retorno
  
    // Calcula a taxa de juro nominal mensal
    const monthlyNominalInterestRate = (1 + annualInterestRate / 100) ** (1 / 12) - 1;
  
    // Atualiza a taxa de juro nominal mensal no Firebase
    const monthlyInterestRef = ref(database, 'admin/monthlyInterestNominal');
    await set(monthlyInterestRef, monthlyNominalInterestRate.toFixed(6)); // Ajuste o número de casas decimais conforme necessário
    setMonthlyInterest(monthlyNominalInterestRate.toFixed(6));
  };

  const theme = useTheme();
  const formatValueWithPercentage = (value) => (value ? `${value}%` : '');

  const showSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem', gap: '1rem' }}>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} mb={3}>
          <Typography variant='h3'>Retornos</Typography>
        </Box>
      </Box>
  
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={4}>
          <Paper
            sx={{ 
              boxShadow: theme.customShadows.z8,
              minHeight: '200px',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '1px'
            }}
          >
            <Typography variant="h4" mt={1}>
              Retorno Ascenda
            </Typography>
            <Box 
              sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                gap: '1rem',
                width: '100%',
                justifyContent: 'center',
                marginTop: '1rem'
              }}
            >
            <TextField
              variant="standard"
              value={returns}
              onChange={(e) => setReturns(e.target.value)}
              label="Retorno"
            />
            <IconButton color="primary" onClick={handleSaveReturns}>
                <DoneAllIcon/>
            </IconButton>
          </Box>
          </Paper>
        </Grid>
  
        <Grid item xs={12} md={4}>
          <Paper
            sx={{ 
              boxShadow: theme.customShadows.z8,
              minHeight: '200px',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '1px'
            }}
          >
            <Typography variant="h4" mt={1}>
              Juros Real
            </Typography>
            <Box sx={{ marginTop: '1rem' }}>
              <Typography variant="h6">
                {formatValueWithPercentage(realInterest)}
              </Typography>
            </Box>
          </Paper>
        </Grid>
  
        <Grid item xs={12} md={4}>
          <Paper
            sx={{ 
              boxShadow: theme.customShadows.z8,
              minHeight: '200px',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '1px'
            }}
          >
            <Typography variant="h4" mt={1}>
              Juros Nominal Mensal
            </Typography>
            <Box sx={{ marginTop: '1rem' }}>
              <Typography variant="h6">
                {formatValueWithPercentage(monthlyInterest)}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
  
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert variant='filled' onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Returns;
