import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Select, MenuItem, IconButton, Tooltip } from '@mui/material';
import { Save as SaveIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { fCurrency, unformatedValue } from '../../../../../utils/formatNumber';
import { investmentCategories } from '../../../../../utils/constants';

const InvestmentRow = ({ investment, editedInvestments, setEditedInvestments, handleInvestmentSubmit, handleDeleteInvestment }) => (
  <tr key={investment.id}>
    <td>
      <TextField
        className="my-custom-textfield"
        variant='standard'
        value={(editedInvestments.find((inv) => inv.id === investment.id)?.investmentName || investment.investmentName)}
        onChange={(e) => {
          const updatedInvestment = {
            ...investment,
            investmentName: e.target.value
          };
          setEditedInvestments((prev) => {
            const index = prev.findIndex((inv) => inv.id === investment.id);
            if (index !== -1) {
              prev[index] = updatedInvestment;
              return [...prev];
            }
            return [...prev, updatedInvestment];
          });
        }}
      />
    </td>
    <td>
      <Select
        className="my-custom-select"
        variant='standard'
        value={editedInvestments.find((inv) => inv.id === investment.id)?.investmentType || investment.investmentType}
        onChange={(e) => {
          const updatedInvestment = {
            ...investment,
            investmentType: e.target.value
          };
          setEditedInvestments((prev) => {
            const index = prev.findIndex((inv) => inv.id === investment.id);
            if (index !== -1) {
              prev[index] = updatedInvestment;
              return [...prev];
            }
            return [...prev, updatedInvestment];
          });
        }}
      >
        {investmentCategories.map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </td>

    <td >
      <TextField
        className="my-custom-textfield"
        variant='standard'
        value={editedInvestments.find((inv) => inv.id === investment.id)?.taxaContratada || investment.taxaContratada}
        onChange={(e) => {
          const updatedInvestment = {
            ...investment,
            taxaContratada: e.target.value
          };
          setEditedInvestments((prev) => {
            const index = prev.findIndex((inv) => inv.id === investment.id);
            if (index !== -1) {
              prev[index] = updatedInvestment;
              return [...prev];
            }
            return [...prev, updatedInvestment];
          });
        }}
      />
    </td>

    <td>
      <TextField
        className="my-custom-textfield"
        variant='standard'
        type="date"
        value={editedInvestments.find((inv) => inv.id === investment.id)?.dataAplicacao || investment.dataAplicacao}
        onChange={(e) => {
          const updatedInvestment = {
            ...investment,
            dataAplicacao: e.target.value
          };
          setEditedInvestments((prev) => {
            const index = prev.findIndex((inv) => inv.id === investment.id);
            if (index !== -1) {
              prev[index] = updatedInvestment;
              return [...prev];
            }
            return [...prev, updatedInvestment];
          });
        }}
      />
    </td>
    <td>
      <TextField
        className="my-custom-textfield"
        variant='standard'
        type="date"
        value={editedInvestments.find((inv) => inv.id === investment.id)?.dataVencimento || investment.dataVencimento}
        onChange={(e) => {
          const updatedInvestment = {
            ...investment,
            dataVencimento: e.target.value
          };
          setEditedInvestments((prev) => {
            const index = prev.findIndex((inv) => inv.id === investment.id);
            if (index !== -1) {
              prev[index] = updatedInvestment;
              return [...prev];
            }
            return [...prev, updatedInvestment];
          });
        }}
      />
    </td>

    <td>
      <TextField
        className="my-custom-textfield"
        variant='standard'
        value={fCurrency(editedInvestments.find((inv) => inv.id === investment.id)?.investmentValue || investment.investmentValue)}
        onChange={(e) => {
          const updatedInvestment = {
            ...investment,
            investmentValue: unformatedValue(e.target.value)
          };
          setEditedInvestments((prev) => {
            const index = prev.findIndex((inv) => inv.id === investment.id);
            if (index !== -1) {
              prev[index] = updatedInvestment;
              return [...prev];
            }
            return [...prev, updatedInvestment];
          });
        }}
      />
    </td>

    <td align='center'>
      <TextField
        className="my-custom-textfield"
        variant='standard'
        value={editedInvestments.find((inv) => inv.id === investment.id)?.quantidade || investment.quantidade}
        onChange={(e) => {
          const updatedInvestment = {
            ...investment,
            quantidade: e.target.value
          };
          setEditedInvestments((prev) => {
            const index = prev.findIndex((inv) => inv.id === investment.id);
            if (index !== -1) {
              prev[index] = updatedInvestment;
              return [...prev];
            }
            return [...prev, updatedInvestment];
          });
        }}
      />
    </td>
    <td>
      <TextField
        className="my-custom-textfield"
        variant='standard'
        value={fCurrency(editedInvestments.find((inv) => inv.id === investment.id)?.ValorLiquido || investment.ValorLiquido)}
        onChange={(e) => {
          const updatedInvestment = {
            ...investment,
            ValorLiquido: unformatedValue(e.target.value)
          };
          setEditedInvestments((prev) => {
            const index = prev.findIndex((inv) => inv.id === investment.id);
            if (index !== -1) {
              prev[index] = updatedInvestment;
              return [...prev];
            }
            return [...prev, updatedInvestment];
          });
        }}
      />
    </td>
    <td>
      <Tooltip title="Salvar investimento">
        <IconButton
          size='small'
          color="primary"
          onClick={() => {
            const investmentToSubmit = editedInvestments.find((inv) => inv.id === investment.id) || investment;
            handleInvestmentSubmit(investmentToSubmit);
          }}
        >
          <SaveIcon sx={{
            width: '16px',
            height: '16px'
          }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Deletar investimento">
        <IconButton
          size='small'
          color="secondary"
          onClick={() => handleDeleteInvestment(investment.id)}
        >
          <DeleteIcon
            sx={{
              width: '16px',
              height: '16px'
            }} />
        </IconButton>
      </Tooltip>
    </td>
  </tr>
);

InvestmentRow.propTypes = {
  investment: PropTypes.object.isRequired,
  editedInvestments: PropTypes.array.isRequired,
  setEditedInvestments: PropTypes.func.isRequired,
  handleInvestmentSubmit: PropTypes.func.isRequired,
  handleDeleteInvestment: PropTypes.func.isRequired
};

export default InvestmentRow;
