import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { set, ref, get } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import RetirementSection from '../forms/RetirementSection';
import EmergencyFundSection from '../forms/EmergencyFundSection';
import HomePurchaseSection from '../forms/HomePurchaseSection';
import ChildrenEducationSection from '../forms/ChildrenEducationSection';
import CarSection from '../forms/CarSection';
import TravelLeisureSection from '../forms/TravelLeisureSection';
import DebtSection from '../forms/DebsSection';
import SuccessionSection from '../forms/SuccessionSection';
import OtherGoalsSection from '../forms/OtherGoalsSection';
import IntroductionGoalForm from '../forms/IntroductionGoalForm';
import { auth, database } from '../../services/firebase';
import CustomButton from '../button/CustomButton';
import AddCustomGoalForm from '../forms/AddCustomGoalForm';
import CashBoxForm from '../forms/CashBoxForm';

export default function GoalsForm({
  objectiveName,
  onNextTab,
  goalsDataForm,
  userIdParams,
  onDataSaved,
  currentGoalData,
  isCustomGoal,
}) {
  const [emergencyFundData, setEmergencyFundData] = useState({});
  const [retirementData, setRetirementData] = useState({});
  const [homePurchaseData, setHomePurchaseData] = useState({});
  const [childrenEducationData, setChildrenEducationData] = useState({});
  const [childrenCollegeData, setChildrenCollegeData] = useState({});
  const [travelLeisureData, setTravelLeisureData] = useState({});
  const [debtData, setDebtData] = useState('');
  const [successionData, setSuccessionData] = useState('');
  const [otherGoalsData, setOtherGoalsData] = useState({});
  const [isAddingCustomGoal, setIsAddingCustomGoal] = useState(false);

  const areMainGoalsFilled =
    goalsDataForm.retirement &&
    Object.keys(goalsDataForm.retirement).length > 0 &&
    goalsDataForm.emergencyFund &&
    Object.keys(goalsDataForm.emergencyFund).length > 0;

  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);

  const sectionsMap = {
    introduction: <IntroductionGoalForm />,
    emergencyFund: (
      <EmergencyFundSection
        userIdParams={userIdParams}
        formData={emergencyFundData}
        setFormData={setEmergencyFundData}
        onDataSaved={onDataSaved}
      />
    ),
    retirement: (
      <RetirementSection
        userIdParams={userIdParams}
        formData={retirementData}
        setFormData={setRetirementData}
        onDataSaved={onDataSaved}
      />
    ),
    homePurchase: (
      <HomePurchaseSection
        userIdParams={userIdParams}
        formData={homePurchaseData}
        setFormData={setHomePurchaseData}
        onDataSaved={onDataSaved}
      />
    ),
    childrenEducation: (
      <ChildrenEducationSection
        userIdParams={userIdParams}
        formData={childrenEducationData}
        setFormData={setChildrenEducationData}
        onDataSaved={onDataSaved}
      />
    ),
    car: (
      <CarSection
        userIdParams={userIdParams}
        formData={childrenCollegeData}
        setFormData={setChildrenCollegeData}
        onDataSaved={onDataSaved}
      />
    ),
    travelLeisure: (
      <TravelLeisureSection
        userIdParams={userIdParams}
        formData={travelLeisureData}
        setFormData={setTravelLeisureData}
        onDataSaved={onDataSaved}
      />
    ),
    debt: (
      <DebtSection
        userIdParams={userIdParams}
        formData={debtData}
        setFormData={setDebtData}
        onDataSaved={onDataSaved}
      />
    ),
    succession: (
      <SuccessionSection
        userIdParams={userIdParams}
        formData={successionData}
        setFormData={setSuccessionData}
        onDataSaved={onDataSaved}
      />
    ),
    otherGoals: (
      <OtherGoalsSection
        userIdParams={userIdParams}
        formData={otherGoalsData}
        setFormData={setOtherGoalsData}
        onDataSaved={onDataSaved}
      />
    ),
    addCustomGoal: (
      <AddCustomGoalForm userIdParams={userIdParams} onDataSaved={onDataSaved} currentGoalData={currentGoalData} />
    ),

    box: <CashBoxForm userIdParams={userIdParams} onDataSaved={onDataSaved} />,
  };

  const renderSection = sectionsMap[objectiveName] || (objectiveName && sectionsMap.addCustomGoal);

  useEffect(() => {
    const fetchGoals = async (userId) => {
      try {
        const userGoalsRef = ref(database, `users/${userId}/goals`);
        const goalsSnapshot = await get(userGoalsRef);
        if (goalsSnapshot.exists()) {
          const goalsData = goalsSnapshot.val();
          setEmergencyFundData(goalsData.reservaEmergência || {});
          setRetirementData(goalsData.aposentadoria || {});
          setHomePurchaseData(goalsData.compraImovel || {});
          setChildrenEducationData(goalsData.educacaoFilhos || {});
          setChildrenCollegeData(goalsData.educacaoUniversitaria || {});
          setTravelLeisureData(goalsData.viagensLazer || {});
          setDebtData(goalsData.dividas || {});
          setSuccessionData(goalsData.sucessao || {});
          setOtherGoalsData(goalsData.outrosObjetivos || {});
        }
      } catch (error) {
        console.error(error);
      }
    };
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const userIdToUse = userIdParams || user.uid;
        setUserId(userIdToUse);
        fetchGoals(userIdToUse);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        {renderSection}
      </Box>
    </>
  );
}

GoalsForm.propTypes = {
  objectiveName: PropTypes.string.isRequired,
};
