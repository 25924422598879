import { Grid, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import PropTypes from 'prop-types';
import { auth, database } from '../../services/firebase';
import CustomButton from '../button/CustomButton';
import CustomContainer from '../CustomContainer';

const capitalizeFirstLetter = (string) => {
  const words = string.split(' ');
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
};

const IntroductionDNAFinancial = (props) => {
  const [userName, setUserName] = useState('');
  const theme = useTheme();

  useEffect(() => {
    const fetchUserName = async (userId) => {
      try {
        const userRef = ref(database, `users/${userId}`);
        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userName = capitalizeFirstLetter(userData.name);
          setUserName(userName);
        }
      } catch (error) {
        console.error('Error fetching user name:', error);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserName(user.uid);
      } else {
        console.log('No user is signed in');
      }
    });

    return unsubscribe;
  }, []);

  const stepProfile = () => {
    props.changeTab();
  };

  return (
    <CustomContainer
      style={{
        marginTop: '1rem',
      }}
    >
      <Grid container spacing={2}>
        {/* Coluna Esquerda: MessageIntroduction */}

        {/* Coluna Direita: Botões */}
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CustomButton
            sx={{
              textTransform: 'none',
              borderRadius: '1.4375rem',
              padding: '0.575rem',
              width: '9.5rem',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                boxShadow: 'none !important',
              },
            }}
            variant="primary"
            onClick={props.onNext}
          >
            Iniciar
          </CustomButton>

          <CustomButton
            size="small"
            variant="primary"
            sx={{ fontSize: '0.752rem', width: '9.5rem' }}
            onClick={stepProfile}
          >
            Ir direto para investimentoses
          </CustomButton>
        </Grid>
      </Grid>
    </CustomContainer>
  );
};

IntroductionDNAFinancial.propTypes = {
  onNext: PropTypes.func,
};

export default IntroductionDNAFinancial;
