import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, Box } from '@mui/material';
import { set, ref, get } from 'firebase/database';
import { auth, database } from '../../services/firebase';
import toast from '../../utils/toast';
import CustomButton from '../button/CustomButton';
import SvgColor from '../svg-color/SvgColor';
import MyTextField from '../text-field/MyTextField';
import { fCurrency, unformatedValue } from '../../utils/formatNumber';
import { calculateCustomGoalContributions } from '../../utils/resolvers';

export default function EditCustomGoalForm({ userIdParams }) {
  const { goalId, clientId } = useParams();
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [goalName, setGoalName] = useState('');
  const [targetValue, setTargetValue] = useState('');
  const [timeFrame, setTimeFrame] = useState('');
  const [registeredContributions, setRegisteredContributions] = useState([]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(clientId || user.uid);
      }
    });
    if (goalId && clientId) {
      const goalRef = ref(database, `users/${clientId}/goals/customGoals/${goalId}`);
      get(goalRef).then((snapshot) => {
        if (snapshot.exists()) {
          const goalData = snapshot.val();
          setGoalName(goalData.name);
          setTargetValue(fCurrency(goalData.targetValue));
          setTimeFrame(goalData.timeFrame);
          setRegisteredContributions(goalData.investments?.registeredContributions || []);
        }
      });
    }
  }, [userIdParams, goalId, clientId]);

  const handleSave = async () => {
    if (!userId) return;

    const goalRef = ref(database, `users/${userId}/goals/customGoals/${goalId}`);

    try {
      const updatedGoal = {
        name: goalName,
        targetValue: unformatedValue(targetValue),
        timeFrame,
        investments: {
          registeredContributions,
        },
      };

      await set(goalRef, updatedGoal);
      toast({
        type: 'success',
        text: 'Objetivo extra atualizado com sucesso!',
      });

      // Chame a função para calcular as contribuições do objetivo customizado
      await calculateCustomGoalContributions(userId, goalId);
    } catch (error) {
      console.log(error);
      toast({
        type: 'error',
        text: 'Erro ao atualizar o objetivo extra',
      });
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <SvgColor src="/assets/images/goals/addCustomGoal.svg" sx={{ width: '10rem', height: '10rem' }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Typography
            sx={{
              fontSize: '1.5625rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 1.7,
            }}
          >
            Altere seu Objetivo Customizado
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
            Você tem algum objetivo financeiro em mente? Pode ser o carro dos sonhos, abrir uma empresa, algum hobby ou
            qualquer projeto que tenha valor financeiro.
          </Typography>

          <Typography variant="body1">Dê um nome para seu objetivo</Typography>
          <MyTextField value={goalName} onChange={(e) => setGoalName(e.target.value)} required />

          <Typography>Qual o valor total desse projeto?</Typography>
          <MyTextField
            type="text"
            value={targetValue}
            onChange={(e) => setTargetValue(fCurrency(unformatedValue(e.target.value)))}
            required
          />

          <Typography mt={2}>Tempo para Atingir (em anos)</Typography>
          <MyTextField type="number" value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)} required />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '1rem' }}>
        <CustomButton variant="custom" sx={{ width: '12rem', marginRight: '1rem' }} onClick={() => navigate(-1)}>
          Voltar
        </CustomButton>
        <CustomButton variant="primary" sx={{ width: '12rem' }} onClick={handleSave}>
          Salvar
        </CustomButton>
      </Box>
    </Container>
  );
}
