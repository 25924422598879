// hooks/useLogin.js
import { useState } from 'react';
import { GoogleAuthProvider, signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { ref, get, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { auth, database } from '../services/firebase';
import { getCurrentDate } from '../utils/formatTime';

import { useUser } from '../context/UserContext';

const useLogin = () => {
    const [error, setError] = useState(null);

  const navigate = useNavigate();
  const { setCurrentUser, setRole, setIsPremium } = useUser();

    const loginWithGoogle = async () => {
      const provider = new GoogleAuthProvider();
      try {
        await signInWithPopup(auth, provider);
        const userId = auth.currentUser.uid;
  
        // busca o usuario para verificar se o mesmo é admin
        const userSnapshot = ref(database, `users/${userId}`);
        const userExists = await get(userSnapshot);
  
        let user = false;
        user = userExists;
  
        if(user.val()) {
          setRole(user.val().role);
          setIsPremium(user.val().premium === 'yes');
          setCurrentUser(userId)
        }
        if (!user.exists()) {
          await set(ref(database, `users/${userId}`), {
            id: userId,
            name: auth.currentUser.displayName,
            email: auth.currentUser.email,
            role: 'cliente',
            premium: 'not',
            createdDate: getCurrentDate()
          });
        }
  
         // Check if the user has a registered patrimony
         const financialDataRef = ref(database, `users/${userId}/patrimonio`);
         const financialDataSnapshot = await get(financialDataRef);
        if (financialDataSnapshot.exists()) {
          navigate('/inicio'); 
        } else {
          navigate('/home');
        }
      } catch (error) {
        switch (error.code) {
          case 'auth/user-not-found':
            window.alert(error);
            break;
          default:
            console.error(error);
        }
        
      }
    };
  
    const loginWithFacebook = async () => {
      const provider = new FacebookAuthProvider();
      try {
        await signInWithPopup(auth, provider);
        const userId = auth.currentUser.uid;
  
        // busca o usuario para verificar se o mesmo é admin
        const userSnapshot = ref(database, `users/${userId}`);
        const userExists = await get(userSnapshot);
  
        let user = false;
        user = userExists;
  
        if (!user.exists()) {
          await set(ref(database, `users/${userId}`), {
            id: userId,
            name: auth.currentUser.displayName,
            email: auth.currentUser.email,
            role: 'cliente',
            premium: 'not',
            createdDate: getCurrentDate()
          });
        }
  
        // Verifica se o usuário tem um perfil cadastrado
        const userProfileRef = ref(database, `users/${userId}/profile`);
        const userProfileSnapshot = await get(userProfileRef);
  
        if (userProfileSnapshot.exists() || (user && user.val().role === 'admin')) {
          navigate('/dashboard/app'); // Redireciona para a URL /dashboard/app se o perfil estiver cadastrado
        } else {
          navigate('/introduction'); // Redireciona para a URL /profile se o perfil não estiver cadastrado
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Outras funções e estados

    return { loginWithGoogle, loginWithFacebook, error };
};

export default useLogin;
