import { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Box, Typography, TextField, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import toast from '../../../utils/toast';
import CustomButton from '../../../components/button/CustomButton';
import { auth } from '../../../services/firebase';
import { useFirebaseError } from '../../../hooks/useFirebaseError';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const { friendlyError, translateError } = useFirebaseError();
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleForgotPassword = async () => {
    if (!validateEmail(email)) {
      toast({
        type: 'error',
        text: 'Por favor, insira um email válido.',
        duration: 3000,
      });
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      toast({
        type: 'success',
        text: 'Email de redefinição de senha enviado com sucesso!',
        duration: 3000,
      });
      navigate('/login');
    } catch (error) {
      translateError(error);
      toast({
        type: 'error',
        text:
          friendlyError ||
          'Houve erro durante a operação. Entre em contato através do email alex@scendavoce.com.br ou tente novamente mais tarde!',
        duration: 3000,
      });
    }
  };

  return (
    <>
      <Helmet>Resetar senha</Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            p: 6,
          }}
        >
          <Box
            sx={{
              background:
                'linear-gradient(270deg, rgba(22, 22, 26, 0.39) 3.17%, rgba(22, 22, 26, 0.80) 51.48%, rgba(22, 22, 26, 0.00) 98.81%)',
              marginBottom: '2rem',
            }}
          >
            <img src="/assets/illustrations/ASCENDA-BRANCO.png" alt="login" style={{ width: 120 }} />
          </Box>
          <Box
            sx={{
              width: '25%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h3" textAlign="center" sx={{ marginBottom: 2, marginTop: 1, color: '#E8E8E8' }}>
              Resetar Senha
            </Typography>
            <Typography
              textAlign="center"
              sx={{ marginBottom: 4, color: '#E8E8E8', fontSize: '1.125rem', fontWeight: 400 }}
            >
              Digite seu endereço de email Ascenda que você usou para se registrar. Enviaremos um e-mail com um link
              para redefinir sua senha.
            </Typography>
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                marginBottom: 2,
                backgroundColor:
                  'linear-gradient(270deg, rgba(22, 22, 26, 0.39) 3.17%, rgba(22, 22, 26, 0.80) 51.48%, rgba(22, 22, 26, 0.00) 98.81%)',
                '& .MuiInputLabel-root': {
                  color: '#E8E8E8',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#E8E8E8',
                },
              }}
            />
            <Box display="flex" justifyContent="space-between" sx={{ width: '100%', marginTop: '1rem' }}>
              <CustomButton sx={{ width: '12rem' }} variant="custom" onClick={() => navigate(-1)}>
                Voltar
              </CustomButton>
              <CustomButton sx={{ width: '12rem' }} variant="primary" onClick={handleForgotPassword}>
                Enviar
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
