import { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Button, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel } from '@mui/material';
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { ref, get } from 'firebase/database';
import { useFirebaseError } from '../../../hooks/useFirebaseError';
import CustomButton from '../../../components/button/CustomButton';
import Iconify from '../../../components/iconify';
import { database, auth } from '../../../services/firebase';
import toast from '../../../utils/toast';
import { useUser } from '../../../context/UserContext';

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setCurrentUser, setRole, setIsPremium } = useUser();
  const navigate = useNavigate();
  const { friendlyError, translateError } = useFirebaseError();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setEmail(user.email);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleClickForgotPassword = () => {
    navigate('/password-reset');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      if (rememberMe) {
        await setPersistence(auth, browserLocalPersistence);
      } else {
        await setPersistence(auth, browserSessionPersistence);
      }

      await signInWithEmailAndPassword(auth, email, password);

      const userId = auth.currentUser.uid;
      setCurrentUser(userId);
      const userAdminRef = ref(database, `users/${userId}`);
      const userAdminSnapshot = await get(userAdminRef);
      const userAdmin = userAdminSnapshot.val();

      if (userAdmin) {
        setRole(userAdmin.role);
        setIsPremium(userAdmin.premium === 'yes');
      }
      const financialDataRef = ref(database, `users/${userId}/patrimonio`);
      const financialDataSnapshot = await get(financialDataRef);

      if (financialDataSnapshot.exists() || userAdmin.role === 'admin') {
        navigate('/inicio');
      } else {
        navigate('/welcome');
      }
    } catch (error) {
      translateError(error);
      toast({
        type: 'error',
        text:
          friendlyError || 'Houve erro durante a operação. Entre em contato através do email alex@scendavoce.com.br!',
        duration: 10000,
        position: { vertical: 'top', horizontal: 'right' },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={2}>
        <TextField
          fullWidth
          name="email"
          label="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          size="small"
        />
        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel
          control={<Checkbox name="remember" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />}
          label="Lembrar-me"
        />
        <Button sx={{ textTransform: 'none', color: '#E8E8E8' }} onClick={handleClickForgotPassword}>
          Esqueceu a senha?
        </Button>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>
        <CustomButton
          fullWidth
          size="large"
          type="submit"
          color="secondary"
          variant="primary"
          onClick={handleFormSubmit}
          sx={{ boxShadow: 'none !important' }}
        >
          Login
        </CustomButton>
        <CustomButton
          fullWidth
          size="large"
          type="button"
          variant="secondary"
          component={RouterLink}
          sx={{ boxShadow: 'none !important' }}
          to="/register"
        >
          Me cadastrar
        </CustomButton>
      </Stack>
    </>
  );
}
