import React, { useEffect, useState, useCallback } from 'react';
import { Container, TextField, Typography } from '@mui/material';
import { set, ref, get } from 'firebase/database';
import { fCurrency, unformatedValue } from '../../utils/formatNumber';
import { auth, database } from '../../services/firebase';
import toast from '../../utils/toast';
import CustomButton from '../button/CustomButton';
import { recalculateGoalsBasedofName } from '../../utils/resolvers';

export default function CarSection({ userIdParams, onDataSaved }) {
  const [userId, setUserId] = useState(null);
  const [valueCar, setValueCar] = useState('');
  const [carTimeFrame, setCarTimeFrame] = useState('');
  const [targetDate, setTargetDate] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const userIdToUse = userIdParams || (user && user.uid);
      if (userIdToUse) {
        setUserId(userIdToUse);
        const userGoalsRef = ref(database, `users/${userIdToUse}/goals/car`);
        const goalsSnapshot = await get(userGoalsRef);
        if (goalsSnapshot.exists()) {
          const goalsData = goalsSnapshot.val();
          setValueCar(goalsData.valueCar || '');
          setCarTimeFrame(goalsData.carTimeFrame || '');
        }
      }
    });
    return () => unsubscribe();
  }, [userIdParams]);

  const handleValueCar = (e) => {
    setValueCar(unformatedValue(e.target.value));
    updateIsEmpty();
  };

  const handleCarTimeFrame = (e) => {
    setCarTimeFrame(e.target.value);
    updateIsEmpty();
  };

  const updateIsEmpty = useCallback(() => {
    setIsEmpty(!valueCar || !carTimeFrame);
  }, [valueCar, carTimeFrame]);

  const handleSave = async () => {
    if (!userId) return;
    try {
      const userGoalsRef = ref(database, `users/${userId}/goals/car`);
      await set(userGoalsRef, {
        valueCar,
        carTimeFrame,
      });
      await recalculateGoalsBasedofName('car', userId);
      toast({
        type: 'success',
        text: 'Dados do carro salvos com sucesso!',
      });
      onDataSaved();
    } catch (error) {
      toast({
        type: 'error',
        text: 'Erro ao salvar dados do carro',
      });
    }
  };

  const handleTargetDateChange = (e) => {
    setTargetDate(e.target.value);
  };

  const formattedValueCar = fCurrency(valueCar);

  return (
    <Container sx={{ marginBottom: '2rem', marginTop: '2rem' }}>
      <Typography sx={{ fontSize: '1.5625rem', fontWeight: 400, lineHeight: 1.7 }}>Comprar Carro</Typography>
      <Typography variant="body1">
        Compartilhe um pouco mais desse objetivo para que possamos ajudar a conquistá-lo. Qual valor deseja ou tem em
        mente que esse automóvel custa?
      </Typography>
      <TextField
        type="text"
        value={formattedValueCar}
        onChange={handleValueCar}
        variant="outlined"
        margin="normal"
        InputLabelProps={{
          sx: { fontSize: '1.3rem', color: '#E8E8E8' },
        }}
        fullWidth
        required
      />

      <Typography variant="body1">E em quanto tempo (em anos) você acha que vai chegar lá?</Typography>
      <TextField
        type="number"
        value={carTimeFrame}
        onChange={handleCarTimeFrame}
        variant="outlined"
        margin="normal"
        fullWidth
        InputLabelProps={{
          sx: { fontSize: '0.8rem', color: '#E8E8E8' },
        }}
        required
      />

      <TextField
        type="date"
        label="Data para atingir o objetivo"
        value={targetDate}
        onChange={handleTargetDateChange}
        variant="outlined"
        margin="normal"
        InputLabelProps={{
          shrink: true,
          sx: { fontSize: '1.3rem', color: '#E8E8E8' },
        }}
        fullWidth
      />
      <CustomButton sx={{ width: '150px' }} variant="primary" fullWidth onClick={handleSave}>
        Salvar
      </CustomButton>
    </Container>
  );
}
