// src/routes.js

import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import ProtectedRoute from './ProtectedRoute';
// pages and components
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import RegisterPage from './pages/RegisterPage';
import IntroductionDNAFinancial from './components/forms/IntroductionDNAFinancial';
import ClientInformation from './components/forms/ClientInformation';
import Returns from './components/dashboard/Return';
import InvestmentDetails from './components/dashboard/client/InvestmentDetails';
import ClientListInvestment from './components/dashboard/ClientListInvestments';
import ClientDashboard from './components/dashboard/client/ClientDashboard';
import MacroeconomicData from './pages/MacroEconomicData';
import Home from './pages/Home';
import BigFiveTest from './pages/BigFive';
import Dashboard from './pages/Dashboard';
import EmailVerification from './pages/EmailVerification';
import ForgotPassword from './sections/auth/login/ForgotPasswordModal';
import TransactionsPage from './pages/TransactionsPage';
import MovementPage from './pages/Movement';
import ManageBudget from './pages/ManageBudget';
import WelcomePage from './pages/WelcomePage';
import PersonalityTestIntro from './pages/PersonalityTestIntro';
import AssetsTable from './components/AssetsTable';
import PoliciesTable from './components/PoliciesTable';
import NotImplementation from './pages/NotImplementation';
import FinancialDataForm from './components/financial/FinancialDataForm';
import ClientRegisterPage from './pages/ClientRegisterPage';
import NewRetirementSection from './components/forms/NewRetirementSection';
import NewHomePurchaseSection from './components/forms/NewHomePurchaseSection';
import NewChildrenEducationSection from './components/forms/NewChildrenEducationSection';
import AddCustomGoalForm from './components/forms/AddCustomGoalForm';
import EditCustomGoalForm from './components/forms/EditCustomGoalForm';

import { useUser } from './context/UserContext';

export default function Router() {
  const { role } = useUser();

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'dna-financeiro', element: <Returns /> },
        { path: 'clients', element: <ClientListInvestment /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/_/auth/action',
      element: <EmailVerification />,
    },
    {
      path: 'password-reset',
      element: <ForgotPassword />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      element: (
        <ProtectedRoute>
          <SimpleLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'profile', element: <BigFiveTest /> },
        { path: 'movement', element: <MovementPage /> },
        { path: 'transactions/:clientId', element: <TransactionsPage /> },
        { path: 'macroeconomic-data', element: <MacroeconomicData /> },
        { path: 'inicio', element: <DashboardAppPage /> },
        { path: 'financial-data/:clientId?', element: <FinancialDataForm isAdmin={role === 'admin'} /> },
        { path: 'home', element: <Home /> },
        { path: 'introduction', element: <IntroductionDNAFinancial /> },
        { path: 'information', element: <ClientInformation /> },
        { path: 'investments/:clientId', element: <ClientDashboard /> },
        { path: 'details/:goalName/:clientId?', element: <InvestmentDetails /> },
        { path: 'welcome', element: <WelcomePage /> },
        { path: 'introduction-profile', element: <PersonalityTestIntro /> },
        { path: 'dashboard/:clientId', element: <Dashboard /> },
        { path: 'manage-budget', element: <ManageBudget /> },
        { path: 'bens/:clientId?', element: <AssetsTable /> },
        { path: 'apolices/:clientId?', element: <PoliciesTable /> },
        { path: 'home/:tab?/:objective?', element: <Home /> },
        { path: 'no-config', element: <NotImplementation /> },
        { path: 'big-five-results', element: <BigFiveTest /> },
        { path: 'client-register', element: <ClientRegisterPage /> },
        { path: 'new-retirement/:clientId', element: <NewRetirementSection /> },
        { path: 'new-home-purchase/:clientId', element: <NewHomePurchaseSection /> },
        { path: 'new-children-education/:clientId', element: <NewChildrenEducationSection /> },
        { path: 'edit-custom-goal/:goalId/:clientId', element: <EditCustomGoalForm /> },
      ],
    },

    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
