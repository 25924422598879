import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  InputLabel,
  TextField,
  Typography,
  Button,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Select,
  FormControl 
, useTheme } from '@mui/material';
import { ref, set, get } from 'firebase/database';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { fCurrency, unformatedValue } from '../../utils/formatNumber';
import { database, auth } from '../../services/firebase';

export default function ClientInformation() {
  const navigate = useNavigate();
  const [idUser, setIdUser] = useState(null)
  const [formData, setFormData] = useState({
    age: '',
    monthlyIncome: '',
    monthlyExpenses: '',
    gender: '',
    dream: '',
  });

  const theme = useTheme()

  useEffect(() => {
    const fetchUserInformation = async (userId) => {
      try {
          const userInformationRef = ref(database, `users/${userId}/dadosCadastrais`);
          const snapshot = await get(userInformationRef);
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setFormData(userData);
          }
      } catch (error) {
        console.error(error);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserInformation(user.uid);
        setIdUser(user.uid)
      } else {
        console.log('No user is signed in');
      }
    });

    return unsubscribe;
  }, []);


  const handleAgeChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      age: value,
    }));
  };

  const handleMonthlyIncomeChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      monthlyIncome: unformatedValue(value),
    }));
  };

  const handleMonthlyExpensesChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      monthlyExpenses: unformatedValue(value),
    }));
  };

  const handleGenderChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      gender: value,
    }));
  };

  const handleDreamChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      dream: value,
    }));
  };

  const handleSubmit = () => {
    const userInformationRef = ref(database, `users/${idUser}/dadosCadastrais`);
    set(userInformationRef, formData);
    navigate('/profile');
  };

  const formattedMonthlyIncome = formData.monthlyIncome ? fCurrency(formData.monthlyIncome) : '';
  const formattedMonthlyExpenses = formData.monthlyExpenses ? fCurrency(formData.monthlyExpenses) : '';

  return (
    <Container sx={{ marginTop: '4rem' }}>
      <AppBar 
        position="static"
        sx={{
          borderRadius: '10px 10px 0 0',
          display: 'flex',
          alignItems:'center',
          justifyContent: 'center'
        }}
      >
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <AccountCircle />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Dados cadastrais
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper sx={{ boxShadow: theme.customShadows.z8, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2rem' }}>
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          Precisamos também dos seus dados para cadastro, não se preocupe, todos os dados estão guardados sob sigilo total.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Idade"
            value={formData.age}
            onChange={handleAgeChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Renda mensal média"
            value={formattedMonthlyIncome}
            onChange={handleMonthlyIncomeChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Gastos mensais médios"
            value={formattedMonthlyExpenses}
            onChange={handleMonthlyExpensesChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth>
            <InputLabel id="gender-label" color="secondary">Gênero</InputLabel>
            <Select
              value={formData.gender}
              onChange={handleGenderChange}
              margin="normal"
              label="Gênero"
            >
              <MenuItem value="Masculino">Masculino</MenuItem>
              <MenuItem value="Feminino">Feminino</MenuItem>
              <MenuItem value="Outro">Outro</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Grande sonho"
            value={formData.dream}
            onChange={handleDreamChange}
            fullWidth
            margin="normal"
          />
          <Box sx={{float: 'right', marginTop: 1}}>
            <Button 
              type="submit"
              variant="contained"
              color="primary"
              sx={{width: '200px'}}
            >
              Próximo
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
}
