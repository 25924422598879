import { useState, useEffect } from 'react';
import { applyActionCode, confirmPasswordReset, getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { TextField, Box, Typography } from '@mui/material';
import { useFirebaseError } from '../hooks/useFirebaseError';
import toast from '../utils/toast';
import CustomButton from '../components/button/CustomButton';
import { useLoader } from '../context/LoaderContext';
import { delay } from '../utils/formatTime';

function EmailVerification() {
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [isResetPassword, setIsResetPassword] = useState(false);
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const { friendlyError, translateError } = useFirebaseError();

  const urlParams = new URLSearchParams(window.location.search);
  const actionCode = urlParams.get('oobCode');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    const actionCode = urlParams.get('oobCode');

    if (mode === 'verifyEmail') {
      showLoader('Estamos verificando seu email...'); 
      const auth = getAuth();
      applyActionCode(auth, actionCode)
      .then(async () => {
        toast({
            type: 'success',
            text: 'Email verificado com sucesso!',
            duration: 3000
        });
        await delay(2000);
        navigate('/login');
    })
    .catch(async (error) => {
        translateError(error) 
        toast({
            type: 'error',
            text: friendlyError
        });
        await delay(4000);
        navigate('/login')
    })
    .finally(() => {
        hideLoader();
    });
    } else if (mode === 'resetPassword') {
      setIsResetPassword(true);
    }
  }, [navigate]);

  const handlePasswordReset = async () => {
    if (newPassword !== repeatNewPassword) {
      toast({
        type: 'error',
        text: 'As senhas não coincidem. Por favor, tente novamente.',
        duration: 3000
      });
      return;
    }

    try {
      const authInstance = getAuth();
      await confirmPasswordReset(authInstance, actionCode, newPassword);
      toast({
        type: 'success',
        text: 'Senha redefinida com sucesso!',
        duration: 3000
      });
      navigate('/login');
    } catch (error) {
      translateError(error)
      toast({
        type: 'error',
        text: friendlyError,
        duration: 3000
      });
    }
  };

  if (isResetPassword) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          p: 6,
        }}
      >
        <Box sx={{ background: 'linear-gradient(270deg, rgba(22, 22, 26, 0.39) 3.17%, rgba(22, 22, 26, 0.80) 51.48%, rgba(22, 22, 26, 0.00) 98.81%)', marginBottom: '2rem' }}>
          <img src="/assets/illustrations/ASCENDA-BRANCO.png" alt="login" style={{ width: 120 }} />
        </Box>

        <Box sx={{ width: '25%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '0.775rem' }}>
        <Typography variant="h3" textAlign="center" sx={{ marginBottom: 2, marginTop: 1, color: '#E8E8E8' }}>
          Resetar Senha
        </Typography>
        <TextField
          type="password"
          fullWidth
          label="Nova Senha"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          type="password"
          fullWidth
          label="Repetir Nova Senha"
          value={repeatNewPassword}
          onChange={(e) => setRepeatNewPassword(e.target.value)}
        />
        <CustomButton onClick={handlePasswordReset}>Redefinir Senha</CustomButton>
        </Box>
      </Box>
    );
  }
}

export default EmailVerification;
