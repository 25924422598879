import React, { createContext, useState, useContext } from 'react';
import Loader from '../components/loader/Loader';

const LoaderContext = createContext();

export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('Carregando seu mapa financeiro');

  const showLoader = (msg) => {
    setMessage(msg || 'Carregando seu mapa financeiro');
    setIsVisible(true);
  };
  const hideLoader = () => setIsVisible(false);

  return (
    <LoaderContext.Provider value={{ showLoader, hideLoader }}>
      {children}
      {isVisible && <Loader message={message} />}
    </LoaderContext.Provider>
  );
};
