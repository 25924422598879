import React, { useState, useEffect } from 'react';
import {
  Alert,
  Card,
  CardContent,
  TextField,
  Box,
  Typography,
  IconButton,
  Grid,
  Snackbar,
  useTheme,
} from '@mui/material';
import { ref, set, get } from 'firebase/database';
import { ArrowUpward, Save as SaveIcon, TrendingUp } from '@mui/icons-material';
import { database } from '../services/firebase';

const MacroeconomicData = () => {
  const [projectedInflation, setProjectedInflation] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [realInterest, setRealInterest] = useState('');
  const [ascendaReturn, setAscendaReturn] = useState('');
  const [monthlyInterestNominal, setMonthlyInterestNominal] = useState('');

  const [statusMessage, setStatusMessage] = useState(null);
  const [severity, setSeverity] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const inflationRef = ref(database, 'admin/inflationRate');
        const inflationSnapshot = await get(inflationRef);
        if (inflationSnapshot.exists()) {
          setProjectedInflation(inflationSnapshot.val());
        } else {
          setProjectedInflation('0');
        }

        const interestRef = ref(database, 'admin/interestRate');
        const interestSnapshot = await get(interestRef);
        if (interestSnapshot.exists()) {
          setInterestRate(interestSnapshot.val());
        } else {
          setInterestRate('0');
        }

        const interestRealRef = ref(database, 'admin/interestRateReal');
        const interestRealSnapshot = await get(interestRealRef);
        if (interestRealSnapshot.exists()) {
          setRealInterest(interestRealSnapshot.val());
        } else {
          setRealInterest('0');
        }

        const ascendaReturnRef = ref(database, 'admin/ascendaReturn');
        const ascendaReturnSnapshot = await get(ascendaReturnRef);
        if (ascendaReturnSnapshot.exists()) {
          setAscendaReturn(ascendaReturnSnapshot.val());
        } else {
          setAscendaReturn('0');
        }

        const monthlyInterestNominalRef = ref(database, 'admin/monthlyInterestNominal');
        const monthlyInterestNominalSnapshot = await get(monthlyInterestNominalRef);
        if (monthlyInterestNominalSnapshot.exists()) {
          setMonthlyInterestNominal(monthlyInterestNominalSnapshot.val());
        } else {
          setMonthlyInterestNominal('0');
        }
      } catch (error) {
        console.log('Error fetching data from Firebase:', error);
      }
    };

    fetchData();
  }, []);

  const handleCloseSnackbar = () => {
    setStatusMessage(null);
  };

  const handleProjectedInflationChange = (e) => {
    setProjectedInflation(e.target.value.replace('%', ''));
  };

  const handleInterestRateChange = (e) => {
    setInterestRate(e.target.value.replace('%', ''));
  };

  const handleAscendaReturnChange = (e) => {
    setAscendaReturn(e.target.value.replace('%', ''));
  };

  const handleSaveAscendaReturn = async () => {
    try {
      const ascendaReturnRef = ref(database, 'admin/ascendaReturn');
      await set(ascendaReturnRef, ascendaReturn);

      const monthlyNominalInterestRate = (1 + parseFloat(ascendaReturn.replace(',', '.')) / 100) ** (1 / 12) - 1;

      console.log(monthlyInterestNominal);
      const monthlyInterestNominalRef = ref(database, 'admin/monthlyInterestNominal');
      await set(monthlyInterestNominalRef, monthlyNominalInterestRate.toFixed(5));

      // Atualiza o estado imediatamente após calcular e salvar o valor
      setMonthlyInterestNominal(monthlyNominalInterestRate.toFixed(5));

      const inflationRateValue = parseFloat(projectedInflation.replace(',', '.'));
      const nominalInterestRate = parseFloat(ascendaReturn.replace(',', '.'));
      const realInterestRate = ((1 + nominalInterestRate / 100) / (1 + inflationRateValue / 100) - 1) * 100; // Valor tá em ano;

      const jurosRealRef = ref(database, 'admin/jurosReal');
      await set(jurosRealRef, realInterestRate.toFixed(5));
      setRealInterest(realInterestRate.toFixed(5));

      setStatusMessage('Retorno Ascenda e Juro Nominal Mensal cadastrados com sucesso.');
      setSeverity('success');
    } catch (error) {
      setStatusMessage('Ocorreu um erro durante a operação.');
      setSeverity('error');
      console.log('Error saving Ascenda return and monthly nominal interest rate:', error);
    }
  };

  const handleSaveProjectedInflation = async () => {
    try {
      const inflationRef = ref(database, 'admin/inflationRate');
      await set(inflationRef, projectedInflation);

      const monthlyInflationRateValue = (1 + parseFloat(projectedInflation.replace(',', '.')) / 100) ** (1 / 12) - 1;
      const monthlyInflationRef = ref(database, 'admin/monthlyIflationRate');
      await set(monthlyInflationRef, monthlyInflationRateValue.toFixed(5));

      calculateRealInterest();
      setStatusMessage('Inflação projetada cadastrada com sucesso.');
      setSeverity('success');
    } catch (error) {
      setStatusMessage('Ocorreu um erro durante a operação.');
      setSeverity('error');
      console.log('Error saving projected inflation rate:', error);
    }
  };

  const handleSaveInterestRate = async () => {
    try {
      const interestRef = ref(database, 'admin/interestRate');
      await set(interestRef, interestRate);

      calculateRealInterest();
      setStatusMessage('Juros cadastrado com sucesso');
      setSeverity('success');
    } catch (error) {
      setStatusMessage('Ocorreu um erro durante a operação.');
      setSeverity('error');
      console.log('Error saving interest rate:', error);
    }
  };

  const calculateRealInterest = () => {
    if (interestRate && projectedInflation) {
      const nominalInterest = parseFloat(interestRate.replace(',', '.'));
      const inflation = parseFloat(projectedInflation.replace(',', '.'));
      const realInterestValue = ((1 + nominalInterest / 100) / (1 + inflation / 100) - 1) * 100;
      const interestRef = ref(database, 'admin/interestRateReal');
      set(interestRef, realInterestValue.toFixed(5));
      setRealInterest(realInterestValue.toFixed(5));
    } else {
      setRealInterest('');
    }
  };

  const formatValueWithPercentage = (value) => (value ? `${value}%` : '');

  return (
    <Grid container spacing={2} justifyContent="start" alignItems="center">
      <Grid item xs={12} md={2}>
        <Card sx={{ borderRadius: '0.99681rem', border: '1px solid #F6F6F6', background: '#444C52' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} mt={1}>
            <Typography mr={1} sx={{ color: '#F6F6F6' }} variant="h6" alignItems="center">
              Retorno Ascenda
            </Typography>
            <TrendingUp size="large" sx={{ color: '#F6F6F6' }} />
          </Box>
          <CardContent style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <TextField
              variant="filled"
              label="Retorno Ascenda"
              value={formatValueWithPercentage(ascendaReturn)}
              onChange={handleAscendaReturnChange}
              fullWidth
              type="text"
              InputProps={{
                style: { color: '#F6F6F6' },
              }}
              InputLabelProps={{
                style: { color: '#F6F6F6' },
              }}
            />
            <IconButton onClick={handleSaveAscendaReturn}>
              <SaveIcon sx={{ color: '#F6F6F6' }} />
            </IconButton>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={2}>
        <Card
          sx={{ borderRadius: '0.99681rem', border: '1px solid #F6F6F6', background: '#444C52', minHeight: '135px' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} mt={1}>
            <Typography mr={1} sx={{ color: '#F6F6F6' }} variant="h6" alignItems="center">
              Retorno Ascenda Mensal
            </Typography>
            <TrendingUp size="large" sx={{ color: '#F6F6F6' }} />
          </Box>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#F6F6F6' }}>
                {monthlyInterestNominal ? `${(parseFloat(monthlyInterestNominal) * 100).toFixed(2)}%` : ''}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={2}>
        <Card sx={{ borderRadius: '0.99681rem', border: '1px solid #F6F6F6', background: '#444C52' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} mt={1}>
            <Typography mr={1} sx={{ color: '#F6F6F6' }} variant="h6" alignItems="center">
              Inflação Projetada
            </Typography>
            <TrendingUp size="large" sx={{ color: '#F6F6F6' }} />
          </Box>
          <CardContent style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <TextField
              variant="filled"
              label="Inflação Projetada"
              value={formatValueWithPercentage(projectedInflation)}
              onChange={handleProjectedInflationChange}
              fullWidth
              type="text"
              InputProps={{
                style: { color: '#F6F6F6' },
              }}
              InputLabelProps={{
                style: { color: '#F6F6F6' },
              }}
            />
            <IconButton onClick={handleSaveProjectedInflation}>
              <SaveIcon sx={{ color: '#F6F6F6' }} />
            </IconButton>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={2}>
        <Card sx={{ borderRadius: '0.99681rem', border: '1px solid #F6F6F6', background: '#444C52' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} mt={1}>
            <Typography mr={1} sx={{ color: '#F6F6F6' }} variant="h6" alignItems="center">
              Juros hoje
            </Typography>
            <TrendingUp size="large" sx={{ color: '#F6F6F6' }} />
          </Box>
          <CardContent style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <TextField
              label="Juros Hoje"
              variant="filled"
              value={formatValueWithPercentage(interestRate)}
              onChange={handleInterestRateChange}
              fullWidth
              type="text"
              InputProps={{
                style: { color: '#F6F6F6' },
              }}
              InputLabelProps={{
                style: { color: '#F6F6F6' },
              }}
            />
            <IconButton onClick={handleSaveInterestRate}>
              <SaveIcon sx={{ color: '#F6F6F6' }} />
            </IconButton>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={2}>
        <Card sx={{ borderRadius: '0.99681rem', border: '1px solid #F6F6F6', background: '#444C52', height: '135px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} mt={1}>
            <Typography mr={1} sx={{ color: '#F6F6F6' }} variant="h6" alignItems="center">
              Juro Real
            </Typography>
            <ArrowUpward size="large" sx={{ color: '#F6F6F6' }} />
          </Box>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#F6F6F6' }}>
                {realInterest ? `${parseFloat(realInterest).toFixed(2)}%` : ''}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Snackbar
        open={statusMessage !== null}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert variant="filled" onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
          {statusMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default MacroeconomicData;
