import { alpha } from '@mui/material/styles';

import { PaletteMode } from '@mui/material';

// ----------------------------------------------------------------------

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const SECONDARY = {
  lighter: '#FF6694',
  light: '#FF338B',
  main: '#FF0062', // Primary color
  dark: '#CC0052',
  darker: '#990041',
  contrastText: '#fff',
};

const PRIMARY = {
  lighter: '#8C66FF',
  light: '#005BDA',

  main: '#005BDA',
  dark: '#4520B2',
  darker: '#301C7A',
  contrastText: '#fff',
};
const INFO = {
  lighter: '#A4DEF9',
  light: '#6AC6F7',
  main: '#3498DB',
  dark: '#2574A9',
  darker: '#1A5276',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#B9E6B1',
  light: '#82CF7A',
  main: '#8CE446',
  dark: '#24A25D',
  darker: '#1A7648',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FCC6C5',
  light: '#F99897',
  main: '#E74C3C',
  dark: '#AF392A',
  darker: '#771F1C',
  contrastText: '#fff',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[0],
    secondary: GREY[600],
    disabled: GREY[500],
    white: `#fff`,
  },
  background: {
    paper: 'rgba(0, 0, 0, 0.6)',
    default: 'rgba(0, 0, 0, 0.6)',
    neutral: GREY[200],
    roxo: '#7942F4',
    rosa: '#FF0062',
    preto: '#000000',
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // valores da paleta para o modo light
          primary: { ...PRIMARY },
          secondary: { ...SECONDARY },
          error: { ...ERROR },
          warning: { ...WARNING },
          info: { ...INFO },
          success: { ...SUCCESS },
          grey: { ...GREY },
          text: {
            primary: '#000',
            secondary: GREY[600],
            disabled: GREY[500],
            white: '#fff',
          },
          background: {
            paper: '#FFF',
            default: '#FFF',
            neutral: GREY[200],
            roxo: '#7942F4',
            rosa: '#FF0062',
            preto: '#000000',
          },
        }
      : {
          // valores da paleta para o modo dark
          primary: { ...PRIMARY },
          secondary: { ...SECONDARY },
          error: { ...ERROR },
          warning: { ...WARNING },
          info: { ...INFO },
          success: { ...SUCCESS },
          grey: { ...GREY },
          text: {
            primary: '#fff',
            secondary: GREY[500],
            disabled: GREY[400],
            white: '#fff',
          },
          background: {
            paper: '#000',
            default: 'rgba(0, 0, 0, 0.6)',
            neutral: GREY[800],
            roxo: '#7942F4',
            rosa: '#FF0062',
            preto: '#000000',
          },
        }),
  },
});

export default palette;
