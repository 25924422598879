import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { Button, Stack, Grid, Typography, Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useFirebaseError } from '../../hooks/useFirebaseError';
import { useUser } from '../../context/UserContext'; // Importa o UserContext
import UserPage from '../../pages/UserPage';
import toast from '../../utils/toast';
import Scrollbar from '../scrollbar/Scrollbar';
import MacroeconomicData from '../../pages/MacroEconomicData';

const AdminDashboard = () => {
  const database = getDatabase();

  const [clients, setClients] = useState([]);
  const [totalClients, setTotalClients] = useState(0);
  const [inflationRate, setInflationRate] = useState(0);
  const [interestRate, setInterestRate] = useState('');
  const [realInterest, setRealInterest] = useState('');
  const { friendlyError, translateError } = useFirebaseError();
  const theme = useTheme();

  const navigate = useNavigate();
  const { setCurrentUser } = useUser(); // Obtém a função setCurrentUser do contexto

  useEffect(() => {
    // setCurrentUser(null); // Seta currentUser para null ao carregar a tela
    fetchClients();
    fetchInflationRate();
    fetchData();
  }, [setCurrentUser]);

  const fetchClients = async () => {
    const clientsRef = ref(database, 'users');
    const clientsSnapshot = await get(clientsRef);
    const clientsData = [];

    if (clientsSnapshot.exists()) {
      const clientsObject = clientsSnapshot.val();
      Object.keys(clientsObject).forEach((clientId) => {
        const client = { id: clientId, ...clientsObject[clientId] };
        if (client.role === 'cliente') {
          clientsData.push(client);
        }
      });
    }

    setClients(clientsData);
    setTotalClients(clientsData.length);
    calculateTotalInvestmentByType(clientsData);
  };

  const fetchData = async () => {
    try {
      const interestRef = ref(database, 'admin/interestRate');
      const interestSnapshot = await get(interestRef);
      if (interestSnapshot.exists()) {
        setInterestRate(interestSnapshot.val());
      } else {
        setInterestRate('0');
      }

      const interestRealRef = ref(database, 'admin/interestRateReal');
      const interestRealSnapshot = await get(interestRealRef);
      if (interestRealSnapshot.exists()) {
        setRealInterest(interestRealSnapshot.val());
      } else {
        setRealInterest('0');
      }
    } catch (error) {
      translateError(error);
      toast({
        type: 'error',
        text: friendlyError,
      });
    }
  };

  const handleUserUpdate = () => {
    fetchClients();
  };

  const fetchInflationRate = async () => {
    const adminRef = ref(database, 'admin/inflationRate');
    const adminSnapshot = await get(adminRef);

    if (adminSnapshot.exists()) {
      const inflationRate = adminSnapshot.val();

      setInflationRate(inflationRate);
    }
  };

  const calculateTotalInvestmentByType = () => {
    const investmentDataByType = {};

    clients.forEach((client) => {
      const goals = client.goals || {};

      Object.entries(goals).forEach(([goalName, goalDetails]) => {
        const investments = goalDetails.investments?.registeredContributions || [];

        investments.forEach((investment) => {
          const { investmentType, ValorLiquido } = investment;

          if (!investmentDataByType[investmentType]) {
            investmentDataByType[investmentType] = 0;
          }

          // Verifica se ValorLiquido é definido e não é NaN antes de somar
          if (ValorLiquido && !Number.isNaN(Number(ValorLiquido))) {
            investmentDataByType[investmentType] += Number(ValorLiquido);
          }
        });
      });
    });

    return investmentDataByType;
  };

  const totalInvestmentByType = calculateTotalInvestmentByType();

  const totalInvestment = Object.values(totalInvestmentByType).reduce((total, value) => total + value, 0);

  const calculateClientCategories = () => {
    let advisoryCount = 0;
    let planningCount = 0;
    let threeSixtyCount = 0;
    let finishProfile = 0;

    clients.forEach((client) => {
      if (client.patrimonio) finishProfile += 1;
      switch (client.category) {
        case 'Advisory':
          advisoryCount += 1;
          break;
        case 'Planning':
          planningCount += 1;
          break;
        case 'cliente360':
          threeSixtyCount += 1;
          break;
        default:
          break;
      }
    });

    return { advisoryCount, planningCount, threeSixtyCount, finishProfile };
  };

  const { advisoryCount, planningCount, threeSixtyCount, finishProfile } = calculateClientCategories();

  return (
    <div style={{ width: '100%', margin: '0.8rem auto 0', padding: '1.6rem 1.5rem' }}>
      <Grid container spacing={3} sx={{ width: '100%' }}>
        <Grid item xs={3}>
          <Box
            sx={{
              py: 0.3,
              px: 1,
              minHeight: '100px',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '0.5rem',
              borderRadius: '0.99681rem',
              border: '1px solid #F6F6F6',
              background: '#444C52',
            }}
          >
            <Stack direction="row" width="80%" spacing={12}>
              <Typography
                sx={{
                  color: '#E8E8E8',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  fontFamily: 'Afrah',
                  padding: '0.5rem',
                }}
                align="center"
              >
                AuM
              </Typography>
              <Typography sx={{ color: '#F6F6F6', fontSize: '1rem', fontFamily: 'DM Sans' }} align="center">
                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalInvestment)}
              </Typography>
            </Stack>

            <Stack direction="row" width="80%" spacing={10}>
              <Typography sx={{ color: '#F6F6F6', fontSize: '1rem', fontFamily: 'DM Sans' }} align="center">
                {' '}
                Clientes
              </Typography>
              <Typography sx={{ fontSize: '1rem', fontFamily: 'DM Sans' }} align="center">
                {totalClients}
              </Typography>
            </Stack>

            <Stack direction="row" width="80%" spacing={6}>
              <Typography sx={{ color: '#F6F6F6', fontSize: '1rem', fontFamily: 'DM Sans' }} align="center">
                {' '}
                Ticket Médio
              </Typography>
              <Typography sx={{ fontSize: '1rem', fontFamily: 'DM Sans' }} align="center">
                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                  totalInvestment / totalClients
                )}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <MacroeconomicData />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: '100%' }}>
          <Scrollbar
            sx={{
              minHeight: '400px',
              maxHeight: '440px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '1px',
            }}
          >
            <UserPage onUserUpdate={handleUserUpdate} />
          </Scrollbar>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminDashboard;
