import React from 'react';
import { Box, Typography } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const Achievements = ({ achievements }) => {
  return (
    <Box display="flex" justifyContent="center" padding="20px" width="100%">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        padding="20px"
        width="100%"
        maxWidth="400px"
        style={{ margin: '0 auto' }} // Center horizontally
      >
        <Typography variant="h5" gutterBottom>
          Conquistas
        </Typography>
        {achievements &&
          Object.entries(achievements).map(([key, value]) => (
            <Box
              key={key}
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ gap: 1, width: '100%' }}
            >
              <StarBorderIcon />
              <Box display="flex" flexDirection="row" alignItems="center" width="100%">
                <Typography variant="body2" sx={{ minWidth: '50px' }}>
                  {value.rank}
                </Typography>
                <Typography variant="body2" sx={{ marginLeft: '10px' }}>
                  {value.dateAchieved}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default Achievements;
