import { format, getTime, formatDistanceToNow, differenceInYears, parse } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'ddMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function delay(ms=2000) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getCurrentDate = () => {
  const currentDate = new Date();
  return currentDate.toISOString();
};

export function calculateYearsUntil(targetDate) {
  const currentDate = new Date();
  const goalDate = parse(targetDate, 'yyyy-MM-dd', new Date());
  return Math.abs(differenceInYears(currentDate, goalDate));
}

