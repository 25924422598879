import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { ref, get, set } from 'firebase/database';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import { recalculateGoalsBasedofNameForDeleteInvestment } from '../../../../utils/resolvers';
import toast from '../../../../utils/toast';
import { arrowStyle, balloonStyle, blurStyle } from '../../../../utils/cssStyles';
import { database } from '../../../../services/firebase';
import Scrollbar from '../../../scrollbar/Scrollbar';
import NewInvestmentRow from './subcomponents/NewInvestmentRow';
import InvestmentRow from './subcomponents/InvestmentRow';

const StyledTable = styled('table')(({ theme }) => ({
  width: '100%',
  borderCollapse: 'collapse',
  borderSpacing: '0',
  fontSize: '0.875rem',
  border: `1px solid ${theme.palette.divider}`,
  'thead th': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: '#000',
    fontWeight: '400',
  },
  'tbody td': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  'th, td': {
    padding: theme.spacing(1),
  },
}));

const DesktopView = ({
  investments,
  editedInvestments,
  setEditedInvestments,
  handleAddNewInvestment,
  addingNewInvestment,
  setAddingNewInvestment,
  handleInvestmentSubmit,
  userId,
  goalName,
  fetchGoalDetails,
  noInvestment,
}) => {
  const [formattedInvestmentValue, setFormattedInvestmentValue] = useState('');
  const [formattedInvestmentValueLiq, setFormattedInvestmentValueLiq] = useState('');
  const [localInvestments, setLocalInvestments] = useState(investments);
  const [hasInvestment, setHasInvestment] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    setLocalInvestments(investments);
  }, [investments]);

  const handleDeleteInvestment = async (investmentId) => {
    const portfolioPath = `users/${userId}/goals/${goalName}/investments/registeredContributions`;
    const updatedInvestments = localInvestments.filter((inv) => inv.id !== investmentId);

    try {
      const portfolioRef = ref(database, portfolioPath);
      await set(portfolioRef, updatedInvestments);
      await recalculateGoalsBasedofNameForDeleteInvestment(goalName, userId);
      setLocalInvestments(updatedInvestments);

      toast({
        type: 'success',
        text: 'Registro deletado com sucesso!',
      });

      fetchGoalDetails();
    } catch (error) {
      toast({
        type: 'error',
        text: 'Erro ao deletar investimento',
      });
    }
  };

  useEffect(() => {
    const checkInvestment = async () => {
      const appPhasePath = `users/${userId}/goals/${goalName}/investments/registeredContributions`;
      const response = await get(ref(database, appPhasePath));
      if (!response.exists()) {
        setHasInvestment(true);
      }
    };
    checkInvestment();
  }, [userId, goalName]);

  const handleClickAddNewInvestment = () => {
    setHasInvestment(false);
    handleAddNewInvestment();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedInvestments = localInvestments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div
      style={{
        borderRadius: '1.25rem',
        position: 'relative',
        paddingBottom: '1rem',
        height: '100%',
        width: '95%',
      }}
    >
      <Scrollbar
        sx={{
          maxHeight: { xs: 400, sm: 500, md: 600, lg: 700 },
          overflowY: 'auto',
          ...(noInvestment ? blurStyle : {}),
        }}
      >
        <StyledTable>
          <thead>
            <tr>
              <th align="left">Nome</th>
              <th align="left">Categoria</th>
              <th align="left">Taxa</th>
              <th align="left">Data Aplicação</th>
              <th align="left">Vencimento</th>
              <th align="left">R$(Inicial)</th>
              <th align="left">Qtde</th>
              <th align="left">R$(Líq)</th>
              <th align="left">Ações</th>
            </tr>
          </thead>
          <tbody>
            {addingNewInvestment && (
              <NewInvestmentRow
                editedInvestments={editedInvestments}
                setEditedInvestments={setEditedInvestments}
                handleInvestmentSubmit={handleInvestmentSubmit}
                setAddingNewInvestment={setAddingNewInvestment}
                setFormattedInvestmentValue={setFormattedInvestmentValue}
                formattedInvestmentValueLiq={formattedInvestmentValueLiq}
                formattedInvestmentValue={formattedInvestmentValue}
                setFormattedInvestmentValueLiq={setFormattedInvestmentValueLiq}
              />
            )}
            {paginatedInvestments.map((investment) => (
              <InvestmentRow
                key={investment.id}
                investment={investment}
                editedInvestments={editedInvestments}
                setEditedInvestments={setEditedInvestments}
                handleInvestmentSubmit={handleInvestmentSubmit}
                handleDeleteInvestment={handleDeleteInvestment}
              />
            ))}
          </tbody>
        </StyledTable>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={localInvestments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {noInvestment && (
        <div style={balloonStyle}>
          Clique aqui para direcionar os investimentos
          <div style={arrowStyle} />
        </div>
      )}
      <IconButton
        sx={{
          color: 'rgba(0, 0, 0)',
          position: 'absolute',
          bottom: 0,
          right: { xs: '0.5rem', sm: '1rem' },
          zIndex: 1,
        }}
        onClick={handleClickAddNewInvestment}
        disabled={addingNewInvestment}
      >
        <AddIcon />
      </IconButton>
    </div>
  );
};

export default DesktopView;
