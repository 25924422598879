import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { set, ref, get } from 'firebase/database';
import { fCurrency, unformatedValue } from '../../utils/formatNumber';
import { auth, database } from '../../services/firebase';
import toast from '../../utils/toast';
import { recalculateGoalsBasedofName } from '../../utils/resolvers';
import CustomButton from '../button/CustomButton';

const OtherGoalsSection = ({ userIdParams, onDataSaved }) => {
  const [userId, setUserId] = useState(null);
  const [hasOtherGoals, setHasOtherGoals] = useState('');
  const [projectValue, setProjectValue] = useState('');
  const [projectTimeframe, setProjectTimeframe] = useState('');
  const [targetDate, setTargetDate] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const userIdToUse = userIdParams || (user && user.uid);
      if (userIdToUse) {
        setUserId(userIdToUse);
        const userGoalsRef = ref(database, `users/${userIdToUse}/goals/outrosObjetivos`);
        const goalsSnapshot = await get(userGoalsRef);
        if (goalsSnapshot.exists()) {
          const goalsData = goalsSnapshot.val();
          setHasOtherGoals(goalsData.hasOtherGoals || '');
          setProjectValue(goalsData.projectValue || '');
          setProjectTimeframe(goalsData.projectTimeframe || '');
          setTargetDate(goalsData.targetDate || '');
        }
      }
    });
    return () => unsubscribe();
  }, []);
  const handleProjectValueChange = (e) => {
    setProjectValue(unformatedValue(e.target.value));
    updateIsEmpty();
  };
  const handletargetDateChange = (e) => {
    setTargetDate(e.target.value);
  };

  const handleProjectTimeframeChange = (e) => {
    setProjectTimeframe(e.target.value);
    updateIsEmpty();
  };

  const updateIsEmpty = () => {
    setIsEmpty(hasOtherGoals === 'yes' && (!projectValue || !projectTimeframe));
  };

  const handleSave = async () => {
    if (!userId) return;
    try {
      const userGoalsRef = ref(database, `users/${userId}/goals/outrosObjetivos`);
      await set(userGoalsRef, {
        hasOtherGoals,
        projectValue,
        projectTimeframe,
      });
      await recalculateGoalsBasedofName('outrosObjetivos', userId);
      toast({
        type: 'success',
        text: 'Dados de outros objetivos salvos com sucesso!',
      });
      onDataSaved();
      onDataSaved();
    } catch (error) {
      toast({
        type: 'success',
        text: 'Erro ao salvar dados de outros objetivos:',
      });
    }
  };

  const formattedProjectValue = fCurrency(projectValue);
  return (
    <Container
      sx={{
        marginBottom: '2rem',
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <Typography
        sx={{
          fontSize: '1.5625rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 1.7,
        }}
      >
        Outros Objetivos ou Realizações
      </Typography>
      {
        <>
          <Typography variant="body1">Qual o valor que você considera para a realização deste projeto?</Typography>
          <TextField
            type="text"
            value={formattedProjectValue}
            onChange={handleProjectValueChange}
            InputLabelProps={{
              sx: {
                fontSize: '1.3rem',
                color: '#E8E8E8',
              },
            }}
            variant="outlined"
            margin="normal"
          />

          <Typography variant="body1">Em quantos anos você quer realizar este projeto?</Typography>
          <TextField
            type="number"
            label="Anos para o projeto"
            value={projectTimeframe}
            onChange={handleProjectTimeframeChange}
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              sx: {
                fontSize: '0.8rem',
                color: '#E8E8E8',
              },
            }}
          />
          <TextField
            type="date"
            label="Data para atingir o objetivo"
            value={targetDate || ''}
            onChange={handletargetDateChange}
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              shrink: true,
              sx: {
                fontSize: '1.3rem',
                color: '#E8E8E8',
              },
            }}
            fullWidth
          />
          <CustomButton variant="primary" onClick={handleSave}>
            Salvar
          </CustomButton>
        </>
      }
    </Container>
  );
};
export default OtherGoalsSection;
