// component
import { Assessment, PeopleAlt, PieChart, Settings } from '@mui/icons-material';

// ----------------------------------------------------------------------


const navConfig = [
  {
    title: 'Configuraçõesde Usuários',
    path: '/dashboard/user',
    icon: <Settings/>,
    role: 'admin'
  },

  {
    title: 'DNA Financeiro',
    path: '/dashboard/dna-financeiro',
    icon: <PieChart/>,
    role: 'admin'
  },

  {
    title: 'Dados Macroeconônicos',
    path: '/dashboard/macroeconomic-data',
    icon: <Assessment/>,
    role: 'admin'
  },

  {
    title: 'Clientes',
    path: '/dashboard/clients',
    icon: <PeopleAlt/>,
    role: 'admin'
  }
];

export default navConfig;
