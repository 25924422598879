import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ref, set, getDatabase, get } from 'firebase/database';

import { database } from '../../../services/firebase';
import sendMessage from '../../../services/sendMenssage';
import toast from '../../../utils/toast';

import DesktopView from './view/DesktopView';
import { recalculateGoalsBasedofName } from '../../../utils/resolvers';
import { calculateAndSaveAchievements } from '../../../utils/achievements'; // Importe a função de conquistas
import { useLoader } from '../../../context/LoaderContext';

const ReturnsTable = ({
  investmentDataByType,
  clientId,
  goalName,
  isPremiumUser,
  isAdminUser,
  goalsDetails,
  fetchGoalDetails,
  noInvestment,
  isCustomGoal,
  customGoalKey,
}) => {
  const investments = goalsDetails?.investments?.registeredContributions || [];
  const [editedInvestments, setEditedInvestments] = useState([]);
  const [addingNewInvestment, setAddingNewInvestment] = useState(false);
  const [key, setKey] = useState(0);

  const { showLoader, hideLoader } = useLoader();

  const handleAddNewInvestment = () => {
    setAddingNewInvestment(true);
  };

  const handleInvestmentSubmit = async (investment) => {
    const userId = clientId;
    const goalKey = isCustomGoal ? customGoalKey : goalName;
    const portfolioPath = isCustomGoal
      ? `users/${userId}/goals/customGoals/${goalKey}/investments/registeredContributions`
      : `users/${userId}/goals/${goalKey}/investments/registeredContributions`;

    const appPhasePath = `users/${userId}/faseApp`;

    showLoader('Registrando investimento...');

    try {
      if (investment.id) {
        const investmentIndex = investments.findIndex((inv) => inv.id === investment.id);
        if (investmentIndex !== -1) {
          investments[investmentIndex] = investment;
          await set(ref(database, portfolioPath), investments);
          await set(ref(database, appPhasePath), 'concluido');
          await recalculateGoalsBasedofName(goalName, userId);
          fetchGoalDetails();
        } else {
          toast({
            type: 'error',
            text: 'Investimento não encontrado',
          });
          return;
        }
      } else {
        investment.id = uuidv4();
        investments.push(investment);
        await set(ref(database, portfolioPath), investments);
        await set(ref(database, appPhasePath), 'concluido');
      }
      setKey((prevKey) => prevKey + 1);
      await recalculateGoalsBasedofName(goalName, userId);

      // Fetch updated goals
      const db = getDatabase();
      const goalsRef = ref(db, `users/${userId}/goals`);
      const goalsSnapshot = await get(goalsRef);
      const updatedGoals = goalsSnapshot.exists() ? goalsSnapshot.val() : {};

      // Calculate and save achievements
      await calculateAndSaveAchievements(userId, updatedGoals);

      const userWhatsAppNumber = '+553197508819';
      const message =
        'Olá, seus investimentos já foram alinhados com seus objetivos! Converse com seu consultor para acompanhar seu plano financeiro.';
      await sendMessage(userWhatsAppNumber, message);
      toast({
        type: 'success',
        text: 'Investimento registrado com sucesso!',
      });
      fetchGoalDetails();
    } catch (error) {
      toast({
        type: 'error',
        text: 'Erro ao registrar o investimento',
      });
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <DesktopView
        key={key}
        investments={investments}
        editedInvestments={editedInvestments}
        handleAddNewInvestment={handleAddNewInvestment}
        addingNewInvestment={addingNewInvestment}
        handleInvestmentSubmit={handleInvestmentSubmit}
        setAddingNewInvestment={setAddingNewInvestment}
        setEditedInvestments={setEditedInvestments}
        userId={clientId}
        goalName={goalName}
        fetchGoalDetails={fetchGoalDetails}
        noInvestment={noInvestment}
      />
    </>
  );
};

export default ReturnsTable;
