import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, TextField, Typography, Radio, Box, RadioGroup, FormControlLabel, Button } from '@mui/material';
import { set, ref, get } from 'firebase/database';
import { fCurrency, unformatedValue } from '../../utils/formatNumber';
import { auth, database } from '../../services/firebase';
import toast from '../../utils/toast';
import { recalculateGoalsBasedofName } from '../../utils/resolvers';
import CustomButton from '../button/CustomButton';
import MyTextField from '../text-field/MyTextField';

export default function ChildrenEducationSection({ userIdParams, onDataSaved }) {
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const [haveChildren, setHaveChildren] = useState('');
  const [childCost, setChildCost] = useState('');
  const [childTimeframe, setChildTimeframe] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const userIdToUse = userIdParams || (user && user.uid);
      if (userIdToUse) {
        setUserId(userIdToUse);
        const userGoalsRef = ref(database, `users/${userIdToUse}/goals/educacaoFilhos`);
        const goalsSnapshot = await get(userGoalsRef);
        if (goalsSnapshot.exists()) {
          const goalsData = goalsSnapshot.val();
          setHaveChildren(goalsData.haveChildren || '');
          setChildCost(goalsData.childCost || '');
          setChildTimeframe(goalsData.childTimeframe || '');
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const handleChildCostChange = (e) => {
    setChildCost(unformatedValue(e.target.value));
    updateIsEmpty();
  };

  const handleChildTimeframeChange = (e) => {
    setChildTimeframe(e.target.value);
    updateIsEmpty();
  };

  const updateIsEmpty = useCallback(() => {
    setIsEmpty(!haveChildren || (haveChildren === 'yes' && (!childCost || !childTimeframe)));
  }, [haveChildren, childCost, childTimeframe]);

  const handleSave = async () => {
    if (!userId) return;
    try {
      const userGoalsRef = ref(database, `users/${userId}/goals/educacaoFilhos`);
      await set(userGoalsRef, {
        haveChildren,
        childCost,
        childTimeframe,
      });
      await recalculateGoalsBasedofName('educacaoFilhos', userId);
      toast({
        type: 'success',
        text: 'Dados de educação dos filhos salvos com sucesso!',
      });
      onDataSaved();
    } catch (error) {
      toast({
        type: 'error',
        text: 'Erro ao salvar dados de educação dos filhos',
      });
    }
  };

  const formattedChildCost = fCurrency(childCost);

  return (
    <Container
      sx={{
        marginBottom: '1rem',
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <Typography
        sx={{
          fontSize: '1.5625rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 1.7,
        }}
      >
        Educação dos filhos
      </Typography>
      {
        <>
          <Typography variant="body1">
            Alguns dos gastos comuns relacionados à educação dos filhos incluem mensalidades escolares, materiais
            didáticos, atividades extracurriculares e, no futuro, as despesas universitárias. Esses custos podem variar
            dependendo do tipo de escola e das ambições educacionais que você tem para seus filhos. Gostaria de saber
            qual é a sua percepção e estimação em relação a esses gastos. Considerando todos esses fatores, qual valor
            você acredita que um filho poderá custar mensalmente?
          </Typography>
          <MyTextField
            type="text"
            value={formattedChildCost}
            onChange={handleChildCostChange}
            variant="outlined"
            margin="normal"
            fullWidth
            InputLabelProps={{
              sx: {
                fontSize: '1.3rem',
                color: '#E8E8E8',
              },
            }}
            required
          />
          <Typography variant="body1">E em relação ao prazo, qual sua expectativa?</Typography>
          <MyTextField
            type="number"
            label="Prazo para gastos com filhos"
            value={childTimeframe || ''}
            onChange={handleChildTimeframeChange}
            variant="outlined"
            margin="normal"
            fullWidth
            InputLabelProps={{
              sx: {
                fontSize: '1.3rem',
                color: '#E8E8E8',
              },
            }}
            required
          />
          <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '1rem' }}>
            <CustomButton variant="custom" sx={{ width: '12rem', marginRight: '1rem' }} onClick={() => onDataSaved()}>
              Voltar
            </CustomButton>
            <CustomButton variant="primary" sx={{ width: '12rem' }} onClick={handleSave}>
              Salvar
            </CustomButton>
          </Box>
        </>
      }
    </Container>
  );
}
