import React from 'react';
import { styled } from '@mui/material/styles';

const CustomMainContainer = styled('main')({
  width: '100%',
  maxWidth: '1300px',
  margin: '0.8rem auto 0',
  padding: '1.6rem 1.5rem',
});

export default function CustomContainer({ children, ...other }) {
  return <CustomMainContainer {...other}>{children}</CustomMainContainer>;
}
