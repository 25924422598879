import React from 'react';
import { Box, Typography, TextField } from '@mui/material';

function CustomTextField({ label, value, onChange, placeholder, labelColor="primary" }) {
  return (
    <Box display="flex" alignItems="center" gap={2} width={650}>
       <Box width={480} textAlign="right">
       <Typography variant="body1" sx={{fontSize: '15px'}} color={labelColor}>{label}</Typography>
      </Box>
      <TextField 
          fullWidth 
          variant="outlined" 
          value={value} 
          onChange={onChange}
          placeholder={placeholder}
      />
    </Box>
  );
}

export default CustomTextField;
