import React from 'react';
import { Typography, Box } from "@mui/material";

export default function Neuroticismo({ data }) {
  if (!data) {
    return null;
  }

  const { facets } = data;

  return (
    <div>
      {facets.map((facet, index) => (
        <Box key={index} sx={{ marginBottom: 0.5, padding: 1,}}>
          <Box sx={{display: 'flex', flexDirection: 'row',alignItems: 'center', gap: '0.5rem'}}>
            <Typography variant="subtitle1">{facet.title} - </Typography>
            <Typography variant="caption">Score: {facet.score} - {facet.scoreText}</Typography>
          </Box>
          <Typography sx={{fontSize: '0.785rem', fontStyle: 'normal',lineHeight: '1.7'}}>{facet.text}</Typography>
        </Box>
      ))}
    </div>
  );
}
