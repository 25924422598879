import { ref, get, set } from 'firebase/database';
import { auth, database } from '../services/firebase';

// Function to calculate the future value based on inflation
const calculateFutureValue = (currentValue, inflationRate, years) => {
  const inflationRatePercentage = inflationRate;
  console.log(years, inflationRatePercentage);
  const futureValue = currentValue * (1 + inflationRatePercentage) ** years;
  return +futureValue.toFixed(2);
};

// Function to retrieve user goals
const getUserGoals = async (userId) => {
  const userGoalsRef = ref(database, `users/${userId}/goals`);
  const userGoalsSnapshot = await get(userGoalsRef);
  return userGoalsSnapshot.val();
};

const getNominalInterestMonthly = async () => {
  const adminRef = ref(database, 'admin/monthlyInterestNominal');
  const adminSnapshot = await get(adminRef);
  return adminSnapshot.val();
};
// Function to retrieve monthly inflation rate
const getInflationRate = async () => {
  const adminRef = ref(database, 'admin/monthlyIflationRate');
  const adminSnapshot = await get(adminRef);
  const monthlyIflationRate = adminSnapshot.val();
  console.log(+monthlyIflationRate);
  return +monthlyIflationRate;
};
// Function to retrieve real interest rates
const getRealInterestRates = async () => {
  const realInterestRef = ref(database, 'admin/jurosReal');
  const realInterestSnapshot = await get(realInterestRef);
  return realInterestSnapshot.val();
};

const getTotalInvestmentbyGoal = (investments) => {
  let totalInvestment = 0;

  if (investments && investments.registeredContributions) {
    investments.registeredContributions.forEach((contribution) => {
      totalInvestment += parseFloat(contribution.ValorLiquido);
    });
    return totalInvestment.toFixed(2);
  }
  return totalInvestment;
};

export const calculateRetirement = async (userId) => {
  const goals = await getUserGoals(userId);
  const inflationRateValue = await getInflationRate();
  const jurosAscendaMensal = await getNominalInterestMonthly();

  // Obtain retirement information
  const retirementAmount = parseFloat(goals.aposentadoria.retirementAmount);
  const retirementYears = parseInt(goals.aposentadoria.retirementYears, 10);
  const { investments } = goals.aposentadoria;

  // Calculate retirement months
  const retirementMonths = retirementYears * 12;

  // Calculate future value
  const futureValue = calculateFutureValue(retirementAmount, inflationRateValue, retirementMonths);
  let targetAmount = (futureValue / +jurosAscendaMensal).toFixed(4);

  const targetAMountToal = targetAmount;
  const totalInvestment = getTotalInvestmentbyGoal(investments);

  if (totalInvestment) {
    targetAmount -= totalInvestment;
  }

  // Calculate required contributions
  const nominalInterestMonthly = await getNominalInterestMonthly();
  const requiredContributions = (
    (targetAmount * nominalInterestMonthly) /
    ((1 + Number(nominalInterestMonthly)) ** retirementMonths - 1)
  ).toFixed(2);
  const goalPath = `users/${userId}/goals/aposentadoria`;
  const retirementPath = `${goalPath}/investments/requiredContributions`;
  const retirementPathTarget = `${goalPath}/investments/targetValue`;

  const futureValuePath = `${goalPath}/investments/futureValue`;
  await set(ref(database, futureValuePath), targetAmount);
  await set(ref(database, retirementPath), requiredContributions);
  await set(ref(database, retirementPathTarget), targetAMountToal);
  await set(ref(database, futureValuePath), futureValue.toFixed(2));
};

export const calculatePropertyPurchase = async (userId) => {
  const goals = await getUserGoals(userId);
  if (goals.compraImovel.buyProperty === 'no') {
    return null;
  }

  // Obtém as informações do objetivo de compra de imóvel
  let propertyValue = parseFloat(goals.compraImovel.propertyValue);
  const goalYears = parseInt(goals.compraImovel.yearsToBuyProperty, 10);
  const { investments } = goals.compraImovel;

  // Converte o período do objetivo em meses
  const goalMonths = goalYears * 12;

  // Calcula as contribuições necessárias
  const nominalInterestMonthly = await getNominalInterestMonthly();

  const targetAmount = propertyValue.toFixed(2);
  const totalInvestment = getTotalInvestmentbyGoal(investments);

  // Se o investimento total for maior ou igual ao valor alvo, a contribuição necessária é 0
  if (totalInvestment >= propertyValue) {
    await set(ref(database, `users/${userId}/goals/compraImovel/investments/requiredContributions`), 0);
    await set(ref(database, `users/${userId}/goals/compraImovel/investments/targetValue`), targetAmount);
    return 0;
  }

  // Se o investimento total for menor que o valor alvo, calcular a contribuição necessária
  if (totalInvestment) {
    propertyValue -= totalInvestment;
  }
  const requiredContributions = (
    (propertyValue * nominalInterestMonthly) /
    ((1 + Number(nominalInterestMonthly)) ** goalMonths - 1)
  ).toFixed(2);

  const goalPath = `users/${userId}/goals/compraImovel`;
  const homePurchasePath = `${goalPath}/investments/requiredContributions`;
  const homePurchaseTargetPath = `${goalPath}/investments/targetValue`;

  await set(ref(database, homePurchasePath), requiredContributions);
  await set(ref(database, homePurchaseTargetPath), targetAmount);

  return requiredContributions;
};

export const calculateEducationExpenses = async (userId) => {
  const goals = await getUserGoals(userId);
  if (goals.educacaoFilhos.haveChildren === 'no') {
    return null;
  }

  // Obtém as informações das despesas com educação
  let childCost = parseFloat(goals.educacaoFilhos.childCost);
  const goalYears = parseInt(goals.educacaoFilhos.childTimeframe, 10);
  const { investments } = goals.educacaoFilhos;
  const totalInvestment = getTotalInvestmentbyGoal(investments);

  // Calcula o período do objetivo em meses
  const goalMonths = goalYears * 12;

  const nominalInterestMonthly = await getNominalInterestMonthly();

  // Calcula as contribuições necessárias
  const targetAmount = childCost.toFixed(2);

  // Se o investimento total for maior ou igual ao valor alvo, a contribuição necessária é 0
  if (totalInvestment >= childCost) {
    await set(ref(database, `users/${userId}/goals/educacaoFilhos/investments/requiredContributions`), 0);
    await set(ref(database, `users/${userId}/goals/educacaoFilhos/investments/targetValue`), targetAmount);
    return 0;
  }

  // Se o investimento total for menor que o valor alvo, calcular a contribuição necessária
  if (totalInvestment) {
    childCost -= totalInvestment;
  }
  const requiredContributions = (
    (childCost * nominalInterestMonthly) /
    ((1 + Number(nominalInterestMonthly)) ** goalMonths - 1)
  ).toFixed(2);

  const goalPath = `users/${userId}/goals/educacaoFilhos`;
  const educationExpensesPath = `${goalPath}/investments/requiredContributions`;
  const educationExpensesTargetPath = `${goalPath}/investments/targetValue`;

  await set(ref(database, educationExpensesPath), requiredContributions);
  await set(ref(database, educationExpensesTargetPath), targetAmount);

  // Retorna o resultado
  return requiredContributions;
};

export const calculateGoalCar = async (userId) => {
  const goals = await getUserGoals(userId);

  // Obtain education expenses information
  let childCost = parseFloat(goals.car.valueCar);
  const goalYears = parseInt(goals.car.carTimeFrame, 10);
  const { investments } = goals.car;
  const totalInvestmentValue = getTotalInvestmentbyGoal(investments);

  // Calculate goal months
  const goalMonths = goalYears * 12;

  const nominalInterestMonthly = await getNominalInterestMonthly();

  // Calculate the required contributions
  const targetAmount = childCost.toFixed(2);

  if (totalInvestmentValue) {
    childCost -= totalInvestmentValue;
  }
  const requiredContributions = (
    (childCost * nominalInterestMonthly) /
    ((1 + Number(nominalInterestMonthly)) ** goalMonths - 1)
  ).toFixed(2);

  const goalPath = `users/${userId}/goals/car`;
  const educationExpensesPath = `${goalPath}/investments/requiredContributions`;

  const educationExpensesTargetPath = `${goalPath}/investments/targetValue`;
  await set(ref(database, educationExpensesPath), requiredContributions);
  await set(ref(database, educationExpensesTargetPath), targetAmount);

  // Return the result
  return requiredContributions;
};

export const calculateTravelAndLeisure = async (userId) => {
  const goals = await getUserGoals(userId);
  const realInterest = await getRealInterestRates();

  // Obtain travel and leisure information
  const annualExpense = parseFloat(goals.viagensLazer.travelCost);

  const goalYears = parseInt(goals.viagensLazer.travelTimeframe, 10);
  const { investments } = goals.viagensLazer;
  const totalInvestment = Number(getTotalInvestmentbyGoal(investments));
  let futureValue = (annualExpense / realInterest).toFixed(2);
  const targetAmount = futureValue;
  const nominalInterestMonthly = await getNominalInterestMonthly();
  const goalMonths = goalYears * 12;

  if (totalInvestment) {
    futureValue -= Number(totalInvestment);
  }

  const requiredContributions = (
    (futureValue * nominalInterestMonthly) /
    ((1 + Number(nominalInterestMonthly)) ** goalMonths - 1)
  ).toFixed(2);

  const goalPath = `users/${userId}/goals/viagensLazer`;
  const contributionsPath = `${goalPath}/investments/requiredContributions`;
  await set(ref(database, contributionsPath), requiredContributions);

  const contributionsTargetPath = `${goalPath}/investments/targetValue`;

  await set(ref(database, contributionsTargetPath), targetAmount);

  // Return the result
  return requiredContributions;
};

export const calculateOtherGoals = async (userId) => {
  const goals = await getUserGoals(userId);
  if (goals.outrosObjetivos.hasOtherGoals === 'no') {
    return null;
  }
  const nominalInterestMonthly = await getNominalInterestMonthly();
  const inflationRate = await getInflationRate();

  let projectValue = parseFloat(goals.outrosObjetivos.projectValue);
  const goalYears = parseInt(goals.outrosObjetivos.projectTimeframe, 10);
  const { investments } = goals.outrosObjetivos;
  const totalInvestmentValue = Number(getTotalInvestmentbyGoal(investments));

  if (totalInvestmentValue) {
    projectValue -= totalInvestmentValue;
  }
  // Calculando as contribuições a partir do juros ascenda mensal
  const futureValue = projectValue * (1 + inflationRate / 100) ** goalYears;
  const requiredContributions = (
    (futureValue * Number(nominalInterestMonthly)) /
    ((1 + Number(nominalInterestMonthly)) ** (goalYears * 12) - 1)
  ).toFixed(2);

  const targetAmount = futureValue.toFixed(2);
  const goalPath = `users/${userId}/goals/outrosObjetivos`;
  const otherGoalsPath = `${goalPath}/investments/requiredContributions`;
  await set(ref(database, otherGoalsPath), requiredContributions);

  const otherGoalsTargetPath = `${goalPath}/investments/targetValue`;
  await set(ref(database, otherGoalsTargetPath), targetAmount);

  // Return the result
  return requiredContributions;
};

export const calculateEmergencyFund = async (userId) => {
  const goals = await getUserGoals(userId);
  const { emergencyFund } = goals.reservaEmergência;

  const goalPath = `users/${userId}/goals/reservaEmergência`;
  const emergencyFundPath = `${goalPath}/investments/targetValue`;
  await set(ref(database, emergencyFundPath), emergencyFund);
};

export const calculateAllGoals = async (userId) => {
  await calculateRetirement(userId);
  await calculatePropertyPurchase(userId);
  await calculateEducationExpenses(userId);
  await calculateGoalCar(userId);
  await calculateTravelAndLeisure(userId);
  await calculateOtherGoals(userId);
  await calculateEmergencyFund(userId);
};

export const calculateCustomGoalContributions = async (userId, customGoalKey) => {
  const goals = await getUserGoals(userId);
  const customGoal = goals.customGoals[customGoalKey];
  if (!customGoal) {
    console.error('Custom goal not found');
    return null;
  }

  let targetValue = parseFloat(customGoal.targetValue);
  const goalYears = parseInt(customGoal.timeFrame, 10);
  const investments = customGoal.investments || { registeredContributions: [] };

  // Convert the goal time frame to months
  const goalMonths = goalYears * 12;

  // Calculate required contributions
  const nominalInterestMonthly = await getNominalInterestMonthly();

  const targetAmount = targetValue.toFixed(2);
  const totalInvestment = getTotalInvestmentbyGoal(investments);

  // Se o investimento total for maior ou igual ao valor alvo, a contribuição necessária é 0
  if (totalInvestment >= targetValue) {
    await set(ref(database, `users/${userId}/goals/customGoals/${customGoalKey}/investments/requiredContributions`), 0);
    await set(
      ref(database, `users/${userId}/goals/customGoals/${customGoalKey}/investments/targetValue`),
      targetAmount
    );
    return 0;
  }

  // Se o investimento total for menor que o valor alvo, calcular a contribuição necessária
  if (totalInvestment) {
    targetValue -= totalInvestment;
  }
  const requiredContributions = (
    (targetValue * nominalInterestMonthly) /
    ((1 + Number(nominalInterestMonthly)) ** goalMonths - 1)
  ).toFixed(2);

  const goalPath = `users/${userId}/goals/customGoals/${customGoalKey}`;
  const contributionsPath = `${goalPath}/investments/requiredContributions`;
  const targetPath = `${goalPath}/investments/targetValue`;

  await set(ref(database, contributionsPath), requiredContributions);
  await set(ref(database, targetPath), targetAmount);

  return requiredContributions;
};

export const recalculateGoalsBasedofName = async (goalName, userId = null) => {
  auth.onAuthStateChanged(async (user) => {
    if (user) {
      // User is signed in
      const currentUserId = userId || user.uid;

      // Fetch the user goals from Firebase
      const userGoalsRef = ref(database, `users/${currentUserId}/goals`);
      const snapshot = await get(userGoalsRef);

      if (snapshot.exists()) {
        const goalsData = snapshot.val();

        // Check for standard goals
        switch (goalName) {
          case 'aposentadoria':
            await calculateRetirement(currentUserId);
            break;
          case 'compraImovel':
            await calculatePropertyPurchase(currentUserId);
            break;
          case 'educacaoFilhos':
            await calculateEducationExpenses(currentUserId);
            break;
          case 'car':
            await calculateGoalCar(currentUserId);
            break;
          case 'viagensLazer':
            await calculateTravelAndLeisure(currentUserId);
            break;
          case 'outrosObjetivos':
            await calculateOtherGoals(currentUserId);
            break;
          default:
            // Check for custom goals
            if (
              goalsData.customGoals &&
              Object.keys(goalsData.customGoals).some((key) => goalsData.customGoals[key].name === goalName)
            ) {
              const customGoalKey = Object.keys(goalsData.customGoals).find(
                (key) => goalsData.customGoals[key].name === goalName
              );
              if (customGoalKey) {
                await calculateCustomGoalContributions(currentUserId, customGoalKey);
              }
            } else {
              console.error('Goal not found:', goalName);
            }
            break;
        }
      } else {
        console.error('No goals found for this user:', currentUserId);
      }
    } else {
      console.error('No user is signed in');
    }
  });
};

// FUnção temporaria específica para lidar com o problema de sincrronismo ao deletar um investimento, no momenti foi a única solução viável
export const recalculateGoalsBasedofNameForDeleteInvestment = async (goalName, userId) => {
  if (!userId) {
    console.error('No user ID provided');
    return;
  }

  if (goalName === 'aposentadoria') {
    await calculateRetirement(userId);
  } else if (goalName === 'compraImovel') {
    await calculatePropertyPurchase(userId);
  } else if (goalName === 'educacaoFilhos') {
    await calculateEducationExpenses(userId);
  } else if (goalName === 'car') {
    await calculateGoalCar(userId);
  } else if (goalName === 'viagensLazer') {
    await calculateTravelAndLeisure(userId);
  } else if (goalName === 'outrosObjetivos') {
    await calculateOtherGoals(userId);
  } else if (goalName === 'reservaEmergência') {
    await calculateEmergencyFund(userId);
  }
};
