import React, { useState, useEffect } from 'react';
import { Container, Typography, Dialog, DialogContent, DialogContentText, DialogActions, Box } from '@mui/material';
import { set, ref, push, get } from 'firebase/database';
import { auth, database } from '../../services/firebase';
import toast from '../../utils/toast';
import CustomButton from '../button/CustomButton';
import MyTextField from '../text-field/MyTextField';
import { fCurrency, unformatedValue } from '../../utils/formatNumber';

export default function AddCustomGoalForm({ userIdParams, onDataSaved, currentGoalData }) {
  const [userId, setUserId] = useState(null);
  const [goalName, setGoalName] = useState('');
  const [targetValue, setTargetValue] = useState('');
  const [timeFrame, setTimeFrame] = useState('');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(userIdParams || user.uid);
      }
    });
    if (currentGoalData) {
      setGoalName(currentGoalData.name);
      setTargetValue(fCurrency(currentGoalData.targetValue));
      setTimeFrame(currentGoalData.timeFrame);
    }
  }, [userIdParams, currentGoalData]);

  const handleSave = async () => {
    if (!userId) return;

    const goalsRef = ref(database, `users/${userId}/goals/customGoals`);
    const goalKey = currentGoalData?.id || push(goalsRef).key;
    const goalRef = ref(database, `users/${userId}/goals/customGoals/${goalKey}`);

    if (!currentGoalData) {
      // Verifica se estamos adicionando um novo objetivo
      const snapshot = await get(goalsRef);
      const customGoals = snapshot.val();
      const numberOfGoals = customGoals ? Object.keys(customGoals).length : 0;

      if (numberOfGoals >= 10) {
        setOpenModal(true);
        return;
      }
    }

    try {
      const newGoal = {
        name: goalName,
        targetValue: unformatedValue(targetValue),
        timeFrame,
      };
      await set(goalRef, newGoal);
      toast({
        type: 'success',
        text: currentGoalData ? 'Objetivo extra atualizado com sucesso!' : 'Objetivo extra adicionado com sucesso!',
      });
      onDataSaved();
    } catch (error) {
      toast({
        type: 'error',
        text: currentGoalData ? 'Erro ao atualizar o objetivo extra' : 'Erro ao adicionar o objetivo extra',
      });
    }
  };

  return (
    <Container
      sx={{ marginBottom: '2rem', marginTop: '2rem', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
    >
      <Typography variant="body1">Você tem algum objetivo financeiro em mente?</Typography>
      <Typography variant="body1">
        Pode ser o carro dos sonhos, abrir uma empresa, algum hobby ou qualquer projeto que tenha valor financeiro.
      </Typography>
      <Typography variant="body1" mb={2}>
        Nós vamos te ajudar a chegar lá!
      </Typography>

      <Typography variant="body1">Dê um nome para seu objetivo</Typography>
      <MyTextField
        style={{ marginTop: '-8px' }}
        value={goalName}
        onChange={(e) => setGoalName(e.target.value)}
        margin="normal"
        fullWidth
        required
      />

      <Typography>Qual o valor total desse projeto?</Typography>
      <MyTextField
        style={{ marginTop: '-8px' }}
        type="text"
        value={targetValue}
        onChange={(e) => setTargetValue(fCurrency(unformatedValue(e.target.value)))}
        margin="normal"
        fullWidth
        required
      />

      <Typography mt={2}>Tempo para Atingir (em anos)</Typography>
      <MyTextField
        style={{ marginTop: '-8px' }}
        type="number"
        value={timeFrame}
        onChange={(e) => setTimeFrame(e.target.value)}
        margin="normal"
        fullWidth
        required
      />
      <CustomButton onClick={handleSave} sx={{ mt: 2 }}>
        Salvar
      </CustomButton>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            backgroundColor: '#DBF226',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            width: '530px',
            borderRadius: '2rem',
            height: '295px',
            padding: '2rem',
          },
        }}
      >
        <Box sx={{ position: 'absolute', top: '1rem', right: '1rem' }}>
          <img src="/assets/icons/wealthmap.png" alt="Wealth Map" style={{ width: '4rem', height: '4rem' }} />
        </Box>
        <DialogContent sx={{ marginTop: '3rem' }}>
          <DialogContentText sx={{ color: '#000', fontSize: '1.5rem' }} id="alert-dialog-description">
            Você não pode mais
          </DialogContentText>
          <DialogContentText
            sx={{ color: '#000', fontSize: '2rem', fontFamily: 'TimesNow-Light' }}
            id="alert-dialog-description"
          >
            adicionar objetivos.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end', padding: '1rem' }}>
          <CustomButton
            onClick={() => {
              setOpenModal(false);
              onDataSaved();
            }}
            sx={{
              backgroundColor: 'black',
              width: '150px',
              color: 'white',
              '&:hover': {
                backgroundColor: '#333',
              },
              position: 'absolute',
              right: '1rem',
            }}
          >
            Voltar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
