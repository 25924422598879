import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, get, set } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import PropTypes from 'prop-types';
import { useUser } from '../../context/UserContext'; // Import UserContext
import { cleanCurrencyFormat, formatToBRL } from '../../utils/formatNumber';
import CustomTextField from '../text-field/CustomTextField';
import CustomButton from '../button/CustomButton';
import toast from '../../utils/toast';

function FinancialDataForm({ isAdmin, onSubmit, handleNextTab }) {
  const { clientId } = useParams();
  const { currentUser } = useUser(); // Access currentUser from UserContext
  const [initialData, setInitialData] = useState({});
  const [monthlyIncome, setMonthlyIncome] = useState(0);
  const [monthlyExpense, setMonthlyExpense] = useState(0);
  const [totalInvestments, setTotalInvestments] = useState(0);
  const [hasDebt, setHasDebt] = useState(false);
  const [totalDebt, setTotalDebt] = useState(0);
  const [userId, setUserId] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        if (!isAdmin) {
          fetchClientData(user.uid);
        }
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, [isAdmin]);

  useEffect(() => {
    if (isAdmin && clientId) {
      fetchClientData(clientId);
    } else if (!isAdmin && currentUser) {
      fetchClientData(currentUser); // Fetch data using currentUser from UserContext
    }
  }, [isAdmin, clientId, currentUser]);

  const fetchClientData = async (id) => {
    const database = getDatabase();
    const clientRef = ref(database, `users/${id}/financial`);
    const snapshot = await get(clientRef);
    console.log(id);

    if (snapshot.exists()) {
      const data = snapshot.val();

      console.log(data);
      setInitialData(data);
      setMonthlyIncome(data.monthlyIncome || 0);
      setMonthlyExpense(data.monthlyExpense || 0);
      setTotalInvestments(data.totalInvestments || 0);
      setTotalDebt(data.totalDebt || 0);
      setHasDebt(data.hasDebt || false);
    }
  };

  // useEffect(() => {
  //   const checkFormValidity = () => {
  //     const fields = [monthlyIncome, monthlyExpense, totalInvestments, totalDebt];
  //     const isValid = fields.every((field) => field !== 0 && field !== null && field !== undefined);
  //     setIsFormValid(isValid);
  //   };

  //   checkFormValidity();
  // }, [monthlyIncome, monthlyExpense, totalInvestments, totalDebt]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const id = isAdmin ? clientId : userId;
      const database = getDatabase();
      const clientRef = ref(database, `users/${id}/financial`);
      await set(clientRef, {
        monthlyIncome,
        monthlyExpense,
        totalInvestments,
        hasDebt,
        totalDebt,
      });

      handleNextTab();
      toast({
        type: 'success',
        text: 'Dados financeiros cadastrados com sucesso.',
        duration: 4000,
      });
    } catch (e) {
      toast({
        type: 'error',
        text: 'Ocorreu um erro ao registrar. Por favor, tente novamente.',
        duration: 4000,
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Box mb={1}>
        <Typography variant="h5" textAlign="center">
          Suas estimativas financeiras
        </Typography>
        <Typography variant="body2" textAlign="center">
          Não precisa pegar seus extratos, coloque as estimativas que possui de cabeça mesmo
        </Typography>
      </Box>

      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Box display="flex" flexDirection="column" gap={2} width="100%" justifyContent="center" alignItems="center">
          <CustomTextField
            label="Qual sua renda média mensal?"
            variant="outlined"
            labelColor="#E6E6E6"
            value={formatToBRL(monthlyIncome)}
            onChange={(e) => setMonthlyIncome(cleanCurrencyFormat(e.target.value))}
          />
          <CustomTextField
            label="Qual seu gasto médio mensal?"
            labelColor="#E6E6E6"
            variant="outlined"
            value={formatToBRL(monthlyExpense)}
            onChange={(e) => setMonthlyExpense(cleanCurrencyFormat(e.target.value))}
          />
          <CustomTextField
            label="Quanto você possui de investimentos?"
            labelColor="#E6E6E6"
            variant="outlined"
            value={formatToBRL(totalInvestments)}
            onChange={(e) => setTotalInvestments(cleanCurrencyFormat(e.target.value))}
          />
          <CustomTextField
            label="Possui dívidas? Se sim, qual valor total delas?"
            labelColor="#E6E6E6"
            variant="outlined"
            value={formatToBRL(totalDebt)}
            onChange={(e) => setTotalDebt(cleanCurrencyFormat(e.target.value))}
          />
          <CustomButton
            type="submit"
            // variant={isFormValid ? 'primary' : 'disabled'}
            variant="primary"
            sx={{ alignSelf: 'center', marginLeft: '17rem' }}
          >
            Registrar
          </CustomButton>
        </Box>
      </form>
    </Box>
  );
}

FinancialDataForm.propTypes = {
  isAdmin: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  handleNextTab: PropTypes.func,
};

FinancialDataForm.defaultProps = {
  isAdmin: false,
  handleNextTab: () => {},
};

export default FinancialDataForm;
