import React from 'react';
import ApexCharts from 'react-apexcharts';

const BigFiveChart = React.forwardRef(({ results, width = 450, height = 450 }, ref) => {
  if (!results) {
    return <div>Carregando gráfico...</div>;
  }
  const categories = results.map((domainResult) => domainResult.title);
  const series = results.map((domainResult) => domainResult.score);

  const chartData = {
    options: {
      chart: {
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          track: {
            background: '#000',
            strokeWidth: '50%', // Ajusta a espessura das barras
          },
          dataLabels: {
            total: {
              show: false,
            },
            value: {
              show: true,
              formatter: (val) => (val / 100) * 100,
              offsetY: 10, // Adjust vertical position
              color: '#fff', // Set color to black
              fontSize: '14px', // Set font size
            },
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '12px',
        labels: {
          colors: '#ffffff', // Set the legend color to white
        },
      },
      tooltip: {
        // Added tooltip configuration
        enabled: true,
        shared: true,
        followCursor: false,
        intersect: false,
        style: {
          fontSize: '12px',
        },
        onDatasetHover: {
          highlightDataSeries: true,
        },
        x: {
          show: true,
        },
        marker: {
          show: true,
        },
      },
      labels: categories,
    },
    series,
  };

  return (
    <div ref={ref}>
      <ApexCharts
        options={chartData.options}
        series={chartData.series}
        type="radialBar"
        height={height}
        width={width}
      />
    </div>
  );
});

export default BigFiveChart;
