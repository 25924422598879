import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, styled, Box, Stack, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getDatabase, ref, get, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { West as WestIcon, Delete, VisibilityOff, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const StyledTextField = styled(TextField)(({ type }) => ({
    width: '9rem',
    marginBottom: '0.825rem',
    height: '2.25rem',
    '& .MuiInputBase-input': {
        borderRadius: '0.3125rem',
        background: 'rgba(255, 255, 255, 0.05)',
        padding: '10px 12px',
        color: type ? getColor(type) : '#E8E8E8',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '0.3125rem',
        border: '1px solid #5B5B5B',
    },
    '& .MuiInputLabel-root': {
        transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
    }
}));


const StyledIconButton = styled(IconButton)({
    borderRadius: '0.3125rem',
    border: '1px solid #5B5B5B',
    background: '#111114',
    width: '9rem',
    height: '2.25rem',
    color: 'white',
    marginTop: '0.875rem'
});

const StyledLabel = styled(Typography)(({ type }) => ({
    borderRadius: '0.3125rem',
    background: '#000',
    color: '#E8E8E8',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '0.95rem',
    fontWeight: 600,
    lineHeight: '130%',
    padding: '0.5rem'
}))

const getColor = (type) => {
    switch (type) {
        case "Receitas":
            return "#8CE446";
        case "Despesas":
            return "#FF0062";
        case "Investimentos":
            return "#E697FF";
        default:
            return "#000";
    }
};


export default function ManageBudget() {
    const [data, setData] = useState({});
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const db = getDatabase();
            const dataRef = ref(db, `users/${userId}/finance`);
            const snapshot = await get(dataRef);
            if (snapshot.exists()) {
                setData(snapshot.val());
            } else {
                console.log("No data available");
            }
        };

        fetchData();
    }, [userId]);

    const handleAddCategory = async (type, groupId) => {
        const newCategoryId = `_${Math.random().toString(36).substr(2, 9)}`;
        const db = getDatabase();
        await set(ref(db, `users/${userId}/finance/${type}/groups/${groupId}/categories/${newCategoryId}`), {
            name: "Nova Categoria",
            isVisible: true
        });
        // Atualiza o estado local
        const updatedData = { ...data };
        updatedData[type].groups[groupId].categories[newCategoryId] = {
            name: "Nova Categoria",
            isVisible: true
        };

        const newTransactionId = `_${Math.random().toString(36).substr(2, 9)}`;
        const zeroTransaction = {
            description: "Transação inicial",
            price: 0,
            estimated: 0,
            group: groupId,
            category: newCategoryId,
            type, 
            date: new Date().toISOString(),
        };
        await set(ref(db, `users/${userId}/transactions/${newTransactionId}`), zeroTransaction);
        setData(updatedData);
    };


  const handleGoBack = () => {
    navigate(-1);
  };

    const handleCategoryChange = async (type, groupId, categoryId, newValue) => {
        const db = getDatabase();
        await set(ref(db, `users/${userId}/finance/${type}/groups/${groupId}/categories/${categoryId}/name`), newValue);
        // Atualiza o estado local
        const updatedData = { ...data };
        updatedData[type].groups[groupId].categories[categoryId].name = newValue;
        setData(updatedData);
    };

    const handleToggleVisibility = async (type, groupId, categoryId) => {
        const isVisible = !data[type].groups[groupId].categories[categoryId].isVisible;
        const db = getDatabase();
        await set(ref(db, `users/${userId}/finance/${type}/groups/${groupId}/categories/${categoryId}/isVisible`), isVisible);
        
        // Update local state
        const updatedData = { ...data };
        updatedData[type].groups[groupId].categories[categoryId].isVisible = isVisible;
        setData(updatedData);
    };

    // Function to delete a category
    const handleDeleteCategory = async (type, groupId, categoryId) => {
        const db = getDatabase();
        await set(ref(db, `users/${userId}/finance/${type}/groups/${groupId}/categories/${categoryId}`), null);
        
        // Update local state
        const updatedData = { ...data };
        delete updatedData[type].groups[groupId].categories[categoryId];
        setData(updatedData);
    };

    const handleGroupChange = async (type, groupId, newValue) => {
        const db = getDatabase();
        await set(ref(db, `users/${userId}/finance/${type}/groups/${groupId}/name`), newValue);
        // Atualiza o estado local
        const updatedData = { ...data };
        updatedData[type].groups[groupId].name = newValue;
        setData(updatedData);
    };

    const renderType = (type) => (
        <div style={{ marginTop: '1rem' }}>
            <IconButton
          onClick={handleGoBack}
          sx={{ position: 'absolute', top: 48, left: 56 }}
        >
          <WestIcon />
        </IconButton>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '0.6rem' }}>
                <Typography sx={{
                    borderRadius: '0.3125rem', background: '#000', color: '#FFF',
                    textAlign: 'center',
                    width: '16rem',
                    fontFamily: 'Inter',
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    lineHeight: '130%',
                    padding: '0.5rem',
                    marginBottom: '1rem'
                }}> Grupos</Typography>
                <Typography sx={{
                    color: `${getColor(type)}`,
                    textAlign: 'center',
                    fontFamily: 'Inter',
                    fontSize: '1.125rem',
                    fontWeight: 600,
                    lineHeight: '130%',
                    padding: '0.5rem',
                    marginBottom: '0.85rem'

                }}>{type}</Typography>
            </div>

            <Grid container spacing={3}>
                <Grid item xs={2}>
                    <StyledLabel mb={2}>Sub Grupos</StyledLabel>
                    <StyledLabel >Categorias</StyledLabel>
                </Grid>
                {Object.keys(data[type]?.groups || {}).map(groupId => (
                    <Grid item xs={2} key={groupId}>
                        <StyledTextField
                            type={type}
                            defaultValue={data[type].groups[groupId].name}
                            onBlur={(e) => handleGroupChange(type, groupId, e.target.value)}
                        />

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }} >
                            {Object.keys(data[type].groups[groupId].categories || {}).map(categoryId => (
                                 <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} key={categoryId}>
                                 <StyledTextField
                                     defaultValue={data[type].groups[groupId].categories[categoryId].name}
                                     onBlur={(e) => handleCategoryChange(type, groupId, categoryId, e.target.value)}
                                 />
                                 <IconButton onClick={() => handleToggleVisibility(type, groupId, categoryId)}>
                                     {data[type].groups[groupId].categories[categoryId].isVisible ? <Visibility /> : <VisibilityOff />}
                                 </IconButton>
                                 <IconButton onClick={() => handleDeleteCategory(type, groupId, categoryId)}>
                                     <Delete />
                                 </IconButton>
                             </div>
                            ))}
                            <StyledIconButton
                                onClick={() => handleAddCategory(type, groupId)}
                            >
                                <AddIcon />
                            </StyledIconButton>
                        </div>
                    </Grid>
                ))}
            </Grid>

        </div>
    );
    return (
        <div style={{ width: '100%' }}>
            {renderType("Receitas")}
            {renderType("Despesas")}
            {renderType("Investimentos")}
        </div>
    );
}
