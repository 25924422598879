import React, { useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, set } from 'firebase/database';
import getResult from '@alheimsins/b5-result-text';
import { Button, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import CustomButton from '../../button/CustomButton';
import { Abertura, Afabilidade, Consciencia, Extroversao, Neuroticismo } from './results';
import BigFiveChart from './chart/BigFiveChart';
import StandardLayout from '../../../layouts/standard/StandardLayout';
import Scrollbar from '../../scrollbar/Scrollbar';
import { useUser } from '../../../context/UserContext';

function BigFiveResults({
  scores,
  isResult,
  result,
  startAddingDependent,
  isDependent,
  onDisplayProfile,
  onNext,
  onRedoProfile,
}) {
  // Adiciona onRedoProfile como prop
  const [userId, setUserId] = useState(null);
  const [results, setResults] = useState(null);
  const [activeTab, setActiveTab] = useState('N'); // Default to 'Neuroticismo'
  const { role, currentUser } = useUser();

  const renderTabContent = () => {
    switch (activeTab) {
      case 'N':
        return <Neuroticismo data={getDomainData('N')} />;
      case 'O':
        return <Abertura data={getDomainData('O')} />;
      case 'A':
        return <Afabilidade data={getDomainData('A')} />;
      case 'E':
        return <Extroversao data={getDomainData('E')} />;
      case 'C':
        return <Consciencia data={getDomainData('C')} />;
      default:
        return null;
    }
  };

  const handleAddAnotherDependentProfile = () => {
    if (startAddingDependent) {
      startAddingDependent();
    }
  };

  const handleStepFinancial = () => {
    onNext(1);
  };

  const handleDisplayProfile = () => {
    if (onDisplayProfile) {
      onDisplayProfile();
    }
  };
  const auth = getAuth();
  const database = getDatabase();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (role === 'admin') {
          setUserId(currentUser);
        } else {
          setUserId(user.uid);
        }
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, [role, currentUser, auth]);

  const saveResultsToDB = useCallback(
    (resultsText) => {
      const pathToFirebase = isDependent ? `users/${userId}/dependentes` : `users/${userId}/profile`;
      const userProfileRef = ref(database, pathToFirebase);
      set(userProfileRef, { bigFiveResults: resultsText });
    },
    [database, isDependent, userId]
  );

  useEffect(() => {
    if (scores && userId) {
      if (!isResult) {
        const resultsText = getResult({ scores, lang: 'pt-br' });
        setResults(resultsText);
        saveResultsToDB(resultsText);
      } else {
        setResults(result);
      }
    }
  }, [scores, userId, isResult, result, saveResultsToDB]);

  if (!results) {
    return <Typography variant="h6">Calculando resultados...</Typography>;
  }

  const getDomainData = (domain) => results.find((r) => r.domain === domain);

  const leftComponent = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          maxHeight: '34rem',
          justifyContent: 'flex-start',
          marginTop: '1rem',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '1.2rem',
              fontStyle: 'normal',
            }}
          >
            Perfil Encontrado!
          </Typography>
        </Box>
        <BigFiveChart results={results} />
      </Box>
    </>
  );

  const rightComponent = (
    <div style={{ marginTop: '0.585rem' }}>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', marginBottom: '0.550rem', height: '1.875rem' }}
      >
        <Button
          sx={{ borderRadius: '5rem', fontSize: '0.752rem', color: `${activeTab === 'N' ? 'primary' : 'inherit'}` }}
          variant={activeTab === 'N' ? 'contained' : 'outlined'}
          color={activeTab === 'N' ? 'primary' : 'inherit'}
          onClick={() => setActiveTab('N')}
        >
          Neuroticismo
        </Button>
        <Button
          sx={{ borderRadius: '5rem', fontSize: '0.752rem' }}
          variant={activeTab === 'O' ? 'contained' : 'outlined'}
          color={activeTab === 'O' ? 'primary' : 'inherit'}
          onClick={() => setActiveTab('O')}
        >
          Abertura
        </Button>
        <Button
          sx={{ borderRadius: '5rem', fontSize: '0.752rem' }}
          variant={activeTab === 'A' ? 'contained' : 'outlined'}
          color={activeTab === 'A' ? 'primary' : 'inherit'}
          onClick={() => setActiveTab('A')}
        >
          Afabilidade
        </Button>
        <Button
          sx={{ borderRadius: '5rem', fontSize: '0.752rem' }}
          variant={activeTab === 'E' ? 'contained' : 'outlined'}
          color={activeTab === 'E' ? 'primary' : 'inherit'}
          onClick={() => setActiveTab('E')}
        >
          Extroversão
        </Button>
        <Button
          sx={{ borderRadius: '5rem', fontSize: '0.752rem' }}
          variant={activeTab === 'C' ? 'contained' : 'outlined'}
          color={activeTab === 'C' ? 'primary' : 'inherit'}
          onClick={() => setActiveTab('C')}
        >
          Consciência
        </Button>
      </Box>
      <Scrollbar sx={{}}>{renderTabContent()}</Scrollbar>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '0.550rem' }}>
        <CustomButton variant="primary" sx={{ fontSize: '0.752rem', marginRight: 1 }} onClick={onRedoProfile}>
          Refazer Perfil
        </CustomButton>
        {!isDependent && (
          <>
            <CustomButton variant="primary" sx={{ fontSize: '0.752rem' }} onClick={handleStepFinancial}>
              Continuar para Dados Financeiros
            </CustomButton>
          </>
        )}
        {isDependent && (
          <Button variant="contained" color="secondary" onClick={handleDisplayProfile}>
            Ver todos
          </Button>
        )}
      </Box>
    </div>
  );
  return <StandardLayout leftComponent={leftComponent} rightComponent={rightComponent} />;
}

export default BigFiveResults;

BigFiveResults.propTypes = {
  scores: PropTypes.array,
  isResult: PropTypes.bool.isRequired,
  result: PropTypes.array.isRequired,
  startAddingDependent: PropTypes.func,
  isDependent: PropTypes.bool.isRequired,
  onDisplayProfile: PropTypes.func,
  onRedoProfile: PropTypes.func.isRequired, // Adiciona a propType para onRedoProfile
};
