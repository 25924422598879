import React, { useEffect, useState, useCallback } from 'react';
import { Container, TextField, Typography, Button } from '@mui/material';
import { set, ref, get } from 'firebase/database';
import { fCurrency, unformatedValue } from '../../utils/formatNumber';
import { auth, database } from '../../services/firebase';
import toast from '../../utils/toast';
import { recalculateGoalsBasedofName } from '../../utils/resolvers';
import CustomButton from '../button/CustomButton';

export default function TravelLeisureSection({ userIdParams, onDataSaved }) {
  const [userId, setUserId] = useState(null);
  const [travelCost, setTravelCost] = useState('');
  const [travelTimeframe, setTravelTimeframe] = useState('');
  const [targetDate, setTargetDate] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const userIdToUse = userIdParams || (user && user.uid);
      if (userIdToUse) {
        setUserId(userIdToUse);
        const userGoalsRef = ref(database, `users/${userIdToUse}/goals/viagensLazer`);
        const goalsSnapshot = await get(userGoalsRef);
        if (goalsSnapshot.exists()) {
          const goalsData = goalsSnapshot.val();
          setTravelCost(goalsData.cost || '');
          setTravelTimeframe(goalsData.timeframe || '');
          setTargetDate(goalsData.targetDate || '');
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const handleTravelCostChange = (e) => {
    const { value } = e.target;
    setTravelCost(unformatedValue(value));
    updateIsEmpty();
  };

  const handletargetDateChange = (e) => {
    setTargetDate(e.target.value);
  };

  const handleTravelTimeframeChange = (e) => {
    setTravelTimeframe(e.target.value);
    updateIsEmpty();
  };

  const updateIsEmpty = useCallback(() => {
    setIsEmpty(!travelCost || !travelTimeframe);
  }, [travelCost, travelTimeframe]);

  const handleSave = async () => {
    if (!userId) return;
    try {
      const userGoalsRef = ref(database, `users/${userId}/goals/viagensLazer`);
      await set(userGoalsRef, {
        travelCost,
        travelTimeframe,
        targetDate,
      });
      await recalculateGoalsBasedofName('viagensLazer', userId);
      toast({
        type: 'success',
        text: 'Dados de viagens e lazer salvos com sucesso!',
      });
      onDataSaved();
    } catch (error) {
      toast({
        type: 'error',
        text: 'Erro ao salvar dados de viagens e lazer',
      });
    }
  };

  return (
    <Container
      sx={{
        marginBottom: '2rem',
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <Typography
        sx={{
          fontSize: '1.5625rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 1.7,
        }}
      >
        Viagens e Lazer
      </Typography>
      <Typography variant="body1">
        Incluir viagens e momentos de lazer no planejamento financeiro é essencial para garantir que essas experiências
        sejam possíveis e sustentáveis ao longo do tempo. Um planejamento adequado pode ajudar a alcançar esses
        objetivos sem comprometer outras metas financeiras. Considerando esses fatores sob uma perspectiva mais
        abrangente: Que valor você teria em mente para incluir como um gasto anual com viagens e/ou momentos de lazer?
      </Typography>
      <TextField
        style={{ marginTop: '-8px' }}
        type="text"
        value={fCurrency(travelCost)}
        onChange={handleTravelCostChange}
        variant="outlined"
        margin="normal"
        fullWidth
        InputLabelProps={{
          sx: {
            fontSize: '1rem',
            color: '#E8E8E8',
          },
        }}
        required
      />

      <Typography variant="body1">Estes gastos começariam a acontecer daqui a quantos anos aproximadamente?</Typography>
      <TextField
        style={{ marginTop: '-8px' }}
        type="number"
        value={travelTimeframe || ''}
        onChange={handleTravelTimeframeChange}
        variant="outlined"
        margin="normal"
        fullWidth
        InputLabelProps={{
          sx: {
            fontSize: '1rem',
            color: '#E8E8E8',
          },
        }}
        required
      />

      <Typography variant="body1">Data para atingir o objetivo</Typography>
      <TextField
        style={{ marginTop: '-8px' }}
        type="date"
        value={targetDate || ''}
        onChange={handletargetDateChange}
        variant="outlined"
        margin="normal"
        InputLabelProps={{
          shrink: true,
          sx: {
            fontSize: '1rem',
            color: '#E8E8E8',
          },
        }}
        fullWidth
      />
      <CustomButton variant="primary" onClick={handleSave}>
        Salvar
      </CustomButton>
    </Container>
  );
}
