import './index.css'
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { LoaderProvider } from './context/LoaderContext'
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ToastManager from './components/ToastManager';
import { UserProvider } from './context/UserContext';
// ----------------------------------------------------------------------
// comment
export default function App() {
  return (
    <HelmetProvider>
      <ToastManager/>
      <BrowserRouter>
        <ThemeProvider>
          <LoaderProvider>
            <UserProvider> 
              <StyledChart />
              <Router />
            </UserProvider>
          </LoaderProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
