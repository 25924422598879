// src/components/CustomTextField.js
import React from 'react';
import { TextField } from '@mui/material';

const MyTextField = (props) => {
  return (
    <TextField
      {...props}
      variant="outlined"
      fullWidth
      margin="normal"
      InputLabelProps={{
        sx: {
          fontSize: '1.3rem',
          color: '#E8E8E8',
        },
      }}
      InputProps={{
        sx: {
          borderRadius: '3rem',
          backgroundColor: '#fff',
          color: '#000',
        },
      }}
    />
  );
};

export default MyTextField;
