import React, { useEffect, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import { ref, get } from 'firebase/database';
import { auth, database } from '../../services/firebase';
import { fCurrency, fCurrencyNumber } from '../../utils/formatNumber';
import CustomButton from '../button/CustomButton';

export default function EmergencyFundSection({ userIdParams, onDataSaved }) {
  const [monthlyIncome, setMonthlyIncome] = useState(0);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const userIdToUse = userIdParams || (user && user.uid);
      if (userIdToUse) {
        const financialDataRef = ref(database, `users/${userIdToUse}/financial`);
        const financialSnapshot = await get(financialDataRef);
        if (financialSnapshot.exists()) {
          const financialData = financialSnapshot.val();
          setMonthlyIncome(financialData.monthlyIncome || 0);
        }
      }
    });

    return () => unsubscribe();
  }, [userIdParams]);

  const recommendedEmergencyFund = monthlyIncome * 6; // Calcula a reserva de emergência recomendada como 6 vezes a renda média mensal
  const formattedIncome = fCurrencyNumber(monthlyIncome);
  const formattedEmergencyFund = fCurrencyNumber(recommendedEmergencyFund);

  return (
    <Container
      sx={{
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <Typography variant="body1" sx={{ textAlign: 'start', fontSize: '1.2rem' }}>
        O seu gasto médio mensal é de {formattedIncome}. Recomendamos que tenha 6 meses de gastos médios mensais como
        reserva de emergência padrão.
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'start', fontWeight: 'bold', fontSize: '1.2rem' }}>
        Portanto, a recomendação de reserva de emergência é de {formattedEmergencyFund}.
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '70%' }}>
        <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '2rem' }}>
          {formattedEmergencyFund}
        </Typography>
      </div>

      <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '1rem' }}>
        <CustomButton variant="custom" sx={{ width: '12rem', marginRight: '1rem' }} onClick={() => onDataSaved()}>
          Voltar
        </CustomButton>
      </Box>
    </Container>
  );
}
