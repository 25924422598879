import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, TextField, Typography, Radio, Box, RadioGroup, FormControlLabel, Button } from '@mui/material';
import { set, ref, get } from 'firebase/database';
import { fCurrency, unformatedValue } from '../../utils/formatNumber';
import { auth, database } from '../../services/firebase';
import toast from '../../utils/toast';
import { recalculateGoalsBasedofName } from '../../utils/resolvers';
import CustomButton from '../button/CustomButton';
import MyTextField from '../text-field/MyTextField';

export default function HomePurchaseSection({ userIdParams, onDataSaved }) {
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    buyProperty: '',
    propertyValue: '',
    yearsToBuyProperty: '',
    isEmpty: true,
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const userIdToUse = userIdParams || (user && user.uid);
      if (userIdToUse) {
        setUserId(userIdToUse);
        const userGoalsRef = ref(database, `users/${userIdToUse}/goals`);
        const goalsSnapshot = await get(userGoalsRef);
        if (goalsSnapshot.exists()) {
          const goalsData = goalsSnapshot.val();
          const propertyData = goalsData.compraImovel || {};
          setFormData({
            ...formData,
            buyProperty: propertyData.buyProperty || '',
            propertyValue: propertyData.propertyValue || '',
            yearsToBuyProperty: propertyData.yearsToBuyProperty || '',
          });
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const handlePropertyValueChange = (e) => {
    const { value } = e.target;
    const numericValue = Number(value.replace(/[^0-9.-]+/g, ''));

    setFormData((prevData) => ({
      ...prevData,
      propertyValue: unformatedValue(value),
      isEmpty:
        prevData.buyProperty === 'yes' &&
        (numericValue === 0 || prevData.yearsToBuyProperty === '' || prevData.yearsToBuyProperty === undefined), // Update isEmpty value
    }));
  };
  const formattedPropertyValue = fCurrency(formData.propertyValue);

  const handleYearsToBuyPropertyChange = (e) => {
    const numericValue = formData.propertyValue ? Number(formData.propertyValue.replace(/[^0-9.-]+/g, '')) : 0;
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      yearsToBuyProperty: value,
      isEmpty: prevData.buyProperty === 'yes' && (numericValue === 0 || value === '' || value === undefined), // Update isEmpty value
    }));
  };
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      isEmpty:
        prevData.buyProperty === undefined ||
        (prevData.buyProperty === 'yes' && (prevData.propertyValue === '' || prevData.yearsToBuyProperty === '')), // Update isEmpty value
    }));
  }, []);

  const handleSave = async () => {
    if (!userId) return;
    try {
      const userGoalsRef = ref(database, `users/${userId}/goals/compraImovel`);
      await set(userGoalsRef, {
        buyProperty: formData.buyProperty,
        propertyValue: formData.propertyValue,
        yearsToBuyProperty: formData.yearsToBuyProperty,
      });
      await recalculateGoalsBasedofName('compraImovel', userId);
      toast({
        type: 'success',
        text: 'Dados de compra de imóvel salvos com sucesso!',
      });
      onDataSaved();
    } catch (error) {
      toast({
        type: 'error',
        text: 'Erro ao salvar dados de compra de imóvel',
      });
    }
  };

  return (
    <Container
      sx={{
        marginBottom: '1rem',
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <Typography
        sx={{
          fontSize: '1.5625rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 1.7,
        }}
      >
        Compra de um imóvel
      </Typography>
      {
        <>
          <Typography variant="body1">
            Compartilhe um pouco mais desse objetivo para que possamos ajudá-lo a conquistá-lo. Qual valor você deseja
            ou tem em mente que este imóvel custa?
          </Typography>
          <MyTextField
            type="text"
            value={formattedPropertyValue}
            onChange={handlePropertyValueChange}
            variant="outlined"
            margin="normal"
            fullWidth
            InputLabelProps={{
              sx: {
                fontSize: '1.3rem',
                color: '#E8E8E8',
              },
            }}
            sx={{ marginBottom: '1rem' }}
          />

          <Typography variant="body1">Com quantos anos você deseja atingir esse objetivo de vida?</Typography>
          <MyTextField
            type="number"
            label="Anos até a compra do imóvel"
            value={formData.yearsToBuyProperty || ''}
            onChange={handleYearsToBuyPropertyChange}
            variant="outlined"
            margin="normal"
            fullWidth
            InputLabelProps={{
              sx: {
                fontSize: '0.9rem',
                color: '#E8E8E8',
              },
            }}
          />

          <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '1rem' }}>
            <CustomButton variant="custom" sx={{ width: '12rem', marginRight: '1rem' }} onClick={() => onDataSaved()}>
              Voltar
            </CustomButton>
            <CustomButton variant="primary" sx={{ width: '12rem' }} onClick={handleSave}>
              Salvar
            </CustomButton>
          </Box>
        </>
      }
    </Container>
  );
}
