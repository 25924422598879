import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, Container, Typography, Grid } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { RegisterForm } from '../sections/auth/register';
import Logo from '../components/logo/Logo';

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: 0,
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  height: '100vh',
  overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  maxWidth: 'none',
  margin: 0,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 450,
  marginLeft: '7rem',
  marginTop: '-3rem',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),

  [theme.breakpoints.down('sm')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 0,
    padding: theme.spacing(3, 2),
  },
}));

const StyledLeftContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  background: 'url(assets/illustrations/back.png) no-repeat center center',
  backgroundSize: 'cover',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const StyledRightContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(10),
  height: '85vh',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    height: '100vh', // Make sure the form takes the full height on mobile
  },
}));

const LoginContainer = styled('div')({
  maxWidth: '700px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

export default function RegisterPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div style={{ height: '100vh' }}>
      <Helmet>
        <title>Crie uma Nova Conta</title>
      </Helmet>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12} md={6}>
          <StyledLeftContent />
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              startIcon={<ArrowBack />}
              onClick={goBack}
              sx={{ display: 'flex', maxWidth: '800px', justifyContent: 'flex-end' }}
            >
              Voltar
            </Button>
          </div>
          <StyledRightContent>
            <LoginContainer>
              <RegisterForm />
            </LoginContainer>
          </StyledRightContent>
          <div style={{ display: 'flex', maxWidth: '800px', justifyContent: 'flex-end' }}>
            <Logo />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
