import * as React from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

export default function CircularProgressWithLabel(props) {
  const color = props.color ? props.color : '#FFF';
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={300} variant="determinate" {...props} />
      <Box
        sx={{
          top: 20,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.profile ? (
          <Typography sx={{ fontSize: '1.6rem', fontFamily: 'DM Sans' }} component="div">
            {`${Math.round(props.value)}%`}
            <br />
            {props.profile}
          </Typography>
        ) : (
          <Typography sx={{ fontSize: '4rem', fontFamily: 'DM Sans' }} component="div">
            {`${Math.round(props.value)}%`}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  color: PropTypes.string,
  profile: PropTypes.string,
  value: PropTypes.number.isRequired,
};
