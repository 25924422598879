import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Delete, Save } from '@mui/icons-material';
import {
  Checkbox,
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useUser } from '../context/UserContext';
import CustomButton from '../components/button/CustomButton';
import SvgColor from '../components/svg-color/SvgColor';
import { useUserData } from '../hooks/userUserData';
import sendMessage from '../services/sendMenssage';
import { fDate } from '../utils/formatTime';

export default function UserPage({ onUserUpdate }) {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [stageFilter, setStageFilter] = useState('');

  const navigate = useNavigate();
  const { clientUsers, updateUser, deleteUser } = useUserData();
  const { setCurrentUser } = useUser();

  const filteredUsers = clientUsers.filter((user) => {
    const nameOrEmailMatch =
      !filterValue ||
      (user.name && user.name.toLowerCase().includes(filterValue.toLowerCase())) ||
      (user.email && user.email.toLowerCase().includes(filterValue.toLowerCase()));
    const dateMatch = !selectedDate || (user.date && user.date.startsWith(selectedDate));
    const stageMatch = !stageFilter || (user.faseApp && user.faseApp === stageFilter);
    return nameOrEmailMatch && dateMatch && stageMatch;
  });

  const handleCheckboxChange = async (userId, field, value) => {
    if (field === 'premium' && value === 'yes') {
      const userWhatsAppNumber = '+553197508819';
      const message =
        'Olá, você acabou de ser incluído no grupo exclusivo de clientes Premium com acesso aos investimentos de cada objetivo financeiro seu.';
      await sendMessage(userWhatsAppNumber, message);
    }
    updateUser(userId, { [field]: value });
  };

  const handleOpenDeleteDialog = (userId) => {
    setSelectedUserId(userId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedUserId(null);
    setOpenDeleteDialog(false);
  };

  const handleDeleteUser = async () => {
    const result = await deleteUser(selectedUserId);
    if (result.success) {
      handleCloseDeleteDialog();
      onUserUpdate();
    }
  };

  const handleSaveUserChanges = (userId) => {
    const user = clientUsers.find((u) => u.id === userId);
    if (!user) return;
    const { id, name, email, role, premium, category } = user;
    const updatedUser = { id, name, email, role, premium, category };
    updateUser(userId, updatedUser);
  };

  const handleNavigateClient = (userId) => {
    setCurrentUser(userId);
    navigate(`/dashboard/${userId}`);
  };

  const columns = [
    {
      field: 'date',
      headerName: 'Data',
      width: 150,
      valueFormatter: ({ value }) => (value ? fDate(value, 'dd/MM/yy') : 'N/A'),
    },
    { field: 'name', headerName: 'Nome', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'premium',
      headerName: 'Premium',
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={params.value === 'yes'}
          onChange={(e) => handleCheckboxChange(params.row.id, 'premium', e.target.checked ? 'yes' : 'not')}
        />
      ),
    },
    {
      field: 'role',
      headerName: 'Admin',
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={params.value === 'admin'}
          onChange={(e) => handleCheckboxChange(params.row.id, 'role', e.target.checked ? 'admin' : 'cliente')}
        />
      ),
    },
    {
      field: 'categoryPlanning',
      headerName: 'Planning',
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.category === 'Planning'}
          onChange={(e) => handleCheckboxChange(params.row.id, 'category', e.target.checked ? 'Planning' : '')}
        />
      ),
    },
    {
      field: 'categoryAdvisory',
      headerName: 'Advisory',
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.category === 'Advisory'}
          onChange={(e) => handleCheckboxChange(params.row.id, 'category', e.target.checked ? 'Advisory' : '')}
        />
      ),
    },
    {
      field: 'category360',
      headerName: '360',
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.category === 'cliente360'}
          onChange={(e) => handleCheckboxChange(params.row.id, 'category', e.target.checked ? 'cliente360' : '')}
        />
      ),
    },
    { field: 'faseApp', headerName: 'Fase APP', width: 200 },
    {
      field: 'map',
      headerName: 'Mapa',
      width: 100,
      renderCell: (params) => (
        <SvgColor
          src="/assets/icons/Mapa.svg"
          onClick={() => handleNavigateClient(params.row.id)}
          sx={{
            color: params.row.isGoal ? '#AF93FF' : 'grey',
            cursor: params.row.isGoal ? 'pointer' : 'default',
            pointerEvents: params.row.isGoal ? 'auto' : 'none',
          }}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton onClick={() => handleSaveUserChanges(params.row.id)}>
            <Save />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteDialog(params.row.id)}>
            <Delete />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={filteredUsers}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 50]}
          checkboxSelection
          disableSelectionOnClick
        />
      </Box>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        }}
        sx={{
          '& .MuiBackdrop-root': {
            boxShadow: 'none !important',
          },
          '& .MuiPaper-root': {
            boxShadow: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
          },
        }}
      >
        <DialogTitle sx={{ color: '#E8E8E8' }} id="alert-dialog-title">
          {'Confirmar Exclusão'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: '#E8E8E8' }}>
            Você tem certeza que deseja excluir o usuário?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseDeleteDialog} variant="secondary">
            Não
          </CustomButton>
          <CustomButton onClick={handleDeleteUser} variant="primary">
            Sim
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

UserPage.propTypes = {
  onUserUpdate: PropTypes.func.isRequired,
};
