import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Grid, Stack, Typography, LinearProgress, IconButton } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { fCurrency } from '../../../utils/formatNumber';
import { useUser } from '../../../context/UserContext';
import SvgColor from '../../svg-color/SvgColor';
import { mapGoalsName, translateGoalsName } from '../../../utils/constants';
import CustomContainer from '../../CustomContainer';

const InvestmentChart = ({ clientId, goals }) => {
  const { role, isPremium } = useUser();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate back in browser history
  };

  const handleButtonClick = () => {
    navigate('/home?tab=Objetivos');
  };

  const objectives = ['aposentadoria', 'compraImovel', 'educacaoFilhos'];
  const customGoalsArray = Object.keys(goals.customGoals || {}).map((key) => ({
    id: key,
    name: goals.customGoals[key].name,
    ...goals.customGoals[key],
  }));

  const allObjectives = [...objectives, ...customGoalsArray.map((goal) => goal.name)];

  const mapYears = {
    compraImovel: 'yearsToBuyProperty',
    aposentadoria: 'retirementYears',
    educacaoFilhos: 'childTimeframe',
  };

  const calculateGoalProgress = (goalData, goalName) => {
    const investments = goalData?.investments?.registeredContributions || [];
    const totalContributions = investments.reduce((acc, investment) => acc + Number(investment.ValorLiquido), 0);
    const targetValue = goalName === 'reservaEmergência' ? goalData?.value : goalData?.investments?.targetValue || 0;

    return (totalContributions / targetValue) * 100;
  };

  return (
    <CustomContainer sx={{ minHeight: '100%', paddingBottom: '1rem', marginTop: '1.5rem' }}>
      <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
        <IconButton onClick={handleGoBack} color="primary" aria-label="go back">
          <KeyboardBackspaceIcon />
        </IconButton>
      </Box>
      <Grid container spacing={8}>
        {allObjectives.map((objective) => {
          const isSpecialObjective = objective === 'aposentadoria' || objective === 'reservaEmergência';
          const isCustomGoal = objectives.indexOf(objective) === -1;

          const svgSrc = isCustomGoal
            ? `/assets/images/goals/addCustomGoal.svg`
            : `/assets/images/goals/${translateGoalsName[objective]}.svg`;
          const divStyle = {
            background: isSpecialObjective ? '#FFF' : '#000',
            width: '20rem',
            height: '14rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '1rem',
            border: '1px solid #E5E5E5',
          };
          const svgStyle = {
            color: isSpecialObjective ? '#000' : '#FFF',
            width: '7rem',
            height: '7rem',
          };

          let investments;
          let requiredContributions;
          let yearsUntilTarget;
          let targetValue;

          if (isCustomGoal) {
            const customGoal = customGoalsArray.find((goal) => goal.name === objective);
            investments = customGoal?.investments?.registeredContributions || [];
            requiredContributions = customGoal?.investments?.requiredContributions || '0';
            yearsUntilTarget = customGoal?.timeFrame || 0;
            targetValue = customGoal?.investments?.targetValue || 0;
          } else {
            investments = goals[objective]?.investments?.registeredContributions || [];
            requiredContributions = goals[objective]?.investments?.requiredContributions || '0';
            const yearsField = mapYears[objective];
            yearsUntilTarget = goals[objective]?.[yearsField] || 0;
            targetValue = goals[objective]?.investments?.targetValue || 0;
          }

          const chartDataObj = investments.reduce((data, investment) => {
            const { investmentType, ValorLiquido } = investment;

            const existingValue = data[investmentType] || 0;
            return {
              ...data,
              [investmentType]: existingValue + Number(ValorLiquido),
            };
          }, {});

          const chartData = Object.entries(chartDataObj).map(([investmentType, ValorLiquido]) => ({
            investmentType,
            ValorLiquido,
          }));

          const chartSeries = chartData.map((data) => data.ValorLiquido);
          const arraySum = chartSeries.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
          const meta = objective !== 'reservaEmergência' ? targetValue || 0 : goals[objective]?.value || 0;
          const percentageGoal = meta ? ((arraySum / meta) * 100).toFixed(2) : 0;

          return (
            <Grid key={objective} item xs={12} sm={6} md={3}>
              <Link
                to={isPremium || role === 'admin' ? `/details/${objective}/${clientId}` : undefined}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {goals[objective] || isCustomGoal ? (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.475rem' }}>
                      <Typography sx={{ fontFamily: 'DM Sans' }}>{mapGoalsName[objective] || objective}</Typography>
                      <div
                        style={{
                          width: '20rem',
                          height: '14rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '1rem',
                          border: `1rem solid ${percentageGoal >= 100 ? '#82CF7A' : '#FFF'}`,
                        }}
                      >
                        <Stack direction="column" justifyContent="center" alignItems="center">
                          <Stack direction="column" justifyContent="center" alignItems="center">
                            <Typography sx={{ fontFamily: 'DM Sans' }} color="#FFF">
                              {`Aporte Mensal`}
                            </Typography>
                            <Typography sx={{ fontFamily: 'DM Sans' }} color="#FFF">
                              {fCurrency(requiredContributions)}
                            </Typography>
                          </Stack>

                          <Stack direction="column" justifyContent="center" alignItems="center" mt={1}>
                            <Typography sx={{ fontFamily: 'DM Sans' }} color="#FFF">
                              Tempo para o objetivo
                            </Typography>
                            <Typography sx={{ fontFamily: 'DM Sans' }} color="#FFF">
                              {yearsUntilTarget} anos
                            </Typography>
                          </Stack>
                        </Stack>
                      </div>

                      <div
                        style={{
                          height: '0.7rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'row',
                          gap: '5px',
                          borderRadius: '1.0625rem',
                          marginTop: '0.375rem',
                        }}
                      >
                        {' '}
                        {percentageGoal > 100 ? (
                          <Typography>Objetivo Concluído</Typography>
                        ) : (
                          <>
                            <LinearProgress
                              variant="determinate"
                              value={Number(percentageGoal)}
                              sx={{
                                height: '0.7rem',
                                width: '14rem',
                                borderRadius: '1.0625rem',
                                background: '#FFF',
                                color: '#8CE446',
                              }}
                            />
                            <Typography sx={{ flex: 0.2 }}>{percentageGoal}%</Typography>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.475rem' }}>
                    <Typography>{mapGoalsName[objective] || objective}</Typography>
                    <div style={divStyle}>
                      <SvgColor sx={svgStyle} src={svgSrc} />
                    </div>
                    <div
                      style={{
                        height: '0.7rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        gap: '5px',
                        borderRadius: '1.0625rem',
                        marginTop: '0.375rem',
                      }}
                    >
                      {' '}
                      {percentageGoal > 100 ? (
                        <Typography>Concluído</Typography>
                      ) : (
                        <>
                          <LinearProgress
                            variant="determinate"
                            value={Number(percentageGoal)}
                            sx={{
                              height: '0.7rem',
                              width: '14rem',
                              borderRadius: '1.0625rem',
                              background: '#FFF',
                              color: '#8CE446',
                            }}
                          />
                          <Typography sx={{ flex: 0.2 }}>{percentageGoal}%</Typography>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </CustomContainer>
  );
};

export default InvestmentChart;
