import { Box, Typography } from "@mui/material";

export default function Extroversao({ data }) {
  if (!data) {
    return null;
  }

  const { facets} = data;

  return (
    <div>
      {/* Mapeando o array facets para renderizar cada elemento */}
      {facets.map((facet, index) => (
        <Box key={index} sx={{ marginBottom: 0.5, padding: 1,}}>
          <Box sx={{display: 'flex', flexDirection: 'row', gap: '0.5rem'}}>
            <Typography variant="subtitle1">{facet.title} - </Typography>
            <Typography variant="body2">Score: {facet.score} - {facet.scoreText}</Typography>
          </Box>
          <Typography variant="body2">{facet.text}</Typography>
        </Box>
      ))}
    </div>
  );
}