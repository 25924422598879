import React, { useEffect, useState } from "react";
import { ref, get } from 'firebase/database';
import { useParams } from "react-router-dom";
import { auth, database } from '../../../services/firebase';


const { default: InvestmentChart } = require("./InvestmentChart");

const ClientDashboard = () => {
  const [clientId, setClientId] = useState(null);
  const [client, setClient] = useState(null);
  const params = useParams()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        if (params && params.clientId) {
          setClientId(params.clientId);
        } else {
          setClientId(user.uid);
        }
      } else {
        setClientId(null);
      }
    });
  
    return () => unsubscribe();
  }, [params]);

  useEffect(() => {
    const fetchClient = async () => {
      const clientRef = ref(database, `users/${clientId}`);
      const clientSnapshot = await get(clientRef);
      if (clientSnapshot.exists()) {
        const clientData = clientSnapshot.val();
        setClient(clientData);
      }
    }
    if (clientId) {
      fetchClient();
    }
  }, [clientId]);
  if (!client) {
    return <p>Carregando...</p>;
  }
  return (
    <>
      <InvestmentChart clientId={clientId} goals={client.goals} />
    </>
  );
};

export default ClientDashboard;
