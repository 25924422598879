import PropTypes from 'prop-types';
import { useState, useMemo, createContext } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { getDesignTokens } from './palette';
import shadows from './shadows';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';
// Crie um contexto para a função de alternância de tema
export const ThemeToggleContext = createContext();

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const [mode, setMode] = useState('dark');

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'dark' ? 'light' : 'dark'));
  };

  const themeOptions = useMemo(
    () => ({
      ...getDesignTokens(mode),
      shape: { borderRadius: 6 },
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [mode]
  );

  const theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeToggleContext.Provider value={{ toggleTheme, setMode, mode }}>
        <MUIThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          {children}
        </MUIThemeProvider>
      </ThemeToggleContext.Provider>
    </StyledEngineProvider>
  );
}
