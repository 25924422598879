import React from 'react';
import Dashboard from './Dashboard';
import DashboardMobile from './DashboardMobile';
import { useWidth } from '../hooks/useResponsive';

function ResponsiveDashboard(props) {
  const screenWidth = useWidth();

  if (screenWidth === 'xs') {
    return <DashboardMobile {...props} />;
  }

  return <Dashboard {...props} />;
}

export default ResponsiveDashboard;
