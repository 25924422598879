import PropTypes from 'prop-types';
import { forwardRef, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import { ThemeToggleContext } from '../../theme';

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const themeContext = useContext(ThemeToggleContext);
  const themeMode = themeContext?.mode;
  const logoSrc =
    theme.palette.mode === 'light'
      ? '/assets/illustrations/ascenda-logo.png'
      : '/assets/illustrations/ASCENDA-BRANCO.png';

  const LogoStyled = styled('img')(({ theme }) => ({
    width: 220,
    height: 65,
    margin: '0',
    display: 'inline-flex',
    [theme.breakpoints.down('sm')]: {
      width: 150,
      height: 45,
    },
    [theme.breakpoints.down('xs')]: {
      width: 100,
      height: 30,
    },
  }));

  const logo = (
    <Box ref={ref} component="div" sx={{ ...sx }} {...other}>
      <LogoStyled src={logoSrc} alt="logo" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/inicio" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
