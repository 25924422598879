import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, set, onValue } from 'firebase/database';

import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { fCurrency } from '../../utils/formatNumber';

import CustomProgressBar from '../CustomProgressBar';
import Scrollbar from '../scrollbar/Scrollbar';

function CategorySummary({ transactions, estimatedValues, userIdParam }) {
  const categories = [...new Set(transactions.map(transaction => transaction.category))];

  const [financeData, setFinanceData] = useState({});
  const [userId, setUserId] = useState(null);


  React.useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        if (userIdParam) {
        setUserId(userIdParam);
        } else {
          setUserId(user.uid);
        }
      } else {
        setUserId(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    const db = getDatabase();
    const financeRef = ref(db, `users/${userId}/finance`);
    const unsubscribe = onValue(financeRef, (snapshot) => {
      setFinanceData(snapshot.val() || {});
    });

    return () => {
      unsubscribe();
    };
  }, [userId]);

  const calculateAchievementPercentage = (category) => {
    const totalEstimated = Object.keys(estimatedValues)
      .filter(key => key.startsWith(category))
      .reduce((sum, key) => sum + parseFloat(estimatedValues[key]), 0);

    const totalRealized = transactions
      .filter(transaction => transaction.category === category)
      .reduce((sum, transaction) => sum + parseFloat(transaction.price), 0);

    // Tratando divisões por zero
    if (totalEstimated === 0) {
      return 0;
    }

    return (totalRealized / totalEstimated) * 100;
  };

  const getGroupNameById = (groupId) => {
    const foundGroup = Object.entries(financeData)
      .map(([key, value]) => value.groups && value.groups[groupId] && value.groups[groupId].name)
      .find(name => name !== undefined);
    return foundGroup || groupId;
  }


  const getCategoryNameById = (groupId, categoryId) => {
    const foundCategory = Object.entries(financeData)
      .map(([key, value]) => value.groups && value.groups[groupId] && value.groups[groupId].categories && value.groups[groupId].categories[categoryId] && value.groups[groupId].categories[categoryId].name)
      .find(name => name !== undefined);

      console.log(foundCategory, groupId, categoryId, financeData);
    return foundCategory || categoryId;
  }

  const getTypeByCategory = (category) => {
    const typeEntry = Object.entries(financeData).find(([type, typeData]) =>
      Object.values(typeData.groups || {}).some(group =>
        group.categories && group.categories[category]
      )
    );
    return typeEntry ? typeEntry[0] : null;
  };

  const calculateTotalEstimatedByType = (type) => categories
    .filter(category => getTypeByCategory(category) === type)
    .reduce((sum, category) => {
      const totalEstimatedForCategory = Object.keys(estimatedValues)
        .filter(key => key.startsWith(category))
        .reduce((innerSum, key) => innerSum + parseFloat(estimatedValues[key]), 0);
      return sum + totalEstimatedForCategory;
    }, 0);

  const calculateTotalRealizedByType = (type) => transactions
    .filter(transaction => getTypeByCategory(transaction.category) === type)
    .reduce((sum, transaction) => sum + parseFloat(transaction.price), 0);

  const totalEstimatedEntrada = calculateTotalEstimatedByType("Receitas");
  const totalRealizedEntrada = calculateTotalRealizedByType("Receitas");
  const percentageEntrada = (totalRealizedEntrada / totalEstimatedEntrada) * 100;

  const totalEstimatedSaida = calculateTotalEstimatedByType("Despesas");
  const totalRealizedSaida = calculateTotalRealizedByType("Despesas");
  const percentageSaida = (totalRealizedSaida / totalEstimatedSaida) * 100;

  const totalEstimatedInvestimento = calculateTotalEstimatedByType("Investimentos");
  const totalRealizedInvestimento = calculateTotalRealizedByType("Investimentos");
  const percentageInvestimento = (totalRealizedInvestimento / totalEstimatedInvestimento) * 100;


  if (transactions.length === 0) {
    return (
      <div style={{ borderRadius: '0.625rem', background: '#000', flex: 1, padding: '1rem' }}>
        <Typography variant="body1" color="textSecondary" align="center">
          Nenhuma transação disponível.
        </Typography>
      </div>
    );
  }
  const typeColors = {
    "Receitas": "#8CE446",
    "Despesas": "#FF0062",
    "Investimentos": "#E697FF"
  };

  const toggleFavorite = (groupId, categoryId) => {
    const updatedFinanceData = { ...financeData };

    // Acessando a categoria corretamente
    const categoryType = Object.keys(updatedFinanceData).find(type => updatedFinanceData[type].groups[groupId]);
    const category = updatedFinanceData[categoryType]?.groups[groupId]?.categories[categoryId];
    if (category) {
      category.isFavorite = !category.isFavorite; // Toggle the favorite status
      setFinanceData(updatedFinanceData);

      const db = getDatabase();
      const financeRef = ref(db, `users/${userId}/finance`);
      set(financeRef, updatedFinanceData);
    }
  };

  const isCategoryFavorite = (groupId, categoryId) => {
    const categoryType = Object.keys(financeData).find(type => financeData[type].groups && financeData[type].groups[groupId]);

    if (!categoryType) {
      console.error("Tipo não encontrado para o groupId:", groupId);
      return false;
    }

    const category = financeData[categoryType].groups[groupId].categories[categoryId];

    if (!category) {
      console.error("Categoria não encontrada para o categoryId:", categoryId);
      return false;
    }

    return category.isFavorite;
  };

  const sortedCategories = categories.sort((a, b) => {
    const groupA = transactions.find(t => t.category === a)?.group || 'N/A';
    const groupB = transactions.find(t => t.category === b)?.group || 'N/A';

    const isFavoriteA = isCategoryFavorite(groupA, a) ? 1 : 0;
    const isFavoriteB = isCategoryFavorite(groupB, b) ? 1 : 0;

    return isFavoriteB - isFavoriteA;
  });


  return (
    <Scrollbar style={{ borderRadius: '0.625rem', background: '#000', flex: 1, maxHeight: '700px' }}>
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>

          <Typography sx={{ color: '#FFF', fontSize: '0.95rem', padding: '0.5rem', fontFamily: 'DM Sans' }}>
            Resumo
          </Typography>
          {["Receitas", "Despesas", "Investimentos"].map(type => {
            const totalEstimatedForType = calculateTotalEstimatedByType(type);
            const totalRealizedForType = calculateTotalRealizedByType(type);
            const percentageForType = totalEstimatedForType === 0 ? 0 : (totalRealizedForType / totalEstimatedForType) * 100;

            return (
              <div key={type} style={{ marginBottom: '0.5rem', padding: '0.345rem' }}>
                <Typography variant="body2" sx={{ color: typeColors[type], fontFamily: 'DM Sans', fontSize: '1rem' }}>
                  {type}
                </Typography>
                <CustomProgressBar value={percentageForType} color={typeColors[type]} />
                <Typography  style={{ fontFamily: 'DM Sans', fontSize: '1rem',color: typeColors[type] }}>
                  {fCurrency(totalRealizedForType.toFixed(2))} / {fCurrency(totalEstimatedForType.toFixed(2))} ({percentageForType.toFixed(2)}%)
                </Typography>
              </div>
            );
          })}
        </Grid>
        <Grid item xs={12} sx={{ padding: '0.7rem' }}>
          <Typography sx={{ color: '#E8E8E8',  padding: '0.6rem', fontWeight: 500, fontFamily: 'DM Sans', fontSize: '1rem' }}>Metas</Typography>
          <ul>
            {sortedCategories.map(category => {
              const group = transactions.find(t => t.category === category)?.group || 'N/A';
              const isFavorite = isCategoryFavorite(group, category);
              const percentage = calculateAchievementPercentage(category);
              const totalEstimated = Object.keys(estimatedValues)
                .filter(key => key.startsWith(category))
                .reduce((sum, key) => sum + parseFloat(estimatedValues[key]), 0);
              const totalRealized = transactions
                .filter(transaction => transaction.category === category)
                .reduce((sum, transaction) => sum + parseFloat(transaction.price), 0);
              return (
                <div key={category} style={{ marginBottom: '0.5rem', padding: '0.3rem' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isFavorite ? (
                      <StarIcon
                        onClick={() => toggleFavorite(group, category)}
                        fontSize="small"
                        style={{ fill: 'yellow' }}
                      />
                    ) : (
                      <StarBorderIcon
                        onClick={() => toggleFavorite(group, category)}
                        fontSize="small"
                      />
                    )}
                    <Typography sx={{ color: '#E8E8E8',marginLeft: '4px', fontFamily: 'DM Sans', fontSize: '1rem' }}>
                      {getCategoryNameById(group, category)} | {getGroupNameById(group)}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Typography sx={{ color: '#E8E8E8', fontSize: '1rem' }}>
                      {percentage.toFixed(0)}%
                    </Typography>
                  </div>
                  <CustomProgressBar value={percentage} color="#8CE446" />
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ color: '#E8E8E8', fontSize: '1rem' }}>
                      {fCurrency(totalRealized.toFixed(2))}
                    </Typography>
                    <Typography sx={{ color: '#E8E8E8', fontSize: '1rem' }}>
                      {fCurrency(totalEstimated.toFixed(2))}
                    </Typography>
                  </div>
                </div>
              );
            })}
          </ul>
        </Grid>
      </Grid>
    </Scrollbar>

  );
}

CategorySummary.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired
  })).isRequired,
  estimatedValues: PropTypes.object.isRequired,
};

export default CategorySummary;
