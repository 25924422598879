import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Check';
import './transactions.css';
import { getDatabase, ref, set, onValue } from 'firebase/database';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { fCurrencyAlternative, unformatedValue } from '../../utils/formatNumber';


const StyledGroupContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
  fontFamily: 'DM Sans',
  width: '100%',
});


const StyledTextField = styled(TextField)(({ type }) => ({
  width: '7rem',
  height: '2.25rem',
  fontSize: '0.7rem',
  '& .MuiInputBase-input': {
    borderRadius: '0.3125rem',
    background: '#111114',
    padding: '6px 8px',
    color: '#E8E8E8',
    fontSize: '0.9rem',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '0.3125rem',
  },
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, 12px) scale(1)',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.75)',
  }
}));


const StyledTable = styled('table')(({ theme }) => ({
  width: '100%',
  overflowX: 'auto',
  borderCollapse: 'separate',
  borderSpacing: '0 0rem',
}));

const StyledTableCell = styled('td')(({ theme }) => ({
  padding: '0.455rem 0.455rem',
  border: '1px solid #5B5B5B',
  color: '#E8E8E8',
  fontSize: '0.9rem',
  fontFamily: 'DM Sans',
  '&:first-of-type': {
    borderLeft: 'none'
  },
  '&:last-of-type': {
    borderRight: 'none'
  },
}));

const StyledTableCellReal = styled('td')(({ theme }) => ({
  padding: '0.455rem 0.455rem',
  border: '1px solid #5B5B5B',
  fontFamily: 'DM Sans',
  color: '#E8E8E8',
  fontSize: '0.9rem',
  '&:first-of-type': {
    borderLeft: 'none'
  },
  '&:last-of-type': {
    borderRight: 'none'
  },
}));

const ExpandableRow = styled('tr')(({ theme, isOpen }) => ({
  cursor: 'pointer',
  backgroundColor: isOpen ? '#333' : 'transparent',
  '& > td': {
    borderBottom: isOpen ? 'none' : `1px solid ${theme.palette.divider}`,
  },
}));

const StyledMonthHeader = styled('td')(({ theme }) => ({
  padding: '0.4875rem 0.4875rem',
  fontFamily: 'DM Sans',
  fontSize: '1rem',
  textAlign: 'center',
  verticalAlign: 'middle',
}));

const StyledCategoryHeader = styled('td')(({ theme }) => ({
  padding: '0.4875rem 0.4875rem',
  textAlign: 'center',
  border: '1px solid #5B5B5B',
  fontFamily: 'DM Sans',
  verticalAlign: 'middle',
}));


function formatPrice(price) {
  return price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

function calculateGroupSums(groupTransactions, estimatedValues) {
  const sumByMonth = Array(12).fill(0);
  const estimatedByMonth = Array(12).fill(0);

  const categoriesInGroup = groupTransactions.map(t => t.category);

  groupTransactions.forEach(transaction => {
    const monthIndex = new Date(transaction.date).getMonth();
    sumByMonth[monthIndex] += parseFloat(transaction.price);
  });

  Object.keys(estimatedValues).forEach(key => {
    const [category, monthIndex] = key.split('_');
    if (categoriesInGroup.includes(category)) {
      estimatedByMonth[monthIndex] += parseFloat(estimatedValues[key]);
    }
  });

  return { sumByMonth, estimatedByMonth };
}


function calculateSumAndEstimatedByCategoryPerMonth(transactions) {
  const categories = [...new Set(transactions.map(transaction => transaction.category))];
  const months = Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('default', { month: 'long' }));
  const sumByCategoryPerMonth = {};
  const estimatedByCategoryPerMonth = {};

  categories.forEach(category => {
    sumByCategoryPerMonth[category] = Array(12).fill(0);
    estimatedByCategoryPerMonth[category] = Array(12).fill(0);
    transactions.forEach(transaction => {
      const monthIndex = new Date(transaction.date).getMonth();
      if (transaction.category === category) {
        sumByCategoryPerMonth[category][monthIndex] += parseFloat(transaction.price);
        estimatedByCategoryPerMonth[category][monthIndex] += parseFloat(transaction.estimated);
      }
    });
  });

  return { categories, months, sumByCategoryPerMonth, estimatedByCategoryPerMonth };
}

function TransactionsTable({ transactions, userId }) {

  
  const [tempEstimatedValues, setTempEstimatedValues] = useState({});

  
  const [estimatedValues, setEstimatedValues] = useState({});

  const [financeData, setFinanceData] = useState({});

  const [openGroup, setOpenGroup] = useState(null);
  const groups = transactions.reduce((acc, transaction) => {
    const { group } = transaction;
    if (!acc[group]) acc[group] = [];
    acc[group].push(transaction);
    return acc;
  }, {});


  React.useEffect(() => {
    const db = getDatabase();
    const financeRef = ref(db, `users/${userId}/finance`);
    const unsubscribe = onValue(financeRef, (snapshot) => {
      setFinanceData(snapshot.val() || {});
    });

    return () => {
      unsubscribe();
    };
  }, [userId]);

  const { months } = calculateSumAndEstimatedByCategoryPerMonth(transactions);
  const handleTempEstimatedChange = (category, monthIndex, value) => {
    const numericValue = unformatedValue(value);
    setTempEstimatedValues(prevValues => ({
      ...prevValues,
      [`${category}_${monthIndex}`]: numericValue || 0
    }));
  };
  const handleSaveEstimated = (category, monthIndex) => {
    const key = `${category}_${monthIndex}`;
    const value = tempEstimatedValues[key];
    handleEstimatedChange(category, monthIndex, value);
  };


  const getGroupNameById = (groupId) => {
    const foundGroup = Object.entries(financeData)
      .map(([key, value]) => value.groups && value.groups[groupId] && value.groups[groupId].name)
      .find(name => name !== undefined);
    return foundGroup || groupId;
  }

  const getCategoryNameById = (groupId, categoryId) => {
    const foundCategory = Object.entries(financeData)
      .map(([key, value]) => value.groups && value.groups[groupId] && value.groups[groupId].categories && value.groups[groupId].categories[categoryId] && value.groups[groupId].categories[categoryId].name)
      .find(name => name !== undefined);
    return foundCategory || categoryId;
  }
  const handleEstimatedChange = (category, monthIndex) => {
    const key = `${category}_${monthIndex}`;
    const value = tempEstimatedValues[key];
    if (value !== undefined) {
      const db = getDatabase();
      const month = monthIndex + 1;
      const estimatedRef = ref(db, `users/${userId}/estimatedValues/${category}/${month}`);
      set(estimatedRef, { estimated: value });
    }
  };
  useEffect(() => {
    const db = getDatabase();
    const estimatedValuesRef = ref(db, `users/${userId}/estimatedValues`);
    const unsubscribe = onValue(estimatedValuesRef, (snapshot) => {
      const data = snapshot.val();
      const newEstimatedValues = {};
      Object.keys(data || {}).forEach(category => {
        Object.keys(data[category] || {}).forEach(month => {
          const monthIndex = parseInt(month, 10) - 1;
          newEstimatedValues[`${category}_${monthIndex}`] = data[category][month].estimated;
        });
      });
      setEstimatedValues(newEstimatedValues);
      setTempEstimatedValues(newEstimatedValues);
    });

    return () => {
      unsubscribe();
    };
  }, [userId]);

  const getCategoryVisibilityById = (groupId, categoryId) => Object.entries(financeData)
      .map(([key, value]) => value.groups && value.groups[groupId] && value.groups[groupId].categories && value.groups[groupId].categories[categoryId] && value.groups[groupId].categories[categoryId].isVisible)
      .find(isVisible => isVisible !== undefined);
  return (
    <>
      <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
        <StyledTable>
          <thead>
            <tr>
              <StyledCategoryHeader rowSpan={2}>Orçamento</StyledCategoryHeader>
              {months.map(month => (
                <StyledMonthHeader colSpan={2} key={month}>{month.substring(0, 3)}</StyledMonthHeader>
              ))}
            </tr>
            <tr>
              {months.map(month => (
                <React.Fragment key={month}>

                  <StyledTableCellReal align="right">Rea</StyledTableCellReal>
                  <StyledTableCell align="right">Est</StyledTableCell>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(groups).map(group => {
              const { sumByMonth, estimatedByMonth } = calculateGroupSums(groups[group], estimatedValues);
              const uniqueCategories = [...new Set(groups[group].map(transaction => transaction.category))];
              return (
                <React.Fragment key={group}>
                  <ExpandableRow isOpen={openGroup === group} onClick={() => setOpenGroup(openGroup === group ? null : group)}>
                    <StyledTableCell colSpan={1}>
                      <StyledGroupContainer>
                        {openGroup === group ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        {getGroupNameById(group)}
                      </StyledGroupContainer>
                    </StyledTableCell>
                    {months.map((month, monthIndex) => (
                      <React.Fragment key={month}>
                      <StyledTableCellReal align="right">{formatPrice(sumByMonth[monthIndex]) || 0}</StyledTableCellReal>
                        <StyledTableCell align="right">{formatPrice(estimatedByMonth[monthIndex]) || 0}</StyledTableCell>
                      </React.Fragment>
                    ))}
                  </ExpandableRow>


                  {openGroup === group && uniqueCategories.filter(category => getCategoryVisibilityById(group, category)).map(category => (
                    <tr key={category}>
                      <StyledTableCell>{getCategoryNameById(group, category)}</StyledTableCell>
                      {months.map((month, monthIndex) => {
                        const categoryTransactions = groups[group].filter(t => t.category === category);
                        const totalForMonth = categoryTransactions.filter(t => new Date(t.date).getMonth() === monthIndex).reduce((acc, t) => acc + parseFloat(t.price), 0);
                        return (
                          <React.Fragment key={month}>

                            <StyledTableCellReal align="right">{formatPrice(totalForMonth)}</StyledTableCellReal>
                            <StyledTableCell align="right">
                              <StyledTextField
                              variant='standard'
                                value={fCurrencyAlternative(tempEstimatedValues[`${category}_${monthIndex}`] || 0)}
                                onChange={(e) => handleTempEstimatedChange(category, monthIndex, e.target.value)}
                              />
                              <IconButton sx={{ color: '#E8E8E8' }} aria-label="Salvar valor estimado" onClick={() => handleSaveEstimated(category, monthIndex)}>
                                <SaveIcon />
                              </IconButton>
                            </StyledTableCell>
                          </React.Fragment>
                        );
                      })}
                    </tr>
                  ))}
                </React.Fragment>
              )
            })}

          </tbody>
        </StyledTable>
      </div>
    </>
  );
}
TransactionsTable.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    estimated: PropTypes.string,
    group: PropTypes.string.isRequired
  })).isRequired,
  userId: PropTypes.string.isRequired
};
export default TransactionsTable;
