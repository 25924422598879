import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { toastManager } from '../utils/toast'; // Atualize o caminho conforme necessário

function ToastManager() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [duration, setDuration] = useState(600); 
  const [anchorOrigin, setAnchorOrigin] = useState({ vertical: 'bottom', horizontal: 'left' });

  useEffect(() => {
    const handleAddToast = ({ type, text, duration, position }) => {
      setSeverity(type);
      setMessage(text);
      setDuration(duration || 500);
      setOpen(true);
      if (position) {
        setAnchorOrigin(position);  // Atualiza a posição se fornecida
      }
    };

    toastManager.on('addtoast', handleAddToast);

    return () => {
      toastManager.removeListener('addtoast', handleAddToast);
    };
  }, []);
  

  useEffect(() => {
    const handleAddToast = ({ type, text, duration }) => {
      setSeverity(type);
      setMessage(text);
      setDuration(duration || 500);
      setOpen(true);
    };

    toastManager.on('addtoast', handleAddToast);

    return () => {
      toastManager.removeListener('addtoast', handleAddToast);
    };
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default ToastManager;
