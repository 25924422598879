import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme, variant }) => {
  let backgroundColor = 'transparent';
  let color = '#E8E8E8';
  let borderColor = 'transparent';

  switch (variant) {
    case 'primary':
      backgroundColor = '#005BDA';
      break;
    case 'secondary':
      borderColor = '#005BDA';
      backgroundColor =  '#E8E8E8';
      color = '#005BDA';
      break;
    case 'disabled':
      backgroundColor = 'rgba(74, 74, 91, 0.30)';
      break;
    case 'custom':
      backgroundColor = '#000';
      color = '#E8E8E8';
      borderColor = '#E8E8E8';
      break;
    default:
      break;
  }

  return {
    backgroundColor,
    color,
    borderRadius: '5rem',
    textTransform: 'none',
    border: variant === 'custom' ? `2px solid ${  borderColor}` : `1px solid ${borderColor}`,
    '&:hover': {
      backgroundColor,
      color: variant === 'secondary' ? '#E8E8E8' : color,
    },
  };
});

function CustomButton({ variant = 'primary', ...props }) {
  return <StyledButton variant={variant} {...props} />;
}

export default CustomButton;
