import React from 'react';
import { Helmet } from 'react-helmet-async';
import { styled, useTheme } from '@mui/material/styles';
import { Container, Typography, Divider, Stack, Button, Grid } from '@mui/material';
import Iconify from '../components/iconify';
import { LoginForm } from '../sections/auth/login';
import useLogin from '../hooks/useLogin';
import Logo from '../components/logo/Logo';

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: 0,
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  height: '100vh',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  maxWidth: 'none',
  margin: 0,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const StyledLeftContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  background: 'url(assets/illustrations/back.png) no-repeat center center',
  backgroundSize: 'cover',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const StyledRightContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '700px',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(10),
  height: '100vh',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    height: 'auto',
  },
}));

const LoginContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

export default function LoginPage() {
  const theme = useTheme();
  const { loginWithGoogle, loginWithFacebook, error } = useLogin();

  return (
    <div style={{ height: '100vh' }}>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Grid container spacing={2} style={{ height: '100%' }}>
        <Grid item xs={12} md={6}>
          <StyledLeftContent />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}
        >
          <StyledRightContent>
            <LoginContainer>
              <div
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
              >
                <LoginForm />
                <Divider sx={{ my: 3 }}>
                  <Typography variant="body2">Entrar com</Typography>
                </Divider>

                <Stack direction="row" spacing={2}>
                  <Button
                    fullWidth
                    size="medium"
                    color="inherit"
                    variant="outlined"
                    onClick={loginWithGoogle}
                    startIcon={<Iconify icon="flat-color-icons:google" width={22} height={22} />}
                  >
                    Google
                  </Button>
                  <Button
                    fullWidth
                    size="medium"
                    color="inherit"
                    variant="outlined"
                    onClick={loginWithFacebook}
                    startIcon={<Iconify icon="brandico:facebook" width={22} height={22} />}
                  >
                    Facebook
                  </Button>
                </Stack>
              </div>
            </LoginContainer>
          </StyledRightContent>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(3) }}>
            <Logo />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
