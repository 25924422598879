import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue, off} from 'firebase/database';
import { Business } from "@mui/icons-material";
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import { capitalizeFirstLetter, mapGoalsName } from "../../utils/formatText";
import { fCurrency } from "../../utils/formatNumber";
import { database } from '../../services/firebase';

const ClientListInvestment = () => {
  const [clients, setClients] = useState([]);
  const theme = useTheme()

  useEffect(() => {
    const database = getDatabase();
    const clientsRef = ref(database, 'users');

    const handleSnapshot = (snapshot) => {
      const clientsObject = snapshot.val();
      const clientsData = [];

      if (clientsObject) {
        Object.entries(clientsObject).forEach(([clientId, client]) => {
          if (client.role === 'cliente') {
            clientsData.push({ id: clientId, ...client });
          }
        });
      }

      setClients(clientsData);
    };

    const errorCallback = (error) => {
      console.error(error);
      // Handle error
    };

    onValue(clientsRef, handleSnapshot, errorCallback);

    return () => {
      // Cleanup by detaching the event listener
      off(clientsRef, 'value', handleSnapshot);
    };
  }, []);
  return (
    <Container maxWidth='xl'>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} mb={3}>
        <Typography variant='h3'>Detalhar Investimentos</Typography>
      </Box>
      <Card sx={{boxShadow: theme.customShadows.primary}}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cliente</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Receita</TableCell>
              <TableCell>Despesa</TableCell>
              <TableCell>Objetivo</TableCell>
              <TableCell>Tipo de Investimento</TableCell>
              <TableCell>Valor do Investimento</TableCell>
              <TableCell>Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => {
              const investments = [];

              if (client.goals && Object.values(client.goals)) {
                Object.entries(client.goals).forEach(([goalName, goal]) => {
                  if (goal.investments && goal.investments.registeredContributions) {
                    goal.investments.registeredContributions.forEach((investment) => {
                      investments.push({
                        name: client.name,
                        email: client.email,
                        monthlyIncome: client.dadosCadastrais?.monthlyIncome || '',
                        monthlyExpenses: client.dadosCadastrais?.monthlyExpenses || '',
                        objective: goalName,
                        investmentType: investment.investmentType || '',
                        investmentValue: investment.investmentValue || '',
                      });
                    });
                  } else {
                    const alreadyAdded = investments.some((investment) => investment.name === client.name);
                    if (!alreadyAdded) {
                      investments.push({
                        name: client.name,
                        email: client.email,
                        monthlyIncome: client.dadosCadastrais?.monthlyIncome || '',
                        monthlyExpenses: client.dadosCadastrais?.monthlyExpenses || '',
                      });
                    }
                  }
                });
              }

              return investments.map((investment, index) => (
                <TableRow key={index}>
                  <TableCell>{capitalizeFirstLetter(investment.name)}</TableCell>
                  <TableCell>{investment.email}</TableCell>
                  <TableCell>{fCurrency(investment.monthlyIncome)}</TableCell>
                  <TableCell>{fCurrency(investment.monthlyExpenses)}</TableCell>
                  <TableCell>{mapGoalsName[investment.objective]}</TableCell>
                  <TableCell>{investment.investmentType}</TableCell>
                  <TableCell>{fCurrency(investment.investmentValue)}</TableCell>
                  <TableCell>
                  <Button
                    component={Link}
                    to={`/dashboard/investments/${client.id}`}
                    variant="outlined"
                    startIcon={<Business/>}
                  >
                  Investimentos
                  </Button>
                </TableCell>
                </TableRow>
              ));
            })}
          </TableBody>
        </Table>
      </TableContainer>
      </Card>
    </Container>
  )
}

export default ClientListInvestment;
