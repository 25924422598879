import numeral from 'numeral';

export function fNumber(number) {
  return numeral(number).format();
}

export function fCurrency(value) {
  const stringValue = typeof value === 'string' ? value : String(value);
  const number = Number(stringValue.replace(/\D/g, ''));
  const formattedValue = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number / 100);
  return formattedValue;
}

export function fCurrencyAlternative(value) {
  const stringValue = typeof value === 'string' ? value : String(value);
  
  let number;
  if (stringValue.includes('.')) {
    number = Number(stringValue.replace(/\D/g, '')) / 100; // Convertendo para formato de ponto flutuante
  } else {
    number = Number(stringValue);
  }

  const formattedValue = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
  return formattedValue;
}

export function fCurrencyNumber(value) {

  const numericValue = typeof value === 'string' ? Number(value.replace(/\D/g, '')) : Number((String(value)).replace(/\D/g, ''));
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numericValue);
  const currencySymbol = 'R$';
  const result = formattedValue.replace('BRL', currencySymbol);
  return result;
}



export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

export function unFormattedEmergencyFund(value) {
  return typeof value === 'string' ? value.replace(/[R$\s,.]/g, '') : '';
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function unformatedValue(value) {
  const stringValue = typeof value === 'string' ? value : '';
  const modifiedValue = stringValue.replace(/[^0-9]/g, '');

  if(modifiedValue === '0000') return '';
  const unformattedValue = `${modifiedValue.slice(0, -2)}.${modifiedValue.slice(-2)}`;

  return unformattedValue;
}


export function formatToBRL(value) {
  const formattedValue = parseFloat(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formattedValue;
}


export function cleanCurrencyFormat(value) {
  if (!value) return value;
  return parseFloat(value.replace(/\D/g, '')) / 100; // Remove todos os caracteres não numéricos e divide por 100 para obter o valor correto.
}
