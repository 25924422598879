import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AssetsForm from '../components/financial/AssetsForm';
import IntroductionDNAFinancial from "../components/forms/IntroductionDNAFinancial";
import BigFiveTest from "./BigFive";
import DependentForm from '../components/profile/dependente/DependenteForm';
import NavigableTabs from "./NavigationTabs";
import BigFiveProfiles from '../components/profile/ShowProfile';
import Financial from '../components/financial/Financial';
import GoalManager from '../components/goal/GoalManager';


export default function Home() {
  const [currentStep, setCurrentStep] = useState(0);
  const [isDependent, setIsDependet] = useState(false)
  
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab');
  const objectiveFromURL = searchParams.get('objective');
  const userId = searchParams.get('hash');

  const nextStep = () => {
    setCurrentStep(prevStep => prevStep + 1);
  };

  const goToStep = (step) => {
    setCurrentStep(step);
  };

  const startAddingDependent = () => {
    setIsDependet(true)
    setCurrentStep(2);
  };

  let profileComponent;
  switch(currentStep) {
    case 0:
      profileComponent = <BigFiveTest startAddingDependent={startAddingDependent} isDependent={isDependent} onNext={nextStep} onDisplayProfile={() => goToStep(3)}  />;
      break;
    case 1:
      profileComponent = <DependentForm onNext={() => goToStep(1)} />;
      break;
      case 2:
        profileComponent = <BigFiveProfiles />;
      break;
    default:
      profileComponent = <IntroductionDNAFinancial onNext={nextStep} />;
  }

  const tabs = [
    { label: 'Personalidade', component: profileComponent },
    { label: 'Financeiro', component: <Financial /> },
    { label: 'Objetivos', component: <GoalManager  initialObjective={objectiveFromURL} userIdParams={userId} /> },
    { label: 'Patrimônio', component: <AssetsForm /> }
  ];

  const defaultTabIndex = selectedTab ? tabs.findIndex(tab => tab.label.toLowerCase() === selectedTab.toLowerCase()) : 0;

  return (
    <NavigableTabs tabData={tabs} color="secondary" defaultTabIndex={defaultTabIndex} />
  )
}
