import { getDatabase, ref, get, push } from 'firebase/database';
import { format } from 'date-fns';

export const calculateAndSaveAssetAchievements = async (userId, assets) => {
  const db = getDatabase();
  const achievementsRef = ref(db, `users/${userId}/achievements`);
  const snapshot = await get(achievementsRef);

  const currentAchievements = snapshot.exists() ? snapshot.val() : {};

  const totalAssetValue = assets.reduce((acc, asset) => acc + Number(asset.valorMercado || 0), 0);
  const newAchievements = [];
  const currentDate = format(new Date(), 'yyyy-MM-dd');

  // Fetch policies and calculate policy percentage
  const policiesRef = ref(db, `users/${userId}/policies`);
  const policiesSnapshot = await get(policiesRef);

  let policyPercentage = 0;

  if (policiesSnapshot.exists()) {
    const policies = Object.values(policiesSnapshot.val());
    const totalPolicyValue = policies.reduce((acc, policy) => acc + Number(policy.valorApolice || 0), 0);
    policyPercentage = (totalPolicyValue / totalAssetValue) * 100;
  }

  const assetRank = getAssetRank(policyPercentage);
  const existingAssetAchievement = Object.values(currentAchievements).find(
    (achievement) => achievement.rank === assetRank && achievement.type === 'totalAssetValue'
  );

  if (!existingAssetAchievement) {
    newAchievements.push({
      type: 'totalAssetValue',
      rank: assetRank,
      dateAchieved: currentDate,
    });
  }

  const pushPromises = newAchievements.map((achievement) => push(achievementsRef, achievement));
  await Promise.all(pushPromises);
};

const getAssetRank = (percentage) => {
  if (percentage >= 150) return 'Superprotetor';
  if (percentage >= 100) return 'Seguro Total';
  if (percentage >= 50) return 'Conservador';
  return 'Desprotegido';
};
