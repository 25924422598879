import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { getDatabase, ref, get } from "firebase/database";
import { Link } from 'react-router-dom';
import { fCurrencyAlternative } from '../utils/formatNumber';

const BoxCard = ({ userId }) => {
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [balance, setBalance] = useState(0);
  const [estimatedInvestment, setEstimatedInvestment] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const db = getDatabase();

      // Fetch transactions
      const transactionsRef = ref(db, `users/${userId}/transactions`);
      const transactionsSnapshot = await get(transactionsRef);

      // Fetch investmentSummary
      const investmentSummaryRef = ref(db, `users/${userId}/investmentSummary`);
      const investmentSummarySnapshot = await get(investmentSummaryRef);

      // Fetch estimated values
      const estimatedValuesRef = ref(db, `users/${userId}/estimatedValues/Investimento`);
      const estimatedValuesSnapshot = await get(estimatedValuesRef);

      if (transactionsSnapshot.exists()) {
        const transactions = transactionsSnapshot.val();
        let total = 0;
        Object.values(transactions).forEach(transaction => {
          if (transaction.type === "Investment") {
            total += Number(transaction.price);
          }
        });
        setTotalInvestment(total);
      }

      if (investmentSummarySnapshot.exists()) {
        const { balance } = investmentSummarySnapshot.val();
        setBalance(balance);
      }

      if (estimatedValuesSnapshot.exists()) {
        const estimatedValues = estimatedValuesSnapshot.val();
        let totalEstimated = 0;
        Object.values(estimatedValues).forEach(value => {
          totalEstimated += Number(value.estimated);
        });
        setEstimatedInvestment(totalEstimated);
      }
    };

    fetchData();
  }, [userId]);

  const estimatedPercentage = ((estimatedInvestment / totalInvestment) * 100).toFixed(2);
  const backgroundImage = `url('/assets/images/goals/cofre.png') `
  return (
    <>
    <Link to="/movement" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Box
      sx={{
        px: 3,
        py: 2,
        borderRadius: '0.625rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundImage,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        border: '1px solid #8CE446',
        // background: '#000',
        alignItems: 'center',
        width: '100%',
        height: '95%'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: '0.625rem',
          background: '#000',
          boxSshadow: '0px 5px 4px 0px rgba(0, 0, 0, 0.33)',
          border: '1px solid #4A4A5B',
          padding: 2,
          marginTop: -4,
          minWidth: '11.875rem',
          minHeight: '2rem'
        }}
      >
        <Typography
          sx={{fontSize: '0.95rem', color: '#E8E8E8', fontWeight: 500}}
          color="#E8E8E8"
        >
          COFRE ASCENDA
        </Typography>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', borderRadius: '0.625rem', background: 'rgba(0, 0, 0, 0.90)', width: '70%', padding: '1rem', flex: 1, maxHeight: '45%', margin: 'auto 0' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography sx={{ fontSize: '0.95rem', color: '#E8E8E8', fontWeight: '500' }}>Total ainda não aplicado:</Typography>
          <Typography sx={{ fontSize: '1.125rem', color: '#8CE446', fontWeight: '500' }} variant="body1">{fCurrencyAlternative(balance)}</Typography>
        </div>
        <Typography sx={{ fontSize: '0.85rem', color: '#4A4A5B', fontWeight: '500' }}>Estimado x Realizado:</Typography>
        <Box sx={{ width: '90%', position: 'relative', marginBottom: '0.5rem' }}>
          <Typography variant="caption" sx={{ position: 'absolute', left: 0, marginTop: '1rem' }}>{fCurrencyAlternative(totalInvestment)}</Typography>
          <Typography variant="caption" sx={{ position: 'absolute', right: 0, marginTop: '1rem' }}>{fCurrencyAlternative(estimatedInvestment)}</Typography>
          <LinearProgress variant="determinate" color="success" value={Number(estimatedPercentage)} sx={{ height: '0.7rem', borderRadius: '1.0625rem',  background: 'rgba(41, 42, 45, 1)', color: '#8CE446' }} />
        </Box>
        <Typography variant="body1" sx={{ textAlign: 'center', width: '100%', color: '#8CE446', fontWeight: 'bold', marginTop: '1.2rem',}}>{estimatedPercentage}%</Typography>
      </div>
    </Box>
    </Link>
    </>
  );
};

export default BoxCard;
