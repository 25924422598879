import React, { useState, useEffect, useContext } from 'react';
import { getDatabase, ref, set, onValue, remove, update } from 'firebase/database';
import { Typography, Grid, Stack, TextField, Select, MenuItem, IconButton, Box, Container } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import { DataGrid } from '@mui/x-data-grid';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { fCurrency, fCurrencyNumber, unformatedValue } from '../utils/formatNumber';
import CustomButton from './button/CustomButton';
import { ThemeToggleContext } from '../theme';
import { calculateAndSaveAssetAchievements } from '../utils/achievementsAssets';
import toast from '../utils/toast'; // Import the toast function

const AssetsTable = () => {
  const [assets, setAssets] = useState([]);
  const [userId, setUserId] = useState(null);
  const { clientId } = useParams(); // Get clientId from URL params
  const { setMode } = useContext(ThemeToggleContext);

  useEffect(() => {
    // Configura o modo para claro quando o componente monta
    setMode('light');

    return () => {
      setMode('dark'); // Volta o tema para "dark"
    };
  }, [setMode]);

  const navigate = useNavigate();
  const db = getDatabase();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchAssets = async (id) => {
      const assetsRef = ref(db, `users/${id}/assets`);
      onValue(assetsRef, (snapshot) => {
        const data = snapshot.val();
        const loadedAssets = data ? Object.keys(data).map((key) => ({ id: key, ...data[key] })) : [];
        setAssets(loadedAssets);
      });
    };

    if (clientId) {
      fetchAssets(clientId);
    } else if (userId) {
      fetchAssets(userId);
    }
  }, [userId, clientId]);

  useEffect(() => {
    const id = clientId || userId;
    if (id && assets.length > 0) {
      calculateAndSaveAssetAchievements(id, assets);
    }
  }, [assets, userId, clientId]);

  const addAsset = () => {
    setAssets([
      ...assets,
      {
        id: uuidv4(),
        nome: '',
        valorCompra: '',
        valorMercado: '',
        dataCompra: new Date().toISOString().slice(0, 10), // formato YYYY-MM-DD
        categoria: '',
      },
    ]);
  };

  const deleteAsset = (id) => {
    const assetRef = ref(db, `users/${clientId || userId}/assets/${id}`);
    remove(assetRef)
      .then(() => {
        setAssets(assets.filter((asset) => asset.id !== id));
        toast({
          type: 'success',
          text: 'Bem deletado com sucesso.',
          duration: 4000,
        });
      })
      .catch((error) => {
        toast({
          type: 'error',
          text: 'Erro ao deletar bem.',
          duration: 4000,
        });
      });
  };

  const handleInputChange = (id, name, value) => {
    const unformattedValue = name.includes('valor') ? unformatedValue(value) : value;
    setAssets((currentAssets) =>
      currentAssets.map((asset) => (asset.id === id ? { ...asset, [name]: unformattedValue } : asset))
    );
  };

  const handleBlur = (id, name) => {
    const value = assets.find((asset) => asset.id === id)[name];
    if (value.trim() !== '') {
      // Verifica se o valor não é apenas espaços em branco
      const assetRef = ref(db, `users/${clientId || userId}/assets/${id}`);
      update(assetRef, { [name]: value })
        .then(() => {
          toast({
            type: 'success',
            text: 'Bem atualizado com sucesso.',
            duration: 4000,
          });
        })
        .catch((error) => {
          toast({
            type: 'error',
            text: 'Erro ao atualizar bem.',
            duration: 4000,
          });
        });
    }
  };

  const saveAsset = (asset) => {
    const assetRef = ref(db, `users/${clientId || userId}/assets/${asset.id}`);
    update(assetRef, asset)
      .then(() => {
        toast({
          type: 'success',
          text: 'Bem salvo com sucesso.',
          duration: 4000,
        });
      })
      .catch((error) => {
        toast({
          type: 'error',
          text: 'Erro ao salvar bem.',
          duration: 4000,
        });
      });
  };

  const totalValorMercado = assets.reduce((acc, asset) => acc + Number(asset.valorMercado || 0), 0);
  const quantidadeBens = assets.length;

  const handleGoBack = () => {
    navigate(-1);
  };

  const columns = [
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 1,
      renderCell: (params) => (
        <TextField
          variant="standard"
          value={params.value}
          onChange={(e) => handleInputChange(params.row.id, 'nome', e.target.value)}
          onBlur={() => handleBlur(params.row.id, 'nome')}
          fullWidth
          InputProps={{
            style: { fontSize: '0.8rem' },
          }}
        />
      ),
    },
    {
      field: 'valorCompra',
      headerName: 'Valor da Compra',
      flex: 1,
      renderCell: (params) => (
        <TextField
          variant="standard"
          value={fCurrency(params.value || 0)}
          onChange={(e) => handleInputChange(params.row.id, 'valorCompra', e.target.value)}
          onBlur={() => handleBlur(params.row.id, 'valorCompra')}
          fullWidth
          InputProps={{
            style: { fontSize: '0.8rem' },
          }}
        />
      ),
    },
    {
      field: 'valorMercado',
      headerName: 'Valor de Mercado',
      flex: 1,
      renderCell: (params) => (
        <TextField
          variant="standard"
          value={fCurrency(params.value || 0)}
          onChange={(e) => handleInputChange(params.row.id, 'valorMercado', e.target.value)}
          onBlur={() => handleBlur(params.row.id, 'valorMercado')}
          fullWidth
          InputProps={{
            style: { fontSize: '0.8rem' },
          }}
        />
      ),
    },
    { field: 'dataCompra', headerName: 'Data da Compra', flex: 1 },
    {
      field: 'categoria',
      headerName: 'Categoria',
      flex: 1,
      renderCell: (params) => (
        <Select
          variant="standard"
          value={params.value}
          onChange={(e) => handleInputChange(params.row.id, 'categoria', e.target.value)}
          displayEmpty
          fullWidth
          sx={{ fontSize: '0.8rem' }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Imóveis">Imóveis</MenuItem>
          <MenuItem value="Veículo">Veículo</MenuItem>
          <MenuItem value="Arte">Arte</MenuItem>
          <MenuItem value="Títulos">Títulos</MenuItem>
          <MenuItem value="Não tangíveis">Não tangíveis</MenuItem>
          <MenuItem value="Máquinas e equipamentos">Máquinas e equipamentos</MenuItem>
        </Select>
      ),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => saveAsset(params.row)}>
            <SaveIcon />
          </IconButton>
          <IconButton onClick={() => deleteAsset(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Container maxWidth={false} sx={{ px: 8 }}>
      <Grid container spacing={1} sx={{ marginTop: '0.9rem', width: '100%' }}>
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          <Typography
            textAlign="center"
            sx={{ fontSize: '1.6rem', fontFamily: 'DM Sans', alignItems: 'center', mb: 4 }}
          >
            Meus Bens
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              height: '100%',
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                background: '#FFF',
                alignItems: 'center',
                borderRadius: '12.625rem',
                border: '1px solid #000',
                width: ' 16rem',
                height: '5.54913rem',
                flexShrink: 0,
              }}
            >
              <Typography textAlign="center" sx={{ color: '#000', fontSize: '0.7' }}>
                Valor Total dos bens
              </Typography>
              <Typography textAlign="center" sx={{ color: '#000', fontSize: '1rem' }}>
                {fCurrencyNumber(totalValorMercado)}
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                background: '#FFF',
                alignItems: 'center',
                borderRadius: '12.625rem',
                border: '1px solid #000',
                width: ' 16rem',
                height: '5.54913rem',
                flexShrink: 0,
              }}
            >
              <Typography textAlign="center" sx={{ color: '#000', fontSize: '1rem' }}>
                Quantidade de bens
              </Typography>
              <Typography textAlign="center" sx={{ color: '#000', fontSize: '1.3rem' }}>
                {quantidadeBens}
              </Typography>
            </div>

            <Stack direction="row" spacing={1}>
              <CustomButton
                sx={{
                  width: '150px',
                }}
                onClick={handleGoBack}
              >
                Voltar
              </CustomButton>
            </Stack>
          </div>
        </Grid>
        <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
          <Box display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
            <IconButton onClick={addAsset}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
          <div style={{ height: 600, width: '100%' }}>
            <DataGrid
              rows={assets}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
              autoHeight
              components={{
                NoRowsOverlay: () => <Typography>Nenhum bem cadastrado.</Typography>,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AssetsTable;
