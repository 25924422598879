import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import { auth, database } from '../../services/firebase';

const capitalizeFirstLetter = (string) => {
  const words = string.split(' ');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
};

const IntroductionGoalForm = () => {
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const fetchUserName = async (userId) => {
      try {
        const userRef = ref(database, `users/${userId}`);
        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userName = capitalizeFirstLetter(userData.name);
          setUserName(userName);
        }
      } catch (error) {
        console.error('Error fetching user name:', error);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserName(user.uid);
      } else {
        console.log('No user is signed in');
      }
    });

    return unsubscribe;
  }, []);

  if (!userName) {
    return null; // Render nothing if userName is not set
  }

  return (
    <Container sx={{marginBottom: '2rem', marginTop: '2rem'}}>
      <Typography variant="h3">{`Olá ${userName},`}</Typography>
      <Box 
        variant="body1"
        sx={{ 
          wordBreak: 'break-word',
          lineHeight: '1.5',
          marginTop: '1.5px'
        }}
      >
        <Typography>
        Agora que a gente já se conhece, eu preciso entender um pouco mais dos seus objetivos para calculá-los e te ajudar a conquistar todos os sonhos.
        </Typography>
        
        <Typography>
          Como parte do nosso processo de planejamento financeiro, é importante entender seus objetivos financeiros e estilo de vida desejado.
          Isso nos ajudará a criar um plano personalizado para você.
          </Typography>
      </Box>

      <Typography
        variant="body1"
        sx={{ 
          wordBreak: 'break-word',
          lineHeight: '1.5'
        }}
      >
        <Typography
          sx={{ 
            wordBreak: 'break-word',
            lineHeight: '1.5'
          }}
          variant='subtitle1'
        >
          IMPORTANTE:
        </Typography> 
        Descreva seus objetivos como se eles estivessem todos zerados, como se você estivesse iniciando cada um deles hoje.
        Depois destas perguntas, você poderá alocar os recursos que já possui em cada um deles, não se preocupe.
      </Typography>

      <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
        Nas próximas 08 perguntas vamos contextualizar exatamente isso, responda com calma.
      </Typography>
    </Container>
  );
}

export default IntroductionGoalForm;
