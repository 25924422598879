import React from 'react';
import { Typography } from '@mui/material';
import 'three-dots/dist/three-dots.css';
import './custom-dots.scss';

const Loader = ({ message = 'Carregando seu mapa financeiro' }) => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999,
    }}
  >
    <div className="dot-elastic" style={{ marginBottom: '10px' }} />
    <Typography variant="body1" color="#FFF">
      {message}
    </Typography>
  </div>
);

export default Loader;
