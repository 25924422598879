
import React, { useState } from 'react';
import { Box, Button } from "@mui/material";
import SvgColor from "../../svg-color/SvgColor";
import { NewTransactionModal } from "../../transactions/NewTransactionModal";
import { NewInvestmentModal } from '../../NewInvestmentModal'

export default function StackButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenInvestment, setIsModalOpenInvestment] = useState(false);
  const [initialType, setInitialType] = useState('income');

  const handleIconClick = (type) => {
    setInitialType(type);
    setIsModalOpen(true);
  };

  const handleInvestmentnClick = (type) => {
    setIsModalOpen(true);
  };

  const handleInvestmentClick = () => {
    setIsModalOpenInvestment(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsModalOpenInvestment(false);
  };

  return (
    <>
   
    
    <Box
      sx={{
        position: 'fixed',
        display: 'flex',
        flexDirection: 'row',
        gap: 0,
        bottom: ['4vh', '6vh'],
        left: '50%',
      }}
    >
      <Box
        sx={{
          transform: 'translateX(-50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 3,
          width: ['50vw', '30vw'],
          height: '10vh',
        }}
      >

        <Box
          sx={{
            background: 'linear-gradient(-66.43deg, rgba(0, 0, 0, 1.00) 0.12315115891397%,rgba(17, 17, 20, 1.00) 60.41908860206604%,rgba(17, 17, 20, 0.00) 100%)',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderStyle: 'solid',
            borderColor: '#292a2d',
            borderWidth: '1px',
            width: '70px',
            height: '70px',
            position: 'relative',
            boxShadow: '0px -2px 8px 0px rgba(0, 0, 0, 0.33)',
          }}
          onClick={() => handleIconClick('income')}
        >
          <SvgColor
            src='/assets/icons/fi-rr-pharmacy.svg'
            sx={{
              width: 20,
              height: 20,
              color: '#8CE446',
            }}
          />
        </Box>
        
      <Box
        sx={{
          width: '70px',
          height: '70px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'linear-gradient(180deg, rgba(154, 154, 194, 0.00) 0%, rgba(84, 84, 106, 0.34) 26.69%, rgba(0, 0, 0, 0.75) 71.16%)',
          borderRadius: '50%',
          borderStyle: 'solid',
          borderColor: '#292a2d',
          borderWidth: '1px',
          backdropFilter: 'blur(3px)',

        }}
        onClick={() => handleInvestmentClick()}
      >
        <SvgColor
          src='/assets/icons/plane.svg'
          sx={{
            width: 30,
            height: 30,
            color: '#9A9AC2'
          }}
        />
      </Box>


        <Box
          sx={{
            
            background: 'linear-gradient(-66.43deg, rgba(0, 0, 0, 1.00) 0.12315115891397%,rgba(17, 17, 20, 1.00) 60.41908860206604%,rgba(17, 17, 20, 0.00) 100%)',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderStyle: 'solid',
            borderColor: '#292a2d',
            borderWidth: '1px',
            width: '70px',
            height: '70px',
            position: 'relative',
            boxShadow: '0px -2px 8px 0px rgba(0, 0, 0, 0.33)',
          }}
          onClick={() => handleIconClick('outcome')}
        >
          <SvgColor
            src='/assets/icons/minus.svg'
            sx={{
              width: 36,
              height: 36,
              color: '#FF0062'
            }}
          />
        </Box>
      </Box>
      <NewTransactionModal
        open={isModalOpen}
        onClose={handleCloseModal}
        initialType={initialType}
      />
      <NewInvestmentModal
        open={isModalOpenInvestment}
        onClose={handleCloseModal}
      />
    </Box>
    </>

  )
}