import React, { useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useParams , useNavigate } from "react-router-dom";

import { Grid } from '@mui/material';

import CustomButton from '../components/button/CustomButton';
import TransactionsTable from '../components/transactions/TransactionsTable';
import StackButton from '../components/dashboard/client/StackButton';
import toast from '../utils/toast'
import CategorySummary from '../components/transactions/CategorySummary';

function TransactionsPage() {
  const [state, setState] = useState({
    userId: null,
    transactions: [],
    searchTerm: '',
    selectedDate: '',
    isModalOpen: false,
    isGroupModalOpen: false,
    filteredTransactions: []
  });
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [estimatedValues, setEstimatedValues] = useState({});
  const params = useParams();

  const { userId, transactions, searchTerm, selectedDate, isModalOpen, isGroupModalOpen, filteredTransactions } = state;

  console.log(userId);

  useEffect(() => {
    const db = getDatabase();
    const estimatedValuesRef = ref(db, `users/${userId}/estimatedValues`);
    const unsubscribe = onValue(estimatedValuesRef, (snapshot) => {
      const data = snapshot.val();
      const newEstimatedValues = {};
      Object.keys(data || {}).forEach(category => {
        Object.keys(data[category] || {}).forEach(month => {
          const monthIndex = parseInt(month, 10) - 1;
          newEstimatedValues[`${category}_${monthIndex}`] = data[category][month].estimated;
        });
      });
      setEstimatedValues(newEstimatedValues);
    });
  
    return () => {
      unsubscribe();
    };
  }, [userId]);
  const handleStateChange = useCallback((changes) => {
    setState(prevState => ({ ...prevState, ...changes }));
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      handleStateChange({ userId: params.clientId ?params.clientId:  user.uid  });
      if (user) {
        setLoadingComplete(true);
      }
    });
    return () => unsubscribe();
  }, [handleStateChange]);
  useEffect(() => {
    if (loadingComplete && !userId) {
      toast({
        type: 'error',
        text: 'Por favor, faça login para visualizar as transações.',
        duration: 3000,
        position: 'top-right'
      });
    }
  }, [loadingComplete, userId]);



  useEffect(() => {
    if (!userId) return undefined;
    const db = getDatabase();
    const transactionsRef = ref(db, `users/${userId}/transactions`);
    const unsubscribe = onValue(transactionsRef, (snapshot) => {
      const data = snapshot.val();
      const loadedTransactions = Object.keys(data || {}).map((key) => {
        const transaction = data[key];
        transaction.price = transaction.price.toString();
        if (transaction.estimated) {
          transaction.estimated = transaction.estimated.toString();
        }
        return transaction;
      });


      handleStateChange({ transactions: loadedTransactions });
    });
    return () => { unsubscribe(); };
  }, [userId, handleStateChange]);


  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = transactions.filter(
      transaction =>
        transaction?.description?.toLowerCase().includes(lowerCaseSearchTerm) ||
        transaction.price.toString().includes(lowerCaseSearchTerm) ||
        transaction.type.toLowerCase().includes(lowerCaseSearchTerm)
    );
    handleStateChange({ filteredTransactions: filtered });
  }, [searchTerm, transactions, handleStateChange]);

  const navigate = useNavigate()

  const mainStyles = {
    width: '100%',
    margin: '1rem auto 0',
    maxHeight: '800px',
    padding: '0 1.5rem',
  };
  return (
    <div style={mainStyles}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.575rem' }}>
        <CustomButton variant="custom" onClick={() => navigate('/manage-budget')}>
          Editar Estrutura de orçamento
        </CustomButton>
      </div>
      <Grid container spacing={1}>
      <Grid item xs={12} md={3}>
          <CategorySummary transactions={transactions} estimatedValues={estimatedValues} userIdParam={userId} />
        </Grid>
        <Grid item xs={12} md={9}>
          {userId ? <TransactionsTable transactions={transactions} userId={userId} /> : <p>Por favor, faça login na aplicação.</p>}
        </Grid>
      </Grid>
      <StackButton />
    </div>
  );
}

export default TransactionsPage;
