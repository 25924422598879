import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, getAuth, sendEmailVerification} from 'firebase/auth';
import { getDatabase, ref, set, get, child, push } from 'firebase/database';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Alert, Snackbar } from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import { delay, getCurrentDate } from '../../../utils/formatTime';
import './register.css'
import CustomButton from '../../../components/button/CustomButton';
import { useLoader } from '../../../context/LoaderContext';
import toast from '../../../utils/toast';
import { useFirebaseError } from '../../../hooks/useFirebaseError';

const generateID = () => `_${  Math.random().toString(36).substr(2, 9)}`;

const defaultData = {
  "Receitas": {
    isVisible: true,
    groups: {
      [generateID()]: {
        name: "Receita fixa",
        isVisible: true,
        categories: {
          [generateID()]: {
            name: "Salário1",
            isVisible: true
          }
        }
      },
      [generateID()]: {
        name: "Receita Variável",
        isVisible: true,
        categories: {
          [generateID()]: {
            name: "Variável 1",
            isVisible: true
          }
        }
      },
      [generateID()]: {
        name: "pontual",
        isVisible: true,
        categories: {
          [generateID()]: {
            name: "Bônus",
            isVisible: true
          }
        }
      },
      [generateID()]: {
        name: "Outros",
        isVisible: true,
        categories: {}
      }
    }
  },
  "Despesas": {
    isVisible: true,
    groups: {
      [generateID()]: {
        name: "Moradia",
        isVisible: true,
        categories: {
          [generateID()]: {
            name: "Aluguel",
            isVisible: true
          }
        }
      },
      [generateID()]: {
        name: "Transporte",
        isVisible: true,
        categories: {
          [generateID()]: {
            name: "Gasolina",
            isVisible: true
          }
        }
      }
    }
  },
  "Investimentos": {
    isVisible: true,
    groups: {
      [generateID()]: {
        name: "Investimentos",
        isVisible: true,
        categories: {
          [generateID()]: {
            name: "Cofre Ascenda",
            isVisible: true
          }
        }
      }
    }
  }
};


export default function RegisterForm() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const [dateOfBirth, setDateOfBirth] = useState('');
  const { showLoader, hideLoader } = useLoader();
  const {friendlyError, translateError} = useFirebaseError()

  const encodeAsFirebaseKey = (string) => string.replace(/\./g, ',')
                 .replace(/#/g, '!')
                 .replace(/\$/g, '^')
                 .replace(/\[/g, '(')
                 .replace(/\]/g, ')');
  
  
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const isEmailFromDomain = (email, domain) => email.endsWith(domain);

    try {
      showLoader("Estamos finalizando seu cadastro...")
      await delay(300)
      const auth = getAuth();
      const database = getDatabase();
      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        throw new Error({code: 'auth/email-already-exists'});
      }
  
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const {user} = userCredential;
        await delay(1500)
        if (user) {
          const encodedEmail = encodeAsFirebaseKey(email);

          const userRole = isEmailFromDomain(email, '@ascendavoce.com.br') ? 'admin' : 'cliente';

          await set(ref(database, `users/${user.uid}`), {
            name,
            email: encodedEmail,
            role: userRole,
            premium: 'not',
            id: user.uid,
            finance: (defaultData),
            createdDate: getCurrentDate()
          });

          await createInitialZeroTransactions(user.uid, defaultData);

          // await setDefaultFinanceData(database,user.uid);
          await sendEmailVerification(user)
          .then(() => {
            delay(500);
            toast({
              type: 'success',
              text: 'Usuário cadastrado. Verifique sua caixa de e-mail.',
            })
          })
          .catch((error) => {
            translateError(error)
            toast({
              type: 'error',
              text: friendlyError,
              duration: 4000
            })
          })
          await delay(3000);
          navigate('/login');
        }
      } catch (error) {
        translateError(error)
        toast({
          type: 'error',
          text: friendlyError,
          duration: 4000
        })
      }
    } catch (error) {
      translateError(error);
      toast({
        type: 'error',
        text: friendlyError
      })
    } finally {
      hideLoader()
    }
  };

  const createInitialZeroTransactions = async (userId, financeData) => {
    const db = getDatabase();
    const transactionsRef = ref(db, `users/${userId}/transactions`);
  
    Object.entries(financeData).forEach(([categoryKey, category]) => {
      if (category.groups) {
        Object.entries(category.groups).forEach(([groupKey, group]) => {
          if (group.categories) {
            Object.entries(group.categories).forEach(([categoryKey, _]) => {
              const transaction = {
                description: "Inicialização",
                price: 0,
                group: groupKey,
                category: categoryKey,
                type: categoryKey.startsWith("Receitas") ? "income" : "outcome",
                date: getCurrentDate(),
              };
              set(push(transactionsRef), transaction);
            });
          }
        });
      }
    });
  };
  
  

  const checkEmailExists = async (email) => {
    const database = getDatabase();
    const usersRef = ref(database, 'users');
    const encodedEmail = encodeAsFirebaseKey(email);
    const snapshot = await get(child(usersRef, encodedEmail));
  
    return snapshot.exists();
  };
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setMessage('');
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
    const match = cleanedPhoneNumber.match(/^(\d{2})(\d{5})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber;
  };

  const handlePhoneChange = (event) => {
    const inputPhone = event.target.value;
    const formattedPhone = formatPhoneNumber(inputPhone);
    setPhone(formattedPhone);
  };
  

  return (
    <>
      <Stack spacing={3}>
        <TextField
          id="name"
          variant="outlined"
          label="Nome"
          fullWidth
          value={name}
          onChange={handleNameChange}
          required
        />
        <TextField
          name="email"
          label="Email"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          required
        />

        <TextField
          name="phone"
          label="Telefone"
          fullWidth
          value={phone}
          onChange={handlePhoneChange}
          required
        />

        <TextField
          name="dateOfBirth"
          label="Data de Nascimento"
          type="date"
          fullWidth
          value={dateOfBirth}
          onChange={(event) => setDateOfBirth(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />


        <TextField
          name="password"
          label="Senha"
          value={password}
          type={showPassword ? 'text' : 'password'}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
        />
      </Stack>

      <CustomButton
        fullWidth
        sx={{marginTop: 3}}
        size="large"
        type="submit"
        variant={!email || !name || !password || !phone || !dateOfBirth? "disabled": "primary"}
        onClick={handleFormSubmit}
        loading={loading}
      >
        Cadastrar
      </CustomButton>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert variant="filled" onClose={handleCloseSnackbar} severity={message.startsWith('Usuário') ? "success" : "error"}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
