import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, push, update } from "firebase/database";
import { Table, Button, TextField, IconButton, Tooltip, Grid, Typography } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Save as SaveIcon, Edit as EditIcon, Add as AddIcon, Clear as ClearIcon } from '@mui/icons-material';
import BoxCard from '../components/BoxCards';
import { fCurrencyAlternative } from '../utils/formatNumber';
import CustomContainer from '../components/CustomContainer';
import toast from '../utils/toast';

const MovementPage = () => {
  const [balance, setBalance] = useState(0);
  const [movements, setMovements] = useState([]);
  const [newMovement, setNewMovement] = useState(null);
  const [userId, setUserId] = useState(null);
  const [editingMovementIndex, setEditingMovementIndex] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [originalValueSent, setOriginalValueSent] = useState(null);


  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const calculateValues = () => {
    const investments = transactions.filter(item => item.type === "Investment");

    const totalInvestedThisYear = investments
      .filter(item => new Date(item.date).getFullYear() === new Date().getFullYear())
      .reduce((acc, curr) => acc + parseFloat(curr.price), 0);

    const totalInvested = investments
      .reduce((acc, curr) => acc + parseFloat(curr.price), 0);

    const monthlyAverage = totalInvested / (new Date().getMonth() + 1);

    return {
      monthlyAverage,
      totalInvestedThisYear,
      totalInvested
    };
  };

  const { monthlyAverage, totalInvestedThisYear, totalInvested } = calculateValues();


  useEffect(() => {
    if (!userId) return;

    const fetchData = async () => {
      const db = getDatabase();
      const investmentSummaryRef = ref(db, `users/${userId}/investmentSummary`);
      const investmentSummarySnapshot = await get(investmentSummaryRef);

      if (investmentSummarySnapshot.exists()) {
        const { balance } = investmentSummarySnapshot.val();
        setBalance(balance);
      }

      const movementsRef = ref(db, `users/${userId}/movements`);
      const movementsSnapshot = await get(movementsRef);

      if (movementsSnapshot.exists()) {
        setMovements(Object.values(movementsSnapshot.val()));

        const transactionsRef = ref(db, `users/${userId}/transactions`);
        const transactionsSnapshot = await get(transactionsRef);

        if (transactionsSnapshot.exists()) {
          setTransactions(Object.values(transactionsSnapshot.val()));
        }
      }
    };

    fetchData();
  }, [userId]);

  const handleAddMovement = async () => {
    if (newMovement.valueSent > balance) {
      toast({ type: 'error', text: 'Saldo insuficiente!' });
      return;
    }

    try {
      const db = getDatabase();
      const movementsRef = ref(db, `users/${userId}/movements`);
      const newMovementKey = push(movementsRef).key;
      await update(ref(db, `users/${userId}/movements/${newMovementKey}`), {
        ...newMovement,
        id: newMovementKey,
        aportNumber: movements.length + 1,
        editable: true
      });

      const investmentSummaryRef = ref(db, `users/${userId}/investmentSummary`);
      const investmentSummarySnapshot = await get(investmentSummaryRef);
      const { totalDirected } = investmentSummarySnapshot.val();
      const updatedTotalDirected = totalDirected + parseFloat(newMovement.valueSent);
      const updatedBalance = balance - newMovement.valueSent;

      await update(investmentSummaryRef, {
        balance: updatedBalance,
        totalDirected: updatedTotalDirected
      });

      setBalance(updatedBalance);
      setMovements([...movements, { ...newMovement, editable: true }]);
      setNewMovement(null);
      toast({ type: 'success', text: 'Movimento adicionado com sucesso!' });
    } catch (error) {
      console.log(error);
      toast({ type: 'error', text: 'Erro ao adicionar movimento. Tente novamente.' });
    }
  };

  const startEditing = (index) => {
    setOriginalValueSent(movements[index].valueSent);
    setEditingMovementIndex(index);
  };


  const saveEditedMovement = async (index) => {
    try {
      const db = getDatabase();
      const movementToEdit = movements[index];

      // Atualizar o movimento no banco de dados
      await update(ref(db, `users/${userId}/movements/${movementToEdit.id}`), movementToEdit);

      console.log(movementToEdit.valueSent, originalValueSent);

      // Atualizar balance e totalDirected
      const investmentSummaryRef = ref(db, `users/${userId}/investmentSummary`);
      const investmentSummarySnapshot = await get(investmentSummaryRef);
      const { balance: currentBalance, totalDirected: currentTotalDirected } = investmentSummarySnapshot.val();

      let updatedBalance = currentBalance;
      let updatedTotalDirected = currentTotalDirected;

      if (movementToEdit.valueSent > originalValueSent) {
        updatedBalance -= (movementToEdit.valueSent - originalValueSent);
        updatedTotalDirected += (movementToEdit.valueSent - originalValueSent);
      } else if (movementToEdit.valueSent < originalValueSent) {
        updatedBalance += (originalValueSent - movementToEdit.valueSent);
        updatedTotalDirected -= (originalValueSent - movementToEdit.valueSent);
      }
      await update(investmentSummaryRef, {
        balance: updatedBalance,
        totalDirected: updatedTotalDirected
      });

      setBalance(updatedBalance);
      setEditingMovementIndex(null);
      setOriginalValueSent(null); // Reset o valor original após a edição
      toast({ type: 'success', text: 'Movimento atualizado com sucesso!' });
    } catch (error) {
      console.log(error);
      toast({ type: 'error', text: 'Erro ao atualizar movimento. Tente novamente.' });
    }
  };


  return (
    <CustomContainer>
      <Grid container spacing={3} sx={{ height: '100vh' }}>
        <Grid item xs={3} sx={{ height: '100vh' }}>
          <div style={{ height: '40vh' }}>
            <BoxCard userId={userId} />
          </div>
          <div style={{ bordeRadius: '0.625rem', background: '#000', height: '50vh', pading: '1rem' }}>
            <Typography sx={{ fontSize: '1.2rem', color: '#8CE446' }}>Resumo</Typography>
            <Typography sx={{ fontSize: '0.983rem', color: '#E8E8E8', fontWeight: 'bold' }}>Média poupada mensalmente</Typography>
            <Typography sx={{ fontSize: '0.885rem', color: '#9A9AC2' }}>- R$ {fCurrencyAlternative(monthlyAverage)}</Typography>

            <Typography sx={{ fontSize: '0.983rem', color: '#E8E8E8', fontWeight: 'bold' }}>Total no ano</Typography>
            <Typography sx={{ fontSize: '0.885rem', color: '#9A9AC2' }}>- R$ {fCurrencyAlternative(totalInvestedThisYear)} já foram poupados este ano</Typography>

            <Typography sx={{ fontSize: '0.983rem', color: '#E8E8E8', fontWeight: 'bold' }}>Total até aqui</Typography>
            <Typography sx={{ fontSize: '0.885rem', color: '#9A9AC2' }}>- Você poupou R$ {fCurrencyAlternative(totalInvested)} até aqui</Typography>
          </div>
        </Grid>
        <Grid item xs={9} sx={{ height: '100vh' }} >
          <Typography sx={{ fontSize: '1.3375rem', color: '#8CE446' }}>Movimentações do Cofre Ascenda</Typography>
          <Table sx={{width: '100%'}}>
            <thead>
              <tr>
                <th>Data da Movimentação</th>
                <th>Valor Enviado</th>
                <th>Número do Aporte</th>
                <th>Observação</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {newMovement && (
                <tr>
                  <td align='center'>
                    <TextField
                      type="date"
                      value={newMovement.date}
                      onChange={(e) => setNewMovement({ ...newMovement, date: e.target.value })}
                    />
                  </td>
                  <td align='center'>
                    <TextField
                      sx={{ color: '#FF0062' }}
                      value={fCurrencyAlternative(newMovement.valueSent)}
                      onChange={(e) => {
                        const unformattedValue = parseFloat(e.target.value.replace(/[^0-9.-]+/g, ""));
                        setNewMovement({ ...newMovement, valueSent: unformattedValue });
                      }}
                    />
                  </td>
                  <td align='center'>{movements.length + 1}</td>
                  <td align='center'>
                    <TextField
                      value={newMovement.observation}
                      onChange={(e) => setNewMovement({ ...newMovement, observation: e.target.value })}
                    />
                  </td>
                  <td align='center'>
                    <Tooltip title="Salvar Movimento">
                      <IconButton onClick={handleAddMovement}>
                        <SaveIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancelar">
                      <IconButton onClick={() => setNewMovement(null)}>
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              )}
              {movements.map((movement, index) => (
                <tr key={index}>
                  <td align='center'>
                    {editingMovementIndex === index ? (
                      <TextField
                        value={movement.date}
                        onChange={(e) => {
                          const updatedMovements = [...movements];
                          updatedMovements[index].date = e.target.value;
                          setMovements(updatedMovements);
                        }}
                      />
                    ) : (
                      movement.date
                    )}
                  </td>
                  <td align='center'>
                    {editingMovementIndex === index ? (
                      <TextField
                        value={fCurrencyAlternative(movement.valueSent)}
                        onChange={(e) => {
                          const unformattedValue = parseFloat(e.target.value.replace(/[^0-9.-]+/g, ""));
                          const updatedMovements = [...movements];
                          updatedMovements[index].valueSent = unformattedValue;
                          setMovements(updatedMovements);
                        }}
                      />
                    ) : (
                      <Typography sx={{ color: '#FF0062', fontSize: '0.625rem' }}>
                        {fCurrencyAlternative(movement.valueSent)}
                      </Typography>
                    )}
                  </td>
                  <td align='center'>{movement.aportNumber}</td>
                  <td align='center'>
                    {editingMovementIndex === index ? (
                      <TextField
                        value={movement.observation}
                        onChange={(e) => {
                          const updatedMovements = [...movements];
                          updatedMovements[index].observation = e.target.value;
                          setMovements(updatedMovements);
                        }}
                      />
                    ) : (
                      movement.observation
                    )}
                  </td>
                  <td align='center'>
                    {editingMovementIndex === index ? (
                      <Tooltip title="Salvar">
                        <IconButton onClick={() => saveEditedMovement(index)}>
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      movement.editable && (
                        <Tooltip title="Editar">
                          <IconButton onClick={() => startEditing(index)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      )
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!newMovement && (
           <IconButton
           sx={{
             position: 'absolute',
             bottom: '5rem',
             right: '4rem',
             backgroundColor: 'primary.main',
             color: 'white',
             '&:hover': {
               backgroundColor: 'primary.dark'
             }
           }}
           onClick={() => setNewMovement({
             date: '',
             valueSent: '',
             aportNumber: '',
             observation: ''
           })}
         >
           <AddIcon />
         </IconButton>
          )}
        </Grid>
      </Grid>
    </CustomContainer>
  );
};

export default MovementPage;
