import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, Box } from '@mui/material';
import { set, ref, get } from 'firebase/database';

import { fCurrency, unformatedValue } from '../../utils/formatNumber';
import { auth, database } from '../../services/firebase';
import toast from '../../utils/toast';
import { recalculateGoalsBasedofName } from '../../utils/resolvers';
import CustomButton from '../button/CustomButton';
import MyTextField from '../text-field/MyTextField';
import SvgColor from '../svg-color/SvgColor';

export default function NewChildrenEducationSection({ onDataSaved }) {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [userId, setUserId] = useState(clientId || null);
  const [haveChildren, setHaveChildren] = useState('');
  const [childCost, setChildCost] = useState('');
  const [childTimeframe, setChildTimeframe] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);
  const [registeredContributions, setRegisteredContributions] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const userIdToUse = clientId || (user && user.uid);
      if (userIdToUse) {
        setUserId(userIdToUse);
        const userGoalsRef = ref(database, `users/${userIdToUse}/goals/educacaoFilhos`);
        const goalsSnapshot = await get(userGoalsRef);
        if (goalsSnapshot.exists()) {
          const goalsData = goalsSnapshot.val();
          setHaveChildren(goalsData.haveChildren || '');
          setChildCost(goalsData.childCost || '');
          setChildTimeframe(goalsData.childTimeframe || '');
          setIsEmpty(
            !goalsData.haveChildren ||
              (goalsData.haveChildren === 'yes' && (!goalsData.childCost || !goalsData.childTimeframe))
          );
          setRegisteredContributions(goalsData.investments?.registeredContributions || []);
        }
      }
    });
    return () => unsubscribe();
  }, [clientId]);

  const handleChildCostChange = (e) => {
    setChildCost(unformatedValue(e.target.value));
    updateIsEmpty();
  };

  const handleChildTimeframeChange = (e) => {
    setChildTimeframe(e.target.value);
    updateIsEmpty();
  };

  const updateIsEmpty = useCallback(() => {
    setIsEmpty(!haveChildren || (haveChildren === 'yes' && (!childCost || !childTimeframe)));
  }, [haveChildren, childCost, childTimeframe]);

  const handleSave = async () => {
    if (!userId) return;
    try {
      const userGoalsRef = ref(database, `users/${userId}/goals/educacaoFilhos`);
      await set(userGoalsRef, {
        haveChildren,
        childCost,
        childTimeframe,
        investments: {
          registeredContributions,
        },
      });
      await recalculateGoalsBasedofName('educacaoFilhos', userId);
      toast({
        type: 'success',
        text: 'Dados de educação dos filhos salvos com sucesso!',
      });
      onDataSaved();
    } catch (error) {
      toast({
        type: 'error',
        text: 'Erro ao salvar dados de educação dos filhos',
      });
    }
  };

  const formattedChildCost = fCurrency(childCost);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <SvgColor src="/assets/images/goals/childrenEducation.svg" sx={{ width: '10rem', height: '10rem' }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Typography
            sx={{
              fontSize: '1.5625rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 1.7,
            }}
          >
            Educação dos filhos
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
            Alguns dos gastos comuns relacionados à educação dos filhos incluem mensalidades escolares, materiais
            didáticos, atividades extracurriculares e, no futuro, as despesas universitárias. Esses custos podem variar
            dependendo do tipo de escola e das ambições educacionais que você tem para seus filhos. Gostaria de saber
            qual é a sua percepção e estimação em relação a esses gastos. Considerando todos esses fatores, qual valor
            você acredita que um filho poderá custar mensalmente?
          </Typography>
          <MyTextField
            type="text"
            value={formattedChildCost}
            onChange={handleChildCostChange}
            variant="outlined"
            margin="normal"
            fullWidth
            InputLabelProps={{
              sx: {
                fontSize: '1.3rem',
                color: '#E8E8E8',
              },
            }}
            required
          />
          <Typography variant="body1">E em relação ao prazo, qual sua expectativa?</Typography>
          <MyTextField
            type="number"
            value={childTimeframe || ''}
            onChange={handleChildTimeframeChange}
            variant="outlined"
            margin="normal"
            fullWidth
            required
          />
          <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '1rem' }}>
            <CustomButton variant="custom" sx={{ width: '12rem', marginRight: '1rem' }} onClick={() => navigate(-1)}>
              Voltar
            </CustomButton>
            <CustomButton variant="primary" sx={{ width: '12rem' }} onClick={handleSave}>
              Salvar
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
