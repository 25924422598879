import React, { useEffect, useState, useRef } from 'react';
import { IconButton, Box } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
import { useReactToPrint } from 'react-to-print';
import html2pdf from 'html2pdf.js';
import SvgColor from './svg-color/SvgColor';
import BigFiveResults from './BigFiveResults';
import Achievements from './Achievements';
import PdfCover from './PdfCover';
import { useUser } from '../context/UserContext';

const GeneratePDFButton = () => {
  const { role, currentUser } = useUser();
  const [bigFiveResults, setBigFiveResults] = useState(null);
  const [achievements, setAchievements] = useState(null);
  const [assets, setAssets] = useState(null);
  const [loggedInUserId, setLoggedInUserId] = useState(null);
  const [clientName, setClientName] = useState('document');
  const componentRef = useRef();
  const coverRef = useRef();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedInUserId(user.uid);
      } else {
        setLoggedInUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchClientName = async (id) => {
      const db = getDatabase();
      const clientRef = ref(db, `users/${id}`);
      try {
        const clientSnapshot = await get(clientRef);
        if (clientSnapshot.exists()) {
          const clientData = clientSnapshot.val();
          setClientName(clientData.name || 'document');
        } else {
          console.error('No client name found for this user.');
        }
      } catch (error) {
        console.error('Error fetching client name:', error);
      }
    };

    const fetchBigFiveResultsAndAchievements = async (id) => {
      const db = getDatabase();
      const bigFiveRef = ref(db, `users/${id}/profile/bigFiveResults`);
      const achievementsRef = ref(db, `users/${id}/achievements`);
      const assetsRef = ref(db, `users/${id}/assets`);
      try {
        const [bigFiveSnapshot, achievementsSnapshot, assetsSnapshot] = await Promise.all([
          get(bigFiveRef),
          get(achievementsRef),
          get(assetsRef),
        ]);
        if (bigFiveSnapshot.exists()) {
          setBigFiveResults(bigFiveSnapshot.val());
        } else {
          console.error('No Big Five results found for this user.');
        }
        if (achievementsSnapshot.exists()) {
          setAchievements(achievementsSnapshot.val());
        } else {
          console.error('No achievements found for this user.');
        }
        if (assetsSnapshot.exists()) {
          setAssets(assetsSnapshot.val());
        } else {
          console.error('No assets found for this user.');
        }
      } catch (error) {
        console.error('Error fetching Big Five results or achievements:', error);
      }
    };

    const userIdToFetch = role === 'admin' ? currentUser : loggedInUserId;
    if (userIdToFetch) {
      fetchClientName(userIdToFetch);
      fetchBigFiveResultsAndAchievements(userIdToFetch);
    }
  }, [role, currentUser, loggedInUserId]);

  const handleDownload = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => componentRef.current,
    removeAfterPrint: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName('html')[0];
        console.log(html);
        const options = {
          margin: [0.5, 0.5, 0.5, 0.5],
          filename: `${clientName}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        };
        html2pdf().from(html).set(options).save();
      }
    },
  });

  return (
    <>
      <style>
        {`
          @media print {
            .page-break {
              page-break-after: always;
              break-after: always;
            }
          }
        `}
      </style>
      <Box sx={{ display: 'none' }}>
        <div ref={coverRef} className="cover-page">
          <PdfCover />
        </div>
        <div ref={componentRef} className="content-page">
          {bigFiveResults && <BigFiveResults results={bigFiveResults} chartWidth={450} chartHeight={450} />}
          <Achievements achievements={achievements} />
        </div>
      </Box>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
        <IconButton variant="contained" onClick={handleDownload} sx={{ margin: '1rem 0' }}>
          <SvgColor src="/assets/icons/pdf-icon.svg" color="black" sx={{ width: '4rem', height: '4rem' }} />
        </IconButton>
      </div>
    </>
  );
};

export default GeneratePDFButton;
