import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import { Menu } from '@mui/icons-material/';
import { ThemeToggleContext } from '../theme';

const StyledHeader = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'relative',
  padding: 0,
  zIndex: 1000,
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(2, 2, 0),
  },
}));

const SpacedContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '0 auto',
}));

const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const LogoText = styled('span')(({ theme }) => ({
  fontFamily: 'Afrah, sans-serif',
  fontSize: '1.5rem',
  fontWeight: 700,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(1),
}));

export default function HeaderComponent({ handleDrawerOpen }) {
  const themeContext = useContext(ThemeToggleContext);
  const themeMode = themeContext?.mode;
  const logoSrc = themeMode === 'light' ? '/assets/icons/wealthmap-branco.png' : '/assets/icons/wealthmap.png';

  return (
    <>
      <StyledHeader>
        <SpacedContainer>
          <IconButton sx={{ marginLeft: '3px' }} edge="start" color="#00" aria-label="menu" onClick={handleDrawerOpen}>
            <Menu color="primary" />
          </IconButton>

          <LogoContainer>
            <LogoText>wealthmap.</LogoText>
            <img src={logoSrc} alt="Wealthmap logo" style={{ width: '65px' }} />
          </LogoContainer>
        </SpacedContainer>
      </StyledHeader>
    </>
  );
}

HeaderComponent.propTypes = {
  handleDrawerOpen: PropTypes.func.isRequired,
};
