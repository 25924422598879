import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import { ref, get } from 'firebase/database';
import { auth, database } from '../../services/firebase';

const OverallProgress = () => {
  const [overallProgress, setOverallProgress] = useState(0);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        calculateOverallProgress(user.uid);
      }
    });

    const calculateOverallProgress = async (userId) => {
      try {
        const userGoalsRef = ref(database, `users/${userId}/goals`);
        const snapshot = await get(userGoalsRef);
        const userGoals = snapshot.val();

        let overallTargetValue = 0;
        let overallInvestmentValue = 0;

        Object.values(userGoals).forEach((goalData) => {
          if (goalData.investments?.targetValue && !Number.isNaN(+goalData.investments?.targetValue)) {
            overallTargetValue += Number(goalData.investments?.targetValue);

            if (goalData.investments && goalData.investments.registeredContributions && !goalData.investments.registeredContributions.isNaN) {
              const contributions = goalData.investments.registeredContributions;

              contributions.forEach((contribution) => {
                overallInvestmentValue += parseFloat(contribution.investmentValue);
              });
            }
          }
        });

        let progress = 0;
        if (overallTargetValue > 0) {
          progress = (overallInvestmentValue / overallTargetValue) * 100;
        }

        setOverallProgress(progress || 0);
      } catch (error) {
        console.error('Error calculating overall progress:', error);
      }
    };

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return (
    <div>
      <LinearProgress variant="determinate" value={overallProgress} />
      <p>Progresso geral dos objetivos: {overallProgress.toFixed(2)}%</p>
    </div>
  );
};

export default OverallProgress;
