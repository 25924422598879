import React from 'react';
import { RadioGroup, FormControlLabel, Radio, Typography, Box } from '@mui/material';

function Question({ index, question, selectedAnswer, onAnswerChange }) {
  return (
    <div style={{marginBottom: '0.750rem'}}>
      <Typography sx={{ marginBottom: '-1px', fontSize: '1.5rem', fontWeight: 700, fontFamily: 'DM Sans' }}>{index + 1}. {question.text}</Typography>
      <RadioGroup
        value={selectedAnswer || ''}
        onChange={event => onAnswerChange(question.id, event.target.value)}
      >
        {question.choices.map((choice, i) => (
          <FormControlLabel
            key={i}
            value={String(choice.score)}
            control={<Radio color="primary" />}
            label={<Typography sx={{ fontSize: '1.1rem', fontFamily: 'DM Sans' }}>{choice.text}</Typography>}
            sx={{ marginBottom: '-4px'}}
          />
        ))}
      </RadioGroup>
    </div>
  );
}

export default Question;
