export default function CustomProgressBar({ value, color, width }) {
  return (
    <div style={{
      backgroundColor: 'rgba(17, 17, 20, 1)',
      height: '0.675rem',
      borderRadius: '0.675rem',
      width: width || '100%',
      overflow: 'hidden'
    }}>
      <div style={{
        backgroundColor: color || '#8CE446',
        height: '100%',
        width: `${value}%`,
        transition: 'width 0.3s ease'
      }} />
    </div>
  );
}
