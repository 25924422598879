import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import PropTypes from 'prop-types';
import GoalsList from './GoalList';
import GoalsForm from './GoalsForm';
import StandardLayout from '../../layouts/standard/StandardLayout';
import { auth, database } from '../../services/firebase';

export default function GoalManager({ changeTab, initialObjective, userIdParams }) {
  const [objectiveName, setObjectiveName] = useState(initialObjective || null);
  const [currentGoalData, setCurrentGoalData] = useState(null); // Estado para armazenar os dados do objetivo atual
  const [goalsData, setGoalsData] = useState({
    emergencyFund: {},
    retirement: {},
    homePurchase: {},
    childrenEducation: {},
    car: {},
    travelLeisure: {},
    debt: '',
    succession: '',
    otherGoals: {},
    customGoals: [],
  });

  const handleDataSaved = () => {
    // Lógica para voltar à lista completa de objetivos
    setObjectiveName(null);
  };

  useEffect(() => {
    const userId = userIdParams || auth.currentUser.uid;
    const userGoalsRef = ref(database, `users/${userId}/goals`);
    const unsubscribe = onValue(userGoalsRef, (snapshot) => {
      if (snapshot.exists()) {
        const fetchedGoalsData = snapshot.val();
        setGoalsData({
          emergencyFund: fetchedGoalsData.reservaEmergência || {},
          retirement: fetchedGoalsData.aposentadoria || {},
          homePurchase: fetchedGoalsData.compraImovel || {},
          childrenEducation: fetchedGoalsData.educacaoFilhos || {},
          car: fetchedGoalsData.car || {},
          travelLeisure: fetchedGoalsData.viagensLazer || {},
          debt: fetchedGoalsData.debt || '',
          succession: fetchedGoalsData.succession || '',
          otherGoals: fetchedGoalsData.outrosObjetivos || {},
          customGoals: fetchedGoalsData.customGoals || [],
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, [userIdParams]);

  const handleSelectObjective = (objectiveName) => {
    // Converte customGoals de objeto para array
    const customGoalsArray = Object.keys(goalsData.customGoals).map((key) => ({
      id: key,
      ...goalsData.customGoals[key],
    }));

    // Encontra o objetivo customizado pelo nome
    const customGoal = customGoalsArray.find((goal) => goal.name === objectiveName);

    if (customGoal) {
      // Se é um customGoal, prepara os dados para serem editados
      setObjectiveName(customGoal.name);
      setCurrentGoalData(customGoal); // Atualiza os dados do objetivo selecionado
    } else {
      // Para objetivos normais
      setObjectiveName(objectiveName);
      setCurrentGoalData(null); // Limpa os dados se não for um customGoal
    }
  };

  return (
    <StandardLayout
      leftComponent={
        <GoalsList
          onSelectObjective={handleSelectObjective}
          goalsData={goalsData}
          selectedObjective={objectiveName}
          onNextTab={changeTab}
        />
      }
      rightComponent={
        <GoalsForm
          objectiveName={objectiveName}
          onNextTab={changeTab}
          goalsDataForm={goalsData}
          userIdParams={userIdParams}
          onDataSaved={handleDataSaved}
          currentGoalData={currentGoalData}
        />
      }
      propMargin={objectiveName === null ? '500px' : null}
    />
  );
}

GoalManager.propTypes = {
  changeTab: PropTypes.func.isRequired,
  initialObjective: PropTypes.string,
  userIdParams: PropTypes.string,
};

GoalManager.defaultProps = {
  initialObjective: 'emergencyFund',
  userIdParams: null,
};
