import { useState, useEffect } from 'react';
import { getDatabase, ref, get, child } from 'firebase/database';
import toast from '../utils/toast';

function useFetchUserDetails(userId) {
  const [financialData, setFinancialData] = useState({});
  const [patrimonioData, setPatrimonioData] = useState({});
  const [goalsData, setGoalsData] = useState({});

  const [name, setName] = useState('');

  useEffect(() => {
    if (!userId) return;
    const db = getDatabase();
    const goalsDataRef = ref(db, `users/${userId}/goals`);
    const financialDataRef = ref(db, `users/${userId}/financial`);
    const patrimonioDataRef = ref(db, `users/${userId}/patrimonio`);
    const nameUSerRef = ref(db, `users/${userId}/name`);
    Promise.all([
      get(child(goalsDataRef, '/')),
      get(child(financialDataRef, '/')),
      get(child(patrimonioDataRef, '/')),
      get(child(nameUSerRef, '/'))
    ]).then(([goalsSnapshot, financialSnapshot, patrimonioSnapshot, nameSnapshot]) => {
      if (goalsSnapshot.exists()) {
        setGoalsData(goalsSnapshot.val());
      } else {
        toast({
          type: 'error',
          text: 'Objetivos não cadastrados'
        });
      }

      if (financialSnapshot.exists()) {
        setFinancialData(financialSnapshot.val());
      } else {
        toast({
          type: 'error',
          text: 'Dados financeiros não cadastrados'
        });
      }

      if (patrimonioSnapshot.exists()) {
        setPatrimonioData(patrimonioSnapshot.val());
      } else {
        toast({
          type: 'error',
          text: 'Dados do patrimônio não cadastrados'
        });
      }
      if (nameSnapshot.exists()) {
        setName(nameSnapshot.val());
      } else {
        toast({
          type: 'error',
          text: 'Nome do usuário não encontrado'
        });
      }
    });
  }, [userId]);

  const totalRevenue = (financialData.annualRevenue + parseFloat(financialData.otherRevenues || 0));
  const totalPatrimonio = parseFloat(patrimonioData.investments || 0) +
    parseFloat(patrimonioData.realEstate || 0) +
    parseFloat(patrimonioData.vehicles || 0);

  const totalGoalsAmount = parseFloat(goalsData.aposentadoria?.retirementAmount || 0) +
    parseFloat(goalsData.compraImovel?.propertyValue || 0) +
    parseFloat(goalsData.educacaoFilhos?.childCost || 0) +
    parseFloat(goalsData.outrosObjetivos?.projectValue || 0) +
    parseFloat(goalsData.reservaEmergência?.value || 0) +
    parseFloat(goalsData.viagensLazer?.cost || 0);

    return { financialData, patrimonioData, goalsData, name, totalRevenue, totalPatrimonio, totalGoalsAmount };
}

export default useFetchUserDetails;
