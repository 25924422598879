// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {getDatabase} from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAi-JngjCp28Yx0ZHQ9KYTCVy1qsYu7edU',
  authDomain: 'projeto-aplicativo-01.firebaseapp.com',
  projectId: 'projeto-aplicativo-01',
  storageBucket: 'projeto-aplicativo-01.appspot.com',
  messagingSenderId: '57368784971',
  appId: '1:57368784971:web:2b65989931c45ba5235dce',
  measurementId: 'G-6NR9JMFH4P'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const database = getDatabase();
export default app;
