import React from 'react';
import { Box, Typography } from '@mui/material';

const PdfCover = React.forwardRef((props, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        backgroundImage: 'url(/assets/illustrations/back.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: 'white',
        textAlign: 'center',
        padding: 0,
        margin: 0,
        pageBreakAfter: 'always',
      }}
    >
      <Typography variant="h1" gutterBottom>
        Ascenda
      </Typography>
      <Typography variant="h4">Wealth Management</Typography>
    </Box>
  );
});

export default PdfCover;
