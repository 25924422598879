import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Grid,
  Link as MuiLink,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { getDatabase, ref, set, get, update } from 'firebase/database';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useUser } from '../context/UserContext';
import CustomButton from '../components/button/CustomButton';
import { ThemeToggleContext } from '../theme';
import CustomContainer from '../components/CustomContainer';
import toast from '../utils/toast';

const initialFormState = {
  nome: '',
  sobrenome: '',
  sexo: '',
  cpf: '',
  celular: '',
  nascimento: '',
  cep: '',
  rua: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  estado: '',
};

const ClientRegisterPage = () => {
  const [formState, setFormState] = useState(initialFormState);
  const [isEditMode, setIsEditMode] = useState(false);
  const { clientId } = useParams();
  const navigate = useNavigate();
  const { setMode } = useContext(ThemeToggleContext);
  const { role, currentUser } = useUser();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    setMode('light');
    return () => {
      setMode('dark');
    };
  }, [setMode]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (role === 'admin') {
          setUserId(currentUser);
        } else {
          setUserId(user.uid);
        }
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, [role, currentUser]);

  useEffect(() => {
    if (userId) {
      fetchClientName();
    }
  }, [userId]);

  const fetchClientName = async () => {
    const database = getDatabase();
    const userRef = ref(database, `users/${userId}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      const clientData = snapshot.val();
      setFormState((prevState) => ({
        ...prevState,
        nome: clientData.name || '',
      }));
    }
  };

  useEffect(() => {
    if (clientId || userId) {
      fetchClientData(clientId);
      setIsEditMode(true);
    }
  }, [clientId, userId]);

  const fetchClientData = async (id) => {
    const database = getDatabase();
    const clientRef = ref(database, `users/${userId}/clients`);
    const snapshot = await get(clientRef);
    if (snapshot.exists()) {
      setFormState(snapshot.val());
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const database = getDatabase();
    const clientRef = ref(database, `users/${userId}/clients}`);
    try {
      if (isEditMode) {
        await update(clientRef, formState);
        toast({ type: 'success', text: 'Cliente atualizado com sucesso!', duration: 3000 });
      } else {
        await set(clientRef, formState);
        toast({ type: 'success', text: 'Cliente cadastrado com sucesso!', duration: 3000 });
      }
      navigate('/some-path'); // Navegar para uma página de sucesso ou a lista de clientes, se aplicável.
    } catch (error) {
      toast({ type: 'error', text: 'Erro ao atualizar o cliente!', duration: 3000 });
    }
  };

  return (
    <CustomContainer sx={{ p: 1 }}>
      <Typography variant="h3" gutterBottom>
        {isEditMode ? 'Editar Cliente' : 'Cadastrar Cliente'}
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body1">
          <MuiLink component={Link} to={`/big-five-results`} underline="hover">
            Refaça seu perfil comportamental
          </MuiLink>
        </Typography>
        <Typography variant="body1">
          <MuiLink component={Link} to={`/financial-data/${clientId || userId}`} underline="hover">
            Atualize seu salário base
          </MuiLink>
        </Typography>
        <Typography variant="body1">
          <MuiLink component={Link} to={`/home?tab=objetivos&hash=${clientId || userId}`} underline="hover">
            Atualize seus objetivos
          </MuiLink>
        </Typography>
      </Box>
      <Typography variant="h6" gutterBottom>
        Olá {formState.nome}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Nome"
              name="nome"
              value={formState.nome}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Sobrenome"
              name="sobrenome"
              value={formState.sobrenome}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Sexo</InputLabel>
              <Select
                label="Sexo"
                name="sexo"
                value={formState.sexo}
                onChange={handleInputChange}
                sx={{
                  backgroundColor: '#FFF',
                  color: '#000',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },
                  '.MuiSelect-icon': {
                    color: '#000',
                  },
                }}
              >
                <MenuItem value="Masculino">Masculino</MenuItem>
                <MenuItem value="Feminino">Feminino</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="CPF" name="cpf" value={formState.cpf} onChange={handleInputChange} required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Celular"
              name="celular"
              value={formState.celular}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Data de Nascimento"
              name="nascimento"
              type="date"
              value={formState.nascimento}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="CEP" name="cep" value={formState.cep} onChange={handleInputChange} required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Rua" name="rua" value={formState.rua} onChange={handleInputChange} required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Número"
              name="numero"
              value={formState.numero}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Complemento"
              name="complemento"
              value={formState.complemento}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Bairro"
              name="bairro"
              value={formState.bairro}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Cidade"
              name="cidade"
              value={formState.cidade}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Estado"
              name="estado"
              value={formState.estado}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CustomButton type="submit">Atualizar meu cadastro</CustomButton>
          </Grid>
        </Grid>
      </form>
    </CustomContainer>
  );
};

export default ClientRegisterPage;
