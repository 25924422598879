import React from 'react';
import { Box, Grid, Button, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import CustomButton from '../components/button/CustomButton';

const PersonalityTestIntro = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleNextPage = () => {
    navigate('/home');
  };

  const BackgroundOverlay = styled('div')(({ theme }) => ({
    position: 'relative',
    height: '100vh', // Ajustado para ocupar toda a altura da viewport
    width: '100%', // Ajustado para ocupar toda a largura
    padding: 16,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -200,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: 'url(assets/illustrations/graphic.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: -1,
    },
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  }));

  return (
    <BackgroundOverlay>
      <Grid
        container
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            maxWidth: '400px',
          }}
        >
          <Typography sx={{ mb: 2, fontFamily: 'TimesNow-Bold', fontSize: '1.3675rem', color: 'white' }}>
            Leia com atenção!
          </Typography>
          <Typography sx={{ my: 2, fontFamily: 'DM Sans', fontSize: '1.3675rem' }} gutterBottom>
            Oi, eu sou a Dini, inteligência artificial da Ascenda Investimentos. Nós precisamos nos conhecer melhor.
            Responda meu teste de personalidade, assim eu posso traçar seu perfil e recomendar investimentos com mais
            certeza.
          </Typography>
          <Typography sx={{ my: 2, fontFamily: 'DM Sans', fontSize: '1.3675rem' }}>
            O seu perfil também vai me ajudar a auxiliar no seu planejamento financeiro. Lembre-se, além de mim sempre
            haverá um humano para atendê-la aqui na Ascenda.
          </Typography>
          <Typography sx={{ my: 2, fontFamily: 'DM Sans', fontSize: '1.3675rem' }}>
            O questionário de personalidade leva cerca de 20 minutinhos apenas, por favor, faça-o em silêncio e
            concentrado. Não há resposta correta.
          </Typography>
          <Typography sx={{ my: 2, fontFamily: 'DM Sans', fontSize: '1.3675rem' }}>
            Este teste é científico, chama-se Big Five. Ele é baseado em um estudo contínuo realizado desde 1992 e está
            integrado diretamente com uma API sob a licença do MIT (Massachusetts Institute of Technology).
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            minHeight: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: 64,
              minHeight: '60%',
            }}
          >
            <CustomButton onClick={handleNextPage} sx={{ mb: 1, width: '230px', height: '60px' }}>
              Iniciar
            </CustomButton>
            <CustomButton sx={{ mb: 1, width: '230px', height: '60px' }} variant="custom" onClick={handleGoBack}>
              Voltar
            </CustomButton>
          </div>
          {/* </div> */}
        </Grid>
      </Grid>
    </BackgroundOverlay>
  );
};

export default PersonalityTestIntro;
