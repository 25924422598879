import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { ref, get } from 'firebase/database';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { auth, database } from '../../services/firebase';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const [visibleItems, setVisibleItems] = React.useState([]);

  React.useEffect(() => {
    const fetchVisibleItems = async (userId) => {
      const visibleItems = await Promise.all(
        data.map(async (item) => {
          const { role } = item;

          if (role) {
            try {
                const userRef = ref(database, `users/${userId}`);
                const userSnapshot = await get(userRef);

                if (userSnapshot.exists() && userSnapshot.val().role !== 'admin') {
                  return null;
                }
            } catch (error) {
              console.error(error);
              return null;
            }
          }

          return item;
        })
      );

      setVisibleItems(visibleItems.filter(Boolean));
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchVisibleItems(user.uid);
      } else {
        console.log('No user is signed in');
      }
    });

    return unsubscribe;
  }, [data]);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {visibleItems.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
