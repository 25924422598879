import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import CustomButton from '../../../button/CustomButton';

const NoInvestmentsModal = ({ open, onClose }) => (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent"
        }
    
      }}
    >
      <Box
        sx={{
          top: '50%',
          left: '50%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          borderRadius: '0.625rem',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          width: 400,
          height: 130,
          backgroundColor: '#E8E8E8',
          color: '#6027FF',
          padding: 20
        }}
      >
        <Typography  sx={{ fontSize: '0.9375rem', fontWeight: 500}}id="simple-modal-title">Este objetivo ainda não foi iniciado</Typography>
        <Typography sx={{ fontSize: '1.875rem', fontWeight: 500}} id="simple-modal-description">Vamos inicia-lo!</Typography>
        <CustomButton sx={{ alignSelf: 'flex-end', marginRight: '0.5rem', marginBottom: '0.250rem' }} variant='secondary'onClick={onClose}>OK</CustomButton>
      </Box>
    </Modal>
  );

export default NoInvestmentsModal;
