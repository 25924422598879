// hooks/useUserData.js
import { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, update, remove } from 'firebase/database';
import toast from '../utils/toast';

export function useUserData() {
  const [clientUsers, setClientUsers] = useState([]);

  
  useEffect(() => {
    const database = getDatabase();
    const usersRef = ref(database, 'users');

    const handleSnapshot = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const clientUsers = Object.values(data)
          .filter((user) => user.role === 'cliente')
          .map((user) => ({
            id: user.id,
            name: user.name,
            email: user.email.replace(/,/g, '.'),
            role: user.role,
            premium: user.premium,
            category: user.category,
            isGoal: !!user.goals,
            date: user.createdDate,
            faseApp: user?.faseApp ? 'Concluído' : 'Sem investimentos direcionados'
          }));
        setClientUsers(clientUsers);
      }
    };

    const unsubscribe = onValue(usersRef, handleSnapshot);

    return () => {
      // Limpar a inscrição quando o componente for desmontado
      unsubscribe();
    };
  }, []);

  const updateUser = async (userId, updatedFields) => {
    const database = getDatabase();
    const userRef = ref(database, `users/${userId}`);
    try {
      await update(userRef, updatedFields);
      toast({
        text: 'Registro atualizado com sucesso!',
        type: 'success',
        duration: 6000
      });
      return { success: true };
    } catch (error) {
      console.error(error);
      toast({
        text: 'Houve um erro',
        type: 'error'
      });
      return { success: false, error };
    }
  };

  const deleteUser = async (userId) => {
    const database = getDatabase();
    const userRef = ref(database, `users/${userId}`);
    try {
      await remove(userRef);
      toast({
        text: 'Usuário excluído com sucesso!',
        type: 'success'
      });
      return { success: true };
    } catch (error) {
      console.error(error);
      toast({
        text: 'Houve um erro',
        type: 'error'
      });
      return { success: false, error };
    }
  };

  return {
    clientUsers,
    updateUser,
    deleteUser,
  };
}
