import React, { useEffect, useState, useCallback, useContext } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ref, get, getDatabase } from 'firebase/database';

import EditIcon from '@mui/icons-material/Edit';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Grid, useTheme, Stack, LinearProgress, IconButton, Box } from '@mui/material';

import dayjs from 'dayjs'; // Biblioteca para manipulação de datas
import { recalculateGoalsBasedofName } from '../../../utils/resolvers';
import CustomButton from '../../button/CustomButton';
import { mapGoalsName, translateGoalsName } from '../../../utils/constants';
import { fCurrency } from '../../../utils/formatNumber';
import { auth, database } from '../../../services/firebase';
import ReturnsTable from './ReturnsTable';
import { calculateYearsUntil } from '../../../utils/formatTime';
import SvgColor from '../../svg-color/SvgColor';
import { ThemeToggleContext } from '../../../theme';
import NoInvestmentsModal from './view/NoInvestmentsModal';
import toast from '../../../utils/toast';

const InvestmentDetails = () => {
  const { goalName, clientId } = useParams();

  const [goalDetails, setGoalDetails] = useState(null);
  const [clientDetails, setClientDetails] = useState(null);
  const [inflationRate, setInflationRate] = useState(null);
  const [ascendaReturn, setAscendaReturn] = useState(null);
  const [monthlyIncome, setMonthlyIncome] = useState(0); // Novo estado para renda média mensal
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const [isCustomGoal, setIsCustomGoal] = useState(false);
  const [customGoalKey, setCustomGoalKey] = useState(null);
  const [clientName, setClientName] = useState('');

  const { setMode } = useContext(ThemeToggleContext);

  const navigate = useNavigate();
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setMode('light');
    return () => {
      setMode('dark');
    };
  }, [setMode]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchGoalDetails(user.uid);
        fetchClientDetails(user.uid); // Fetch client details
        fetchInflationRate(); // Fetch inflation rate
        fetchAscendaRate();
        fetchMonthlyIncome(user.uid); // Fetch monthly income
      } else {
        console.log('No user is signed in.');
      }
    });
    return () => unsubscribe();
  }, [goalName, clientId]);

  const fetchGoalDetails = useCallback(
    async (userAdminIdLogged) => {
      try {
        await recalculateGoalsBasedofName(goalName, clientId);
        const user = ref(database, `users/${userAdminIdLogged}`);
        const snapshotUser = await get(user);
        if (snapshotUser.exists()) {
          const userData = snapshotUser.val();
          const isPremium = userData.premium === 'yes';
          const isAdmin = userData.role === 'admin';
          setIsAdminUser(isAdmin);
          setIsPremiumUser(isPremium);
        }

        const userId = clientId;
        const userGoalsRef = ref(database, `users/${userId}/goals`);
        const snapshot = await get(userGoalsRef);

        if (snapshot.exists()) {
          const goalsData = snapshot.val();
          let goal = goalsData[goalName];

          if (!goal && goalsData.customGoals) {
            const customGoalKey = Object.keys(goalsData.customGoals).find(
              (key) => goalsData.customGoals[key].name === goalName
            );
            goal = customGoalKey ? goalsData.customGoals[customGoalKey] : undefined;
            setIsCustomGoal(!!customGoalKey);
            setCustomGoalKey(customGoalKey);
          } else {
            setIsCustomGoal(false);
          }

          if (goal) {
            if (
              !goal?.investments?.registeredContributions ||
              goal?.investments?.registeredContributions.length === 0
            ) {
              setShowModal(true);
            }
            setGoalDetails(goal);

            // Verificar se o objetivo está concluído
            const totalContributions = goal.investments?.registeredContributions?.reduce(
              (acc, curr) => acc + parseFloat(curr.ValorLiquido),
              0
            );
            const targetValue =
              goalName === 'reservaEmergência' ? goal?.value : parseFloat(goal?.investments?.targetValue) || 0;
            const progress = (totalContributions / targetValue) * 100;
            if (progress >= 100) {
              toast({
                type: 'success',
                text: `Parabéns! Você concluiu o objetivo.`,
                duration: 10000,
              });
            }
          } else {
            console.log(`Goal "${goalName}" not found.`);
          }
        } else {
          console.log('No goals found for the user.');
        }
      } catch (error) {
        console.error('Error fetching investment details:', error);
      }
    },
    [clientId, goalName]
  );

  const fetchClientDetails = useCallback(
    async (userAdminIdLogged) => {
      try {
        const userId = clientId || userAdminIdLogged;
        const database = getDatabase();
        const clientRef = ref(database, `users/${userId}/clients`);
        const snapshot = await get(clientRef);
        if (snapshot.exists()) {
          setClientDetails(snapshot.val());
          fetchClientName(userId);
        } else {
          console.log('Client details not found.');
        }
      } catch (error) {
        console.error('Error fetching client details:', error);
      }
    },
    [clientId]
  );

  const fetchInflationRate = useCallback(async () => {
    try {
      const inflationRef = ref(database, 'admin/inflationRate');
      const snapshot = await get(inflationRef);
      if (snapshot.exists()) {
        setInflationRate(parseFloat(snapshot.val().replace(',', '.')));
      } else {
        setInflationRate(0);
      }
    } catch (error) {
      console.error('Error fetching inflation rate:', error);
      setInflationRate(0);
    }
  }, []);

  const fetchAscendaRate = useCallback(async () => {
    try {
      const inflationRef = ref(database, 'admin/monthlyInterestNominal');
      const snapshot = await get(inflationRef);
      if (snapshot.exists()) {
        setAscendaReturn(parseFloat(snapshot.val().replace(',', '.')));
      } else {
        setAscendaReturn(0);
      }
    } catch (error) {
      console.error('Error fetching inflation rate:', error);
      setAscendaReturn(0);
    }
  }, []);

  const fetchClientName = useCallback(async (userId) => {
    try {
      const database = getDatabase();
      const userRef = ref(database, `users/${userId}`);
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        const userData = snapshot.val();
        setClientName(userData.name || '');
      } else {
        console.log('Client name not found.');
      }
    } catch (error) {
      console.error('Error fetching client name:', error);
    }
  }, []);

  const fetchMonthlyIncome = useCallback(
    async (userAdminIdLogged) => {
      try {
        const userId = clientId || userAdminIdLogged;
        const incomeRef = ref(database, `users/${userId}/financial/monthlyIncome`);
        const snapshot = await get(incomeRef);

        if (snapshot.exists()) {
          setMonthlyIncome(parseFloat(snapshot.val()));
        } else {
          setMonthlyIncome(0);
        }
      } catch (error) {
        console.error('Error fetching monthly income:', error);
        setMonthlyIncome(0);
      }
    },
    [clientId]
  );

  const calculateCorrectedSalary = (salary, years, inflationRate) => {
    return salary * (1 + inflationRate / 100) ** years;
  };

  const investments = goalDetails?.investments?.registeredContributions || [];
  const meta = goalName !== 'reservaEmergência' ? goalDetails?.investments?.targetValue || 0 : goalDetails?.value;
  const requiredContributions = goalDetails?.investments?.requiredContributions || '0';
  const investmentDataByType = investments.reduce((data, investment) => {
    const { investmentType } = investment;
    const existingData = data[investmentType] || { investmentValue: 0, investmentReturns: 0 };
    return {
      ...data,
      [investmentType]: {
        investmentValue: existingData.investmentValue + Number(investment.ValorLiquido),
      },
    };
  }, {});
  const date = goalDetails?.targetDate;

  const formatCurrency = (value) =>
    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  const totalInvestmentValue = Object.values(investmentDataByType)
    .reduce((total, data) => total + data.investmentValue, 0)
    .toFixed(2);
  const percentageGoal = meta ? ((totalInvestmentValue / meta) * 100).toFixed(2) : 0;

  const handleChangeGoal = () => {
    if (goalName === 'aposentadoria') {
      navigate(`/new-retirement/${clientId}`);
    } else if (goalName === 'compraImovel') {
      navigate(`/new-home-purchase/${clientId}`);
    } else if (goalName === 'educacaoFilhos') {
      navigate(`/new-children-education/${clientId}`);
    } else if (isCustomGoal) {
      navigate(`/edit-custom-goal/${customGoalKey}/${clientId}`);
    } else {
      navigate(`/home?tab=Objetivos&objective=${translateGoalsName[goalName]}&hash=${clientId}`);
    }
  };

  const calculateAge = (birthdate) => {
    const birthDate = dayjs(birthdate);
    return dayjs().diff(birthDate, 'year');
  };

  const salary = goalDetails?.retirementAmount || 0;
  const yearsUntilRetirement = goalDetails?.retirementYears;
  const correctedSalary = inflationRate ? calculateCorrectedSalary(salary, yearsUntilRetirement, inflationRate) : null;
  const correctMonthlyIncome = inflationRate
    ? calculateCorrectedSalary(monthlyIncome, yearsUntilRetirement, inflationRate)
    : null;
  const correctMeta = meta;

  const handleGoBack = () => {
    navigate(-1); // Navigate back in the browser history
  };

  const mapYears = {
    compraImovel: 'yearsToBuyProperty',
    aposentadoria: 'retirementYears',
    educacaoFilhos: 'childTimeframe',
  };

  const yearsField = mapYears[goalName];
  const yearsUntilGoal = goalDetails ? goalDetails[yearsField] : 0;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          padding: theme.spacing(2),
          marginRight: theme.spacing(6),
        }}
      >
        <Box
          sx={{
            width: '25%',
            height: '1rem',
            margin: 1,
            marginTop: 0,
            marginBottom: 5,
          }}
        >
          <Typography textAlign="center" variant="h6">
            Carteira {isCustomGoal ? goalName : mapGoalsName[goalName]} - {clientName}
          </Typography>
        </Box>
        <Grid container spacing={1} sx={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 2 }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                height: '100%',
                position: 'relative',
                ...(showModal ? { filter: 'blur(4px)' } : {}),
              }}
            >
              <Box
                sx={{
                  background: '#FFF',
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 12,
                  position: 'relative',
                  padding: theme.spacing(2),
                }}
              >
                <SvgColor
                  sx={{ color: '#000', width: '7rem', height: '7rem' }}
                  src={
                    isCustomGoal
                      ? `/assets/images/goals/addCustomGoal.svg`
                      : `/assets/images/goals/${translateGoalsName[goalName]}.svg`
                  }
                />
                <CustomButton
                  sx={{ position: 'absolute', top: 0, right: 0, padding: theme.spacing(1) }}
                  onClick={handleChangeGoal}
                >
                  <EditIcon />
                </CustomButton>
              </Box>
              <Box sx={{ width: '50%', padding: theme.spacing(1) }}>
                <LinearProgress
                  variant="determinate"
                  value={Number(percentageGoal)}
                  sx={{
                    height: '0.7rem',
                    width: '100%',
                    borderRadius: 1,
                    background: '#E6E6E6',
                    color: '#8CE446',
                  }}
                />
                <Typography sx={{ textAlign: 'center', marginTop: theme.spacing(1) }}>{percentageGoal}%</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  background: '#FFF',
                  alignItems: 'center',
                  borderRadius: 12,
                  border: '1px solid #000',
                  width: '50%',
                  padding: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
              >
                <Typography textAlign="center" sx={{ color: '#000', fontSize: '1rem' }}>
                  Valores Investidos
                </Typography>
                <Typography textAlign="center" sx={{ color: '#000', fontSize: '1.3rem' }}>
                  {totalInvestmentValue ? formatCurrency(totalInvestmentValue) : 'Calculando...'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  background: '#FFF',
                  alignItems: 'center',
                  borderRadius: 12,
                  border: '1px solid #000',
                  width: '50%',
                  padding: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
              >
                <Typography textAlign="center" sx={{ color: '#000', fontSize: '1rem' }}>
                  Valor Corrigido
                </Typography>
                <Typography textAlign="center" sx={{ color: '#000', fontSize: '1.3rem' }}>
                  {correctMeta ? formatCurrency(correctMeta) : 'Calculando...'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  background: '#FFF',
                  alignItems: 'center',
                  borderRadius: 12,
                  border: '1px solid #000',
                  width: '50%',
                  padding: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
              >
                <Typography textAlign="center" sx={{ color: '#000', fontSize: '1rem' }}>
                  {goalName === 'aposentadoria' ? 'Vou me aposentar em' : 'Prazo'}
                </Typography>
                <Typography textAlign="center" sx={{ color: '#000', fontSize: '1.3rem' }}>
                  {isCustomGoal
                    ? `${goalDetails?.timeFrame} anos`
                    : yearsUntilGoal
                    ? `${yearsUntilGoal} anos`
                    : '0 anos'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  background: '#FFF',
                  alignItems: 'center',
                  borderRadius: 12,
                  border: '1px solid #000',
                  width: '50%',
                  padding: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
              >
                <Typography textAlign="center" sx={{ color: '#000', fontSize: '1rem' }}>
                  Aportes Mensais
                </Typography>
                <Typography textAlign="center" sx={{ color: '#000', fontSize: '1.3rem' }}>
                  {fCurrency(requiredContributions)}
                </Typography>
              </Box>
              <CustomButton onClick={handleGoBack}>Voltar</CustomButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={9} xl={9} sx={{ height: '50%' }}>
            {goalName === 'aposentadoria' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(1),
                  background: '#FFF',
                  borderRadius: theme.spacing(2),
                  padding: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontSize: '0.85rem', marginBottom: '0.2rem' }}>
                      Idade: {clientDetails ? `${calculateAge(clientDetails.nascimento)} anos` : ''}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '0.85rem', marginBottom: '0.2rem' }}>
                      Aposentadoria a valores de hoje: {formatCurrency(salary || 0)}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '0.85rem', marginBottom: '0.2rem' }}>
                      Aposentadoria projetada (inflação):{' '}
                      {correctedSalary ? formatCurrency(correctedSalary) : 'Calculando...'}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '0.85rem', marginBottom: '0.2rem' }}>
                      Salário base hoje: {formatCurrency(monthlyIncome || 0)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontSize: '0.85rem', marginBottom: '0.2rem' }}>
                      Mont. necessário hoje: {meta ? formatCurrency(salary / ascendaReturn) : 'Calculando...'}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '0.85rem', marginBottom: '0.2rem' }}>
                      Mont. necessário corrigido (meta): {correctMeta ? formatCurrency(correctMeta) : 'Calculando...'}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '0.85rem', marginBottom: '0.2rem' }}>
                      Salário corrigido: {correctMonthlyIncome ? formatCurrency(correctMonthlyIncome) : 'Calculando...'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            {goalName !== 'aposentadoria' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(1),
                  background: '#FFF',
                  borderRadius: theme.spacing(2),
                  padding: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontSize: '0.85rem', marginBottom: '0.2rem' }}>
                      Idade: {clientDetails ? `${calculateAge(clientDetails.nascimento)} anos` : ''}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '0.85rem', marginBottom: '0.2rem' }}>
                      Valor do objetivo: {meta ? formatCurrency(meta) : 'Calculando...'}
                    </Typography>

                    <Typography variant="body1" sx={{ fontSize: '0.85rem', marginBottom: '0.2rem' }}>
                      Salário Base: {formatCurrency(monthlyIncome || 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
            <ReturnsTable
              investmentDataByType={investmentDataByType}
              clientId={clientId}
              goalName={goalName}
              isPremiumUser={isPremiumUser}
              isAdminUser={isAdminUser}
              goalsDetails={goalDetails}
              fetchGoalDetails={fetchGoalDetails}
              noInvestment={showModal}
              isCustomGoal={isCustomGoal}
              customGoalKey={customGoalKey}
            />
          </Grid>
        </Grid>
      </Box>
      <NoInvestmentsModal open={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};

export default InvestmentDetails;
