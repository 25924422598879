export const capitalizeFirstLetter = (string) => {
  const words = string.split(' ');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
};

export function getFirstName(fullName) {
  return (fullName.split(' ')[0]);
}

export function getFirstLetter(fullName) {
  return fullName.charAt(0).toUpperCase();
}

export const mapGoalsName = {
  aposentadoria: 'Aposentadoria',
  compraImovel: 'Imóvel',
  divida: 'Dívida',
  educacaoFilhos: 'Ensino Fundamental dos Filhos',
  educacaoUniversitaria: 'Faculdade dos Filhos',
  outrosObjetivos: 'Outros objetivos',
  viagensLazer: 'Viagens e Lazer',
  reservaEmergência: 'Reserva de Emergência'
};


export const formatPhoneNumber = (phoneNumber) => {
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
  const match = cleanedPhoneNumber.match(/^(\d{2})(\d{5})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phoneNumber;
};

