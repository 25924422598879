import React, { useState, useEffect, useRef } from 'react';
import { Box,  Typography, Container } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, set, get } from 'firebase/database';
import { useLocation, useNavigate } from 'react-router-dom';
import { SwitchTransition,CSSTransition } from 'react-transition-group';
import {cleanCurrencyFormat, formatToBRL} from '../../utils/formatNumber';

import toast from '../../utils/toast';
import { useLoader } from '../../context/LoaderContext';
import CustomTextField from '../text-field/CustomTextField';

import '../../styles/fade.css'; // Importe o arquivo CSS que criaremos no próximo passo
import { delay } from '../../utils/formatTime';

import CustomButton from '../button/CustomButton';



function AssetsForm() {
  const [realEstate, setRealEstate] = useState(0);
  const [vehicles, setVehicles] = useState(0);
  const [investments, setInvestments] = useState(0);
  const [userId, setUserId] = useState(null);
  const { showLoader, hideLoader } = useLoader();

  const navigate = useNavigate();
  const location = useLocation();

  const nodeRef = useRef(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        fetchData(user.uid);
      } else {
        setUserId(null);
      }
    });
    
    return () => unsubscribe();
  }, []);

  const fetchData = async (uid) => {
    const db = getDatabase();
    const financialDataRef = ref(db, `users/${uid}/patrimonio`);
    const snapshot = await get(financialDataRef);
    if (snapshot.exists()) {
      setRealEstate(snapshot.val().realEstate || '');
      setVehicles(snapshot.val().vehicles || '');
      setInvestments(snapshot.val().investments || '');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    const db = getDatabase();
    const financialDataRef = ref(db, `users/${userId}/patrimonio`);
    await set(financialDataRef, {
      realEstate, 
      vehicles, 
      investments
    });
    toast({
      type: 'success',
      text: 'Dados registrados com sucesso!'
    });
    await delay(1000);
    
    navigate('/inicio')
    // showLoader();
    
  } catch(e) {
    toast({
      type: 'error',
      text: 'Erro ao cadastrar os dados!'
    });
    hideLoader();
  } finally {
    // hideLoader();
  }
  };

  const areFieldsComplete = () => realEstate > 0 && vehicles > 0 && investments > 0;
  

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={location.pathname} // use a chave única para cada rota
        nodeRef={nodeRef}
        addEndListener={(done) => {
          nodeRef.current.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
        timeout={500}
      >
        <Container 
          maxWidth="sm"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '0.5rem'
          }}
        >
          <Box mb={2}>
            <Typography
            sx={{
                fontSize: '1.5625rem',
                fontStyle: 'normal',
                fontFamily: 'DM Sans',
                fontWeight: 400,
                lineHeight: 1.7
              }}
              textAlign="center"
            >
              Momento Financeiro
            </Typography>
            <Typography 
              sx={{
                fontSize: '1.5625rem',
                fontStyle: 'normal',
                fontFamily: 'DM Sans',
                fontWeight: 400,
                lineHeight: 1.7
              }}
              textAlign="center">Estimativa de patrimônio atual</Typography>
          </Box>

          <form onSubmit={handleSubmit} style={{width: '100%'}}>
            <Box display="flex" flexDirection="column" gap={2} width="100%"> 
              <CustomTextField 
                label="Imóveis" 
                variant="outlined"
                labelColor='#E8E8E8'
                value={formatToBRL(realEstate)} 
                onChange={e => setRealEstate(cleanCurrencyFormat(e.target.value))}
              />

              <CustomTextField 
              label="Automóveis" 
              fullWidth 
              variant="outlined"
              labelColor='#E8E8E8'
              value={formatToBRL(vehicles)} 
              onChange={e => setVehicles(cleanCurrencyFormat(e.target.value))}
             />

            <CustomTextField
                label="Investimentos" 
                fullWidth 
                variant="outlined"
                labelColor='#E8E8E8'
                value={formatToBRL(investments)} 
                onChange={e => setInvestments(cleanCurrencyFormat(e.target.value))}
            />

              <Box sx={{width: '88%', display: 'flex', justifyContent: 'flex-end'}}>
                <CustomButton 
                  type="submit"
                  variant={!areFieldsComplete() ? "disabled" : "primary"}
                  sx={{
                    textTransform: 'none',
                    borderRadius: '1.4375rem',
                  }}
                  disabled={!areFieldsComplete()}
                >
                  Finalizar e ver meu mapa
                </CustomButton>
              </Box>
            </Box>
          </form>
        </Container>
      </CSSTransition>
    </SwitchTransition>

  );
}

export default AssetsForm;
