import React, { useEffect, useState, useContext } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box, Typography } from '@mui/material';
import { englishGoalsName } from '../../utils/constants';
import CustomButton from '../button/CustomButton';
import SvgColor from '../svg-color/SvgColor';
import { useUser } from '../../context/UserContext';

export default function GoalsList({ onSelectObjective, goalsData, selectedObjective, onNextTab }) {
  const [clientName, setClientName] = useState('');
  const { role, currentUser } = useUser();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(role === 'admin' ? currentUser : user.uid);
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, [role, currentUser]);

  useEffect(() => {
    if (userId) {
      fetchClientName(userId);
    }
  }, [userId]);

  const fetchClientName = async (id) => {
    const database = getDatabase();
    const userRef = ref(database, `users/${id}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      const clientData = snapshot.val();
      setClientName(clientData.name || '');
    }
  };

  const objectives = ['box', 'emergencyFund', 'retirement', 'homePurchase', 'childrenEducation', 'addCustomGoal'];
  const areMainGoalsSet =
    goalsData.retirement &&
    Object.keys(goalsData.retirement).length > 0 &&
    goalsData.emergencyFund &&
    Object.keys(goalsData.emergencyFund).length > 0;

  const isObjectiveSet = (objective) => goalsData[objective] && Object.keys(goalsData[objective]).length > 0;

  const displayObjectives = areMainGoalsSet
    ? [
        ...objectives,
        ...Object.keys(goalsData.customGoals || {})
          .map((key) => ({
            ...goalsData.customGoals[key],
            id: key,
          }))
          .map((goal) => goal.name),
      ]
    : ['emergencyFund', 'retirement'];

  const handleNextTab = () => {
    onNextTab();
  };

  const handleSelectObjective = (objective) => {
    onSelectObjective(objective);
  };

  if (selectedObjective) {
    const customGoalsArray = Object.keys(goalsData.customGoals).map((key) => ({
      id: key,
      ...goalsData.customGoals[key],
    }));
    const isCustomObjective = customGoalsArray.some((goal) => goal.name === selectedObjective);

    const imageSrc = isCustomObjective
      ? `/assets/images/goals/addCustomGoal.svg`
      : `/assets/images/goals/${selectedObjective}.svg`;

    const isSpecialObjective = selectedObjective === 'retirement' || selectedObjective === 'emergencyFund';
    const selectedStyle = {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    };

    const divStyle = {
      background: isSpecialObjective ? '#FFF' : selectedObjective === 'box' ? '#007BFF' : '#000',
      width: '12rem',
      height: '8rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '1rem',
      marginTop: selectedObjective === 'box' ? '3.5rem' : '0.5rem',
    };

    return (
      <div style={selectedStyle}>
        {selectedObjective !== 'box' && (
          <>
            <Box>
              <Typography
                mb={1}
                mt={2}
                style={{
                  position: 'sticky',
                  top: 0,
                  maxWidth: '33.8rem',
                  zIndex: 1,
                  fontSize: '1.4325rem',
                  fontStyle: 'normal',
                }}
              >
                Carteira de investimentos do {clientName}
              </Typography>
              <Typography
                mb={3}
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  fontSize: '1.3325rem',
                  maxWidth: '35.8rem',

                  fontStyle: 'normal',
                  fontFamily: 'TimesNow-Light',
                  fontWeight: 400,
                  lineHeight: 1,
                }}
              >
                Aqui você verá suas carteiras de investimentos. Cada objetivo financeiro representa um portfólio de
                investimentos próprio.
              </Typography>
            </Box>
          </>
        )}
        <div style={divStyle}>
          <SvgColor
            src={imageSrc}
            sx={{ color: isSpecialObjective ? '#000' : '#FFF', width: '5rem', height: '5rem' }}
          />
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {selectedObjective !== 'box' && (
        <Box>
          <Typography
            textAlign="left"
            mb={1}
            mt={2}
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              fontSize: '1.4325rem',
              fontStyle: 'normal',
            }}
          >
            Carteira de investimentos do {clientName}
          </Typography>
          <Typography
            textAlign="left"
            mb={3}
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              fontSize: '1.3325rem',
              fontStyle: 'normal',
              fontFamily: 'TimesNow-Light',
              fontWeight: 400,
              lineHeight: 1,
            }}
          >
            Aqui você verá suas carteiras de investimentos. Cada objetivo financeiro representa um portfólio de
            investimentos próprio.
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-start" gap={2} marginBottom={2}>
        <div
          style={{
            background: '#007BFF',
            width: '12rem',
            height: '8rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: '1rem',
            padding: '1rem',
          }}
        >
          <SvgColor
            sx={{ color: '#FFF', width: '5rem', height: '5rem' }}
            src="/assets/images/goals/box.svg"
            onClick={() => handleSelectObjective('box')}
          />
          <Typography mt={1} color="textPrimary">
            Box
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: '1rem',
            padding: '1rem',
          }}
        >
          <SvgColor
            sx={{ color: '#FFF', width: '5rem', height: '5rem' }}
            src="/assets/images/goals/addIconGoal.svg"
            onClick={() => handleSelectObjective('addCustomGoal')}
          />
          <Typography mt={1} color="textPrimary">
            Adicionar Objetivo Extra
          </Typography>
        </div>
      </Box>
      {chunkArray(
        displayObjectives.filter((obj) => obj !== 'box' && obj !== 'addCustomGoal'),
        4
      ).map((chunk, chunkIndex) => (
        <Box display="flex" justifyContent="space-between" gap="0.5rem" marginBottom={2} key={chunkIndex}>
          {chunk.map((objective, index) => {
            const isSpecialObjective = objective === 'retirement' || objective === 'emergencyFund';
            const isCustomGoal = !objectives.includes(objective);
            const divStyle = {
              background: isSpecialObjective ? '#FFF' : '#000',
              width: '12rem',
              height: '8rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              borderRadius: '1rem',
              padding: '0.5rem',
              border: isSpecialObjective ? 'none' : '1px solid #FFF', // Adiciona uma borda branca se não for um objetivo especial
            };
            const svgStyle = {
              color: isSpecialObjective ? '#000' : '#FFF',
              width: '5rem',
              height: '5rem',
            };

            const svgSrc = isCustomGoal
              ? `/assets/images/goals/addCustomGoal.svg`
              : `/assets/images/goals/${objective}.svg`;
            return (
              <div key={index} style={divStyle}>
                <SvgColor sx={svgStyle} src={svgSrc} onClick={() => handleSelectObjective(objective)} />
                <Typography mt={1} color="textPrimary">
                  {isCustomGoal ? objective : englishGoalsName[objective] || objective}
                </Typography>
              </div>
            );
          })}
        </Box>
      ))}

      <div style={{ textAlign: 'right', width: '100%', marginTop: '1rem' }}>
        <CustomButton
          variant={areMainGoalsSet ? 'secondary' : 'disabled'}
          onClick={areMainGoalsSet ? handleNextTab : undefined}
          disabled={!areMainGoalsSet}
        >
          Continuar para registrar Patrimônio
        </CustomButton>
      </div>
    </div>
  );
}

// Função auxiliar para dividir o array em pedaços (chunks)
function chunkArray(array, size) {
  const result = [];
  for (let i = 0, len = array.length; i < len; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}
