import React, { useEffect, useState, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';

import MapIcon from '@mui/icons-material/Map';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import HeaderComponent from '../../components/HeaderComponent';
import DrawerComponent from '../../components/Drawer';
import Logo from '../../components/logo/Logo';
import { useUser } from '../../context/UserContext';

const APP_BAR_MOBILE = 6;
const APP_BAR_DESKTOP = 12;

const LayoutContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
}));

const MainContent = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));

const ScrollbarContainer = styled(Scrollbar)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
}));

const Footer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0 2rem 2.3rem 0',
});

export default function SimpleLayout() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hasGoals, setHasGoals] = useState(false);
  const [loggedInUserId, setLoggedInUserId] = useState(null);
  const navigate = useNavigate();
  const { role, currentUser } = useUser();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedInUserId(user.uid);
        checkGoals(user.uid);
      } else {
        setLoggedInUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkGoals = async (userId) => {
    const db = getDatabase();
    const goalsRef = ref(db, `users/${userId}/goals`);
    try {
      const snapshot = await get(goalsRef);
      if (snapshot.exists()) {
        setHasGoals(true);
      } else {
        setHasGoals(false);
      }
    } catch (error) {
      console.error('Error checking goals:', error);
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMapClick = () => {
    console.log(role);
    const clientId = role === 'admin' ? currentUser : loggedInUserId;
    navigate(`/dashboard/${clientId}`);
  };

  return (
    <LayoutContainer>
      <HeaderComponent handleDrawerOpen={handleDrawerOpen} />
      <MainContent>
        <ScrollbarContainer>
          <Outlet />
        </ScrollbarContainer>
      </MainContent>
      <Footer>
        <Logo />
        {(hasGoals || role === 'admin') && (
          <MapIcon sx={{ marginLeft: '1rem', cursor: 'pointer', fontSize: '3rem' }} onClick={handleMapClick} />
        )}
      </Footer>
      <DrawerComponent drawerOpen={drawerOpen} handleDrawerClose={handleDrawerClose} />
    </LayoutContainer>
  );
}
