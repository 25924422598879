import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, Box } from '@mui/material';
import { set, ref, get } from 'firebase/database';
import { fCurrency, unformatedValue } from '../../utils/formatNumber';
import { auth, database } from '../../services/firebase';
import toast from '../../utils/toast';
import { recalculateGoalsBasedofName } from '../../utils/resolvers';
import CustomButton from '../button/CustomButton';
import SvgColor from '../svg-color/SvgColor';
import MyTextField from '../text-field/MyTextField';

export default function NewRetirementSection() {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [userId, setUserId] = useState(clientId || null);
  const [formData, setFormData] = useState({
    retirementAmount: '',
    retirementYears: '',
    isEmpty: true,
  });
  const [registeredContributions, setRegisteredContributions] = useState([]);

  useEffect(() => {
    // Obter a ID do usuário e os dados da aposentadoria
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const userIdToUse = clientId || (user && user.uid);
      if (userIdToUse) {
        setUserId(userIdToUse);
        const userGoalsRef = ref(database, `users/${userIdToUse}/goals`);
        const goalsSnapshot = await get(userGoalsRef);

        if (goalsSnapshot.exists()) {
          const goalsData = goalsSnapshot.val();
          const retirement = goalsData.aposentadoria || {};
          setFormData((prevData) => ({
            ...prevData,
            retirementAmount: retirement.retirementAmount || '',
            retirementYears: retirement.retirementYears || '',
          }));
          setRegisteredContributions(retirement.investments?.registeredContributions || []);
        }
      }
    });

    return () => unsubscribe();
  }, [clientId]);

  const handleRetirementAmountChange = (e) => {
    const { value } = e.target;
    const numericValue = Number(value.replace(/[^0-9.-]+/g, ''));
    setFormData((prevData) => ({
      ...prevData,
      retirementAmount: unformatedValue(value),
      isEmpty: numericValue === 0 || !prevData.retirementYears,
    }));
  };

  const calculateRetirementDate = (retirementYears) => {
    const currentYear = new Date().getFullYear();
    const targetYear = currentYear + parseInt(retirementYears, 10);
    return `${targetYear}-01-01`;
  };

  const handleRetirementYearsChange = (e) => {
    const { value } = e.target;
    const numericValue = formData.retirementAmount ? Number(formData.retirementAmount.replace(/[^0-9.-]+/g, '')) : 0;
    const newTargetDate = calculateRetirementDate(value);
    setFormData((prevData) => ({
      ...prevData,
      retirementYears: value,
      targetDate: newTargetDate,
      isEmpty: numericValue === 0,
    }));
  };

  const formattedRetirementValue = fCurrency(formData.retirementAmount);

  const handleSave = async () => {
    if (!userId) return;

    if (!formData.retirementAmount || !formData.retirementYears) {
      toast({
        type: 'error',
        text: 'Por favor, preencha todos os campos antes de salvar.',
        duration: 3000,
      });
      return;
    }

    try {
      const userGoalsRef = ref(database, `users/${userId}/goals/aposentadoria`);
      await set(userGoalsRef, {
        retirementAmount: formData.retirementAmount,
        retirementYears: formData.retirementYears,
        investments: {
          registeredContributions,
        },
      });
      toast({
        type: 'success',
        text: 'Dados de Aposentadoria salvos com sucesso!',
      });
      await recalculateGoalsBasedofName('aposentadoria', userId);
    } catch (error) {
      toast({
        type: 'error',
        text: 'Erro ao salvar dados de Aposentadoria:',
      });
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <SvgColor src="/assets/images/goals/retirement.svg" sx={{ width: '10rem', height: '10rem' }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Typography
            sx={{
              fontSize: '1.5625rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 1.7,
            }}
          >
            Altere sua Aposentadoria
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
            Quando você pensa sobre sua aposentadoria, que tipo de estilo de vida você imagina ter? Isso pode incluir
            suas atividades, hobbies, viagens e despesas diárias. Com base nesse estilo de vida desejado, qual seria o
            valor aproximado que você gostaria de receber mensalmente?
          </Typography>
          <Typography variant="body1">Quanto você gostaria de receber mensalmente na aposentadoria?</Typography>
          <MyTextField
            type="text"
            value={formattedRetirementValue}
            onChange={handleRetirementAmountChange}
            variant="outlined"
            fullWidth
            margin="normal"
            InputLabelProps={{
              sx: {
                fontSize: '1.3rem',
                color: '#E8E8E8',
              },
            }}
            sx={{ marginBottom: '0.575rem' }}
          />
          <Typography variant="body1">Daqui a quantos anos você planeja se aposentar?</Typography>
          <MyTextField
            type="number"
            value={formData.retirementYears || ''}
            onChange={handleRetirementYearsChange}
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              sx: {
                fontSize: '1.3rem',
                color: '#E8E8E8',
              },
            }}
            fullWidth
          />
          <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '1rem' }}>
            <CustomButton variant="custom" sx={{ width: '12rem', marginRight: '1rem' }} onClick={() => navigate(-1)}>
              Voltar
            </CustomButton>
            <CustomButton variant="primary" sx={{ width: '12rem' }} onClick={handleSave}>
              Salvar
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
