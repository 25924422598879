// @mui
import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export function bgBlur(props) {
  const color = props?.color || '#000000';
  const blur = props?.blur || 6;
  const opacity = props?.opacity || 0.8;
  const imgUrl = props?.imgUrl;

  if (imgUrl) {
    return {
      position: 'relative',
      backgroundImage: `url(${imgUrl})`,
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9,
        content: '""',
        width: '100%',
        height: '100%',
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity),
      },
    };
  }

  return {
    backdropFilter: `blur(${blur}px)`,
    WebkitBackdropFilter: `blur(${blur}px)`,
    backgroundColor: alpha(color, opacity),
  };
}

// ----------------------------------------------------------------------

export function bgGradient(props) {
  const direction = props?.direction || 'to bottom';
  const startColor = props?.startColor;
  const endColor = props?.endColor;
  const imgUrl = props?.imgUrl;
  const color = props?.color;

  if (imgUrl) {
    return {
      background: `linear-gradient(${direction}, ${startColor || color}, ${endColor || color}), url(${imgUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    };
  }

  return {
    background: `linear-gradient(${direction}, ${startColor}, ${endColor})`,
  };
}

// ----------------------------------------------------------------------

export function textGradient(value) {
  return {
    background: `-webkit-linear-gradient(${value})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };
}

// ----------------------------------------------------------------------

export function filterStyles(value) {
  return {
    filter: value,
    WebkitFilter: value,
    MozFilter: value,
  };
}

// ----------------------------------------------------------------------

export const hideScrollbarY = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

// ----------------------------------------------------------------------

export const hideScrollbarX = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowX: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export const balloonTextStyle = {
  color: '#E8E8E8',
  textAlign: 'center',
  fontFamily: 'DM Sans',
  fontSize: '0.9375rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '100.7%', // 0.94406rem
};

export const balloonContainerStyle = {
  borderRadius: '0.625rem',
  background: '#FF0062',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '50px',
  right: '10px',
  padding: '5px 10px',
};

export const balloonStyle = {
  position: 'absolute',
  bottom: '50px',
  right: '10px',
  background: '#4A4A5B',
  borderRadius: '0.625rem',
  padding: '10px 15px',
  color: '#E8E8E8',
  fontFamily: 'DM Sans',
  fontSize: '0.9375rem',
  fontWeight: 500,
  height: '4rem',
  display: 'flex',
  alignItems: 'center',
  width: '16rem',
  textAlign: 'center',
  lineHeight: '100.7%',
  zIndex: 1,
};
export const arrowStyle = {
  position: 'absolute',
  bottom: '-10px',
  right: '10px',
  width: '0',
  height: '0',
  borderTop: '10px solid #4A4A5B',
  borderLeft: '10px solid transparent',
  borderRight: '10px solid transparent',
};

export const blurStyle = {
  filter: 'blur(8px)',
  opacity: 0.9,
  background: '#FFF',
  pointerEvents: 'none',
};
