import React from 'react';
import { Box, Typography } from '@mui/material';
import BigFiveChart from './profile/bigfive/chart/BigFiveChart';

const BigFiveResults = React.forwardRef(({ results }, ref) => {
  return (
    <div ref={ref} style={{ textAlign: 'center', padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Big Five Results
      </Typography>
      {results?.map((result, index) => {
        return (
          <Box key={index} mb={4} display="flex" flexDirection="column" alignItems="center" className="page-break">
            <Box mb={2}>
              <BigFiveChart results={[result]} width={450} height={450} />
            </Box>
            <Box width="60%" textAlign="left">
              <Typography variant="h6">{result.title}</Typography>
              <Typography variant="body2" gutterBottom>
                Score: {result.score} ({result.scoreText})
              </Typography>
              {result.facets.map((facet, facetIndex) => {
                return (
                  <Box key={facetIndex} mb={2} pl={2}>
                    <Typography variant="subtitle1">{facet.title}</Typography>
                    <Typography variant="body2" gutterBottom>
                      {facet.text}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Score: {facet.score} ({facet.scoreText})
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </div>
  );
});

export default BigFiveResults;
