import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, Box } from '@mui/material';
import { set, ref, get } from 'firebase/database';
import { fCurrency, unformatedValue } from '../../utils/formatNumber';
import { auth, database } from '../../services/firebase';
import toast from '../../utils/toast';
import { recalculateGoalsBasedofName } from '../../utils/resolvers';
import CustomButton from '../button/CustomButton';
import SvgColor from '../svg-color/SvgColor';
import MyTextField from '../text-field/MyTextField';

export default function NewHomePurchaseSection({ onDataSaved }) {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [userId, setUserId] = useState(clientId || null);
  const [formData, setFormData] = useState({
    buyProperty: '',
    propertyValue: '',
    yearsToBuyProperty: '',
    isEmpty: true,
  });
  const [registeredContributions, setRegisteredContributions] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const userIdToUse = clientId || (user && user.uid);
      if (userIdToUse) {
        setUserId(userIdToUse);
        const userGoalsRef = ref(database, `users/${userIdToUse}/goals`);
        const goalsSnapshot = await get(userGoalsRef);
        if (goalsSnapshot.exists()) {
          const goalsData = goalsSnapshot.val();
          const propertyData = goalsData.compraImovel || {};
          setFormData({
            buyProperty: propertyData.buyProperty || '',
            propertyValue: propertyData.propertyValue || '',
            yearsToBuyProperty: propertyData.yearsToBuyProperty || '',
            isEmpty:
              propertyData.buyProperty === 'yes' && (!propertyData.propertyValue || !propertyData.yearsToBuyProperty),
          });
          setRegisteredContributions(propertyData.investments?.registeredContributions || []);
        }
      }
    });
    return () => unsubscribe();
  }, [clientId]);

  const handlePropertyValueChange = (e) => {
    const { value } = e.target;
    const numericValue = Number(value.replace(/[^0-9.-]+/g, ''));
    setFormData((prevData) => ({
      ...prevData,
      propertyValue: unformatedValue(value),
      isEmpty: prevData.buyProperty === 'yes' && (numericValue === 0 || !prevData.yearsToBuyProperty),
    }));
  };

  const formattedPropertyValue = fCurrency(formData.propertyValue);

  const handleYearsToBuyPropertyChange = (e) => {
    const { value } = e.target;
    const numericValue = formData.propertyValue ? Number(formData.propertyValue.replace(/[^0-9.-]+/g, '')) : 0;
    setFormData((prevData) => ({
      ...prevData,
      yearsToBuyProperty: value,
      isEmpty: prevData.buyProperty === 'yes' && (numericValue === 0 || value === ''),
    }));
  };

  const handleSave = async () => {
    if (!userId) return;

    try {
      const userGoalsRef = ref(database, `users/${userId}/goals/compraImovel`);
      await set(userGoalsRef, {
        buyProperty: formData.buyProperty,
        propertyValue: formData.propertyValue,
        yearsToBuyProperty: formData.yearsToBuyProperty,
        investments: {
          registeredContributions,
        },
      });
      await recalculateGoalsBasedofName('compraImovel', userId);
      toast({
        type: 'success',
        text: 'Dados de compra de imóvel salvos com sucesso!',
      });
      onDataSaved();
    } catch (error) {
      toast({
        type: 'error',
        text: 'Erro ao salvar dados de compra de imóvel',
      });
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <SvgColor src="/assets/images/goals/homePurchase.svg" sx={{ width: '10rem', height: '10rem' }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Typography
            sx={{
              fontSize: '1.5625rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 1.7,
            }}
          >
            Altere seu Imóvel dos sonhos
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
            Compartilhe um pouco mais desse objetivo para que possamos ajudá-lo a conquistá-lo. Qual valor você deseja
            ou tem em mente que este imóvel custa?
          </Typography>
          <MyTextField type="text" value={formattedPropertyValue} onChange={handlePropertyValueChange} required />
          <Typography variant="body1">Com quantos anos você deseja atingir esse objetivo de vida?</Typography>
          <MyTextField
            type="number"
            value={formData.yearsToBuyProperty || ''}
            onChange={handleYearsToBuyPropertyChange}
            required
          />
          <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '1rem' }}>
            <CustomButton variant="custom" sx={{ width: '12rem', marginRight: '1rem' }} onClick={() => navigate(-1)}>
              Voltar
            </CustomButton>
            <CustomButton variant="primary" sx={{ width: '12rem' }} onClick={handleSave}>
              Salvar
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
