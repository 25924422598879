import * as React from 'react';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import CustomButton from '../components/button/CustomButton';
import { capitalizeFirstLetter } from '../utils/formatText';
import { auth, database } from '../services/firebase';
import SvgColor from '../components/svg-color/SvgColor';

import Logo from '../components/logo/Logo';

export default function WelcomePage() {
  const navigate = useNavigate();

  const [userName, setUserName] = useState('');
  useEffect(() => {
    const fetchUserName = async (userId) => {
      try {
        const userRef = ref(database, `users/${userId}`);
        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userName = capitalizeFirstLetter(userData.name);
          setUserName(userName);
        }
      } catch (error) {
        console.error('Error fetching user name:', error);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserName(user.uid);
      } else {
        console.log('No user is signed in');
      }
    });

    return unsubscribe;
  }, []);

  const handleClickTest = () => {
    navigate('/introduction-profile');
  };

  const handleClickInvestment = () => {
    navigate('/home?tab=Financeiro');
  };
  return (
    <Container sx={{ height: '100vh', width: '100%' }}>
      {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <img src="/assets/icons/wealthmap.png" alt="black" style={{ width: '80px' }} />
      </div> */}
      <Grid container sx={{ height: '70vh' }} mt={1}>
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
          lg={8}
          xl={8}
          sx={{ p: { xs: 2, sm: 4, md: 6 }, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <Box sx={{ color: 'white', mb: 4 }}>
            <Typography sx={{ fontSize: '2.7rem', fontFamily: 'DM Sans' }} gutterBottom>
              Olá {userName}!
            </Typography>
            <Typography sx={{ fontSize: '2.7rem', fontFamily: 'DM Sans' }} gutterBottom>
              Bem-vindo(a) ao <span style={{ fontFamily: 'Afrah' }}>WealthMap.</span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: '1.875rem', fontFamily: 'TimesNow-Light', marginTop: '2.875rem' }}
              gutterBottom
            >
              Nosso propósito é bem simples. Transformar seus investimentos em uma história feliz!
            </Typography>
            <Typography sx={{ fontSize: '2rem', fontFamily: 'TimesNow-Bold' }} mt={22}>
              Leia com atenção!
            </Typography>
            <Typography sx={{ fontSize: '1.425rem', fontFamily: 'DM Sans' }} mt={2}>
              O WealthMap possui uma I.A
            </Typography>
            <Typography sx={{ fontSize: '1.425rem', fontFamily: 'DM Sans' }} mt={2}>
              Para que nossa I.A te ajude a fazer um planejamento, vamos precisar que responda um teste de
              personalidade.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          xl={4}
          sx={{ p: { xs: 2, sm: 4, md: 6 }, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <Stack direction="column" spacing={6}>
            <CustomButton onClick={handleClickTest} sx={{ width: '16rem' }}>
              Responder teste de personalidade.
            </CustomButton>
            <CustomButton sx={{ width: '16rem' }} onClick={handleClickInvestment} variant="custom">
              Ir direto aos investimentos.
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
