import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ref, get, } from '@firebase/database';
import ClientDashboard from '../components/dashboard/client/ClientDashboard';
import AdminDashboard from '../components/dashboard/AdminDashboard';
import { auth, database } from '../services/firebase';
import Dashboard from './Dashboard';
import ResponsiveDashboard from './ResponsiveDashboard';

export default function DashboardAppPage() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        checkAdminStatus(user.uid);
      } else {
        // No user is signed in.
        console.log('No user is signed in');
        setLoading(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const checkAdminStatus = async (userId) => {
    try {
      const roleRef = ref(database, `users/${userId}/role`);
      const snapshot = await get(roleRef);
      const role = snapshot.val();
      setIsAdmin(role === 'admin');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>AscendaApp</title>
      </Helmet>

      {isAdmin ? <AdminDashboard /> : <ResponsiveDashboard />}
    </>
  );
}
