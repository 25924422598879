import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, set, onValue } from 'firebase/database';
import React,{useState, useEffect} from 'react';
import { Container, Paper, Button, TextField, Grid, Box, Typography} from '@mui/material';
import { useTheme } from '@emotion/react';
import CircularProgressWithLabel from '../../CircularProgress';

const DependentForm = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const auth = getAuth();
  const db = getDatabase();

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (name && email && phone && birthDate) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name, email, phone, birthDate]); 

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

  return () => unsubscribe();
}, []);

  useEffect(() => {
      // Cria uma referência para o local dos dados no Firebase
      const dependentRef = ref(db, `users/${userId}/dadosDependente`);
      // Ouve por mudanças nessa localização
      const unsubscribe = onValue(dependentRef, (snapshot) => {

        console.log(snapshot.exists(), userId, dependentRef);
        const data = snapshot.val();
        if (data) {
          // Atualiza o estado local com os dados carregados do Firebase
          setName(data.name || '');
          setEmail(data.email || '');
          setPhone(data.phone || '');
          setBirthDate(data.birthDate || '');
        }
      });
      return () => unsubscribe();
    
    }, [userId, db]);

  const theme = useTheme();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userId) {
      const dependentData = {
        name,
        email,
        phone,
        birthDate
      };
      
      const dependentRef = ref(db, `users/${userId}/dadosDependente`);
      set(dependentRef, dependentData)
        .then(() => {
          console.log('Dados do dependente salvos com sucesso.');
          props.onNext();
        })
        .catch((error) => {
          console.error('Erro ao salvar os dados do dependente:', error);
        });
    }
  };


  return (
    <Container 
      sx={{ 
        margin: 0,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100vh',
        padding: '0px !important',
        minHeight: '650px'
      }}
    >
      <Paper sx={{boxShadow: theme.customShadows.z8, padding: '2rem', textAlign: 'left', width: '100%', }}>

        <Grid sx={{minHeight: '650px'}} container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <Box sx={{display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start'}}>
              <Box sx={{
                display: 'flex',
              }}>
              <Typography color="secondary" variant='h3'>Carregar segundo perfil....</Typography>
              </Box>
          </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
              <form onSubmit={handleSubmit}>
              <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                <Typography variant="h3" color="secondary">Perfil 2</Typography>
                <TextField 
                  label="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{width: '360px'}} />
                <TextField 
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{width: '360px'}}
                />
                <TextField 
                  label="Telefone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  sx={{width: '360px'}}
                />
                <TextField
                  label="Data de Nascimento"
                  type="date"
                  value={birthDate}
                  onChange={(e) => setBirthDate(e.target.value)}
                  sx={{width: '360px'}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <Box sx={{width: '360px', display: 'flex', justifyContent: 'space-between'}}>
                  <Button disabled type="submit" variant="contained" color="primary">
                    Voltar
                  </Button>
                  <Button type="submit" variant="contained" color="primary" disabled={!isFormValid}>
                    Começar
                  </Button>
                </Box>
               </Box>
              </form>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default DependentForm;
