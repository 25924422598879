import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, set, push, get, onValue } from 'firebase/database';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';


import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { formatToBRL, cleanCurrencyFormat } from '../../utils/formatNumber';
import toast from '../../utils/toast';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    boxShadow: 'none',
    backgroundColor: '#202024',
    width: '36.4375rem',
    height: '100%',
    maxHeight: '42rem'
  },
}));

const StyledForm = styled('form')({
  marginTop: '2rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

const StyledButton = styled(Button)(({ theme }) => ({
  height: '50px',
  border: 0,
  backgroundColor: '#005BDA',
  color: theme.palette.common.white,
  fontWeight: 'bold', boxShadow: 'none !important',

  padding: '0 1.25rem',
  borderRadius: '5rem',
  marginTop: '1rem',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transition: 'background-color 0.2s',
  },
}));


const typeMapping = {
  income: 'Entradas',
  outcome: 'Saídas'
};

export function NewTransactionModal({ open, onClose, initialType }) {

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setValue
  } = useForm();
  const [data, setData] = useState({});

  React.useEffect(() => {
    setValue('date', getCurrentDate());
    if (initialType) {
      setValue('type', initialType);
    }
  }, [initialType, setValue]);

  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);

  const [userId, setUserId] = React.useState(null);
  const [isInstallment, setIsInstallment] = useState(false);
  const [installments, setInstallments] = useState(1);
  const [price, setPrice] = React.useState('');

  React.useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, []);

  React.useEffect(() => {
    const loadGroups = async () => {
      try {
          const db = getDatabase();
          const financeRef = ref(db, `users/${userId}/finance`);
          const snapshot = await get(financeRef);
          const financeData = snapshot.val();
  
          if (!financeData || typeof financeData !== 'object') {
              return; 
          }
  
          // Flatten the groups from all categories (Despesas, Investimentos, Receitas)
          const allGroups = [];
          Object.entries(financeData).forEach(([categoryKey, categoryValue]) => {
              if (categoryValue.groups) {
                  allGroups.push(...Object.entries(categoryValue.groups));
              }
          });
  
          setData(financeData);
          setGroups(allGroups);
      } catch (e) {
          console.error('Erro ao buscar grupos:', e);
      }
  };
  

    loadGroups();
}, [userId]);



const handleGroupChange = async (groupId) => {
  try {
      let selectedCategories = [];
      Object.entries(data).forEach(([categoryKey, categoryValue]) => {
          if (categoryValue.groups && categoryValue.groups[groupId]) {
              selectedCategories = categoryValue.groups[groupId].categories;
          }
      });
      
      if (selectedCategories) {
          setCategories(Object.entries(selectedCategories));
      } else {
          setCategories([]);
      }
  } catch (e) {
      console.error('Erro ao buscar categorias:', e);
  }
};


  const handlePriceChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, '');
    const cleanedValue = cleanCurrencyFormat(rawValue);
    setPrice(formatToBRL(cleanedValue));
  };

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleCreateNewTransaction = async (data) => {
    const { description, group, category, type, date } = data;

    const typeMap = typeMapping[type];

    try {
      const db = getDatabase();
      const transactionsRef = ref(db, `users/${userId}/transactions`);

      if (isInstallment && installments > 1) {
        const promises = [];  // Array para armazenar todas as promessas
        for (let i = 0; i < installments; i += 1) {
          const newTransactionRef = push(transactionsRef);
          const installmentDate = new Date(date);
          installmentDate.setMonth(installmentDate.getMonth() + i);
          // Adicionar cada promessa ao array de promessas
          promises.push(
            set(newTransactionRef, {
              description,
              price: (cleanCurrencyFormat(price)) / installments,
              estimated: 0,
              group,
              category,
              type: typeMap,
              date: installmentDate.toISOString().split('T')[0],
            })
          );
        }
        // Aguardar todas as promessas de uma vez
        await Promise.all(promises);
      } else {
        const newTransactionRef = push(transactionsRef);
        await set(newTransactionRef, {
          description,
          price: (cleanCurrencyFormat(price)),
          estimated: 0,
          group,
          category,
          type: typeMap,
          date,
        });
      }

      reset();
      setPrice('');
      setIsInstallment(false)
      setInstallments(1)
      onClose();
      toast({
        type: 'success',
        text: 'Transação registrada com sucesso!'
      });
    } catch (e) {
      toast({
        type: 'error',
        text: 'Erro ao registrar a transação!'
      });
    }
  };
  return (
    <StyledDialog
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'black',
            opacity: 0.75,
          },
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Nova Transação
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ flexGrow: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <StyledForm
          onSubmit={handleSubmit(handleCreateNewTransaction)}
        >

          <TextField
            label="Data"
            type="date"
            fullWidth
            required
            {...register('date')}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Preço"
                fullWidth
                required
                value={price}
                onChange={handlePriceChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isInstallment}
                    onChange={(e) => setIsInstallment(e.target.checked)}
                    color="primary"
                  />
                }
                label="Parcelado?"
              />
            </Grid>
          </Grid>
          <FormControl fullWidth required>
            <InputLabel id="group-label">Grupo</InputLabel>
            <Controller
                name="group"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <Select
                        labelId="group-label"
                        {...field}
                        onChange={(e) => {
                            field.onChange(e);
                            handleGroupChange(e.target.value);
                        }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    backgroundColor: 'rgba(41, 41, 46, 1)',
                                    boxShadow: 'none'
                                },
                            },
                        }}
                    >
                        {groups.map(([groupId, groupData]) => (
                            <MenuItem key={groupId} value={groupId}>{groupData.name}</MenuItem>
                        ))}
                    </Select>
                )}
            />
        </FormControl>
          <FormControl fullWidth required>
            <InputLabel id="category-label">Categoria</InputLabel>
            <Controller
                name="category"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <Select
                        labelId="category-label"
                        {...field}
                    >
                        {categories.map(([categoryId, categoryData]) => (
                            <MenuItem key={categoryId} value={categoryId}>{categoryData.name}</MenuItem>
                        ))}
                    </Select>
                )}
            />
        </FormControl>

          <TextField
            label="Descrição"
            fullWidth
            required
            {...register('description')}
          />
          {isInstallment && (
            <TextField
              label="Número de Parcelas"
              type="number"
              fullWidth
              required
              value={installments}
              onChange={(e) => setInstallments(e.target.value)}
            />
          )}

          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <ToggleButtonGroup
                fullWidth
                exclusive
                value={field.value}
                onChange={field.onChange}
                sx={{
                  '& .MuiToggleButton-root': {
                    flex: 1,
                    justifyContent: 'center',
                  },
                }}
              >
                <ToggleButton value="income">
                  <ArrowUpwardIcon sx={{ color: '#00b37e' }} />
                  Entrada
                </ToggleButton>
                <ToggleButton value="outcome">
                  <ArrowDownwardIcon sx={{ color: '#f75a68' }} />
                  Saída
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          />
          <StyledButton fullWidth type="submit" disabled={isSubmitting} variant="contained">
            Cadastrar
          </StyledButton>
        </StyledForm>
      </DialogContent>
    </StyledDialog>
  );
}
