import { Container, Typography } from '@mui/material';
import CustomContainer from '../components/CustomContainer';

export default function NotImplementation() {
  return (
    <CustomContainer>
      <Typography variant="h5" mb={5}>
        Configurações
      </Typography>
      <Typography sx={{ fontSize: '1.5rem' }}>Ainda não há funcionalidades configuráveis</Typography>
    </CustomContainer>
  );
}
