import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
import { useTheme } from '@mui/material/styles';
import CustomContainer from '../components/CustomContainer';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      style={{ width: '100%' }}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ width: '100%' }}>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

export default function NavigableTabs({ tabData, color, defaultTabIndex }) {
  const theme = useTheme();

  const [value, setValue] = useState(defaultTabIndex || 0);
  const [userId, setUserId] = useState(null);
  const [tabsDisabled, setTabsDisabled] = useState(false);
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const checkFinancialData = async () => {
      const db = getDatabase();
      const financialDataRef = ref(db, `users/${userId}/patrimonio`);
      const snapshot = await get(financialDataRef);

      if (!snapshot.exists()) {
        setTabsDisabled(true);
      } else {
        setTabsDisabled(false);
      }
    };

    checkFinancialData();
  }, [userId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeTab = (newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        style={{
          borderBottom: 1,
          borderColor: 'divider',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          [theme.breakpoints.down('sm')]: {
            borderBottom: 0,
          },
        }}
      >
        <Tabs
          value={value}
          textColor={color}
          indicatorColor={color}
          onChange={handleChange}
          px
          aria-label="navigable tabs"
          sx={{
            padding: '0px !important',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {tabData.map((tab, index) => (
            <Tab
              label={tab.label}
              sx={{
                padding: '0px !important',
                width: '100%',
                fontWeight: 'bold',
                fontSize: '1rem',
                fontFamily: 'DM Sans',
                color: '#FFF',
                marginTop: '0.5rem',
              }}
              {...a11yProps(index)}
              key={index}
            />
          ))}
        </Tabs>
      </Box>
      {tabData.map((tab, index) => (
        <TabPanel
          color={color}
          sx={{ padding: '0px !important', width: '100%' }}
          value={value}
          index={index}
          key={index}
        >
          {React.cloneElement(tab.component, { changeTab: () => changeTab(index + 1) })}
        </TabPanel>
      ))}
    </div>
  );
}

NavigableTabs.propTypes = {
  tabData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  color: PropTypes.string,
  defaultTabIndex: PropTypes.number,
};
