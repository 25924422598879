import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Select, MenuItem, IconButton, Tooltip } from '@mui/material';
import { Save as SaveIcon, Clear } from '@mui/icons-material';
import { fCurrency, unformatedValue } from '../../../../../utils/formatNumber';
import { investmentCategories } from '../../../../../utils/constants';

const NewInvestmentRow = ({
  editedInvestments,
  setEditedInvestments,
  handleInvestmentSubmit,
  setAddingNewInvestment,
  setFormattedInvestmentValue,
  formattedInvestmentValueLiq,
  formattedInvestmentValue,
  setFormattedInvestmentValueLiq,
}) => (
  <tr>
    <td>
      <TextField
        className="my-custom-textfield"
        variant="standard"
        onChange={(e) => {
          const updatedInvestment = {
            investmentName: e.target.value,
          };
          setEditedInvestments([...editedInvestments, updatedInvestment]);
        }}
      />
    </td>
    <td>
      <Select
        variant="standard"
        value={editedInvestments[editedInvestments.length - 1]?.investmentType || ''}
        onChange={(e) => {
          const actionType = e.target.value;
          const lastInvestment = editedInvestments[editedInvestments.length - 1];
          lastInvestment.investmentType = actionType;
          setEditedInvestments([...editedInvestments]);
        }}
      >
        {investmentCategories.map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </td>
    <td>
      <TextField
        className="my-custom-textfield"
        sx={{ height: '30px', padyding: '0px', fontSize: '0.75rem' }}
        variant="standard"
        onChange={(e) => {
          const lastInvestment = editedInvestments[editedInvestments.length - 1];
          lastInvestment.taxaContratada = e.target.value;
          setEditedInvestments([...editedInvestments]);
        }}
      />
    </td>

    <td>
      <TextField
        className="my-custom-textfield"
        sx={{ height: '30px', padding: '0px' }}
        variant="standard"
        type="date"
        onChange={(e) => {
          const lastInvestment = editedInvestments[editedInvestments.length - 1];
          lastInvestment.dataAplicacao = e.target.value;
          setEditedInvestments([...editedInvestments]);
        }}
      />
    </td>
    <td>
      <TextField
        className="my-custom-textfield"
        variant="standard"
        type="date"
        onChange={(e) => {
          const lastInvestment = editedInvestments[editedInvestments.length - 1];
          lastInvestment.dataVencimento = e.target.value;
          setEditedInvestments([...editedInvestments]);
        }}
      />
    </td>
    <td>
      <TextField
        className="my-custom-textfield"
        variant="standard"
        value={formattedInvestmentValue}
        onChange={(e) => {
          const rawValue = unformatedValue(e.target.value);
          const updatedInvestmentValue = fCurrency(rawValue);
          setFormattedInvestmentValue(updatedInvestmentValue);
          const lastInvestment = editedInvestments[editedInvestments.length - 1];
          lastInvestment.investmentValue = rawValue; // Use o valor não formatado aqui
          setEditedInvestments([...editedInvestments]);
        }}
      />
    </td>
    <td>
      <TextField
        className="my-custom-textfield"
        variant="standard"
        onChange={(e) => {
          const lastInvestment = editedInvestments[editedInvestments.length - 1];
          lastInvestment.quantidade = e.target.value;
          setEditedInvestments([...editedInvestments]);
        }}
      />
    </td>
    <td>
      <TextField
        className="my-custom-textfield"
        variant="standard"
        value={formattedInvestmentValueLiq}
        onChange={(e) => {
          const rawValue = unformatedValue(e.target.value);
          const updatedInvestmentValue = fCurrency(rawValue);
          setFormattedInvestmentValueLiq(updatedInvestmentValue);
          const lastInvestment = editedInvestments[editedInvestments.length - 1];
          lastInvestment.ValorLiquido = rawValue; // Use o valor não formatado aqui
          setEditedInvestments([...editedInvestments]);
        }}
      />
    </td>
    <td>
      <Tooltip title="Salvar investimento">
        <IconButton
          size="small"
          color="primary"
          onClick={() => {
            const investmentToSubmit = editedInvestments[editedInvestments.length - 1];
            handleInvestmentSubmit(investmentToSubmit);
            setAddingNewInvestment(false);
          }}
        >
          <SaveIcon
            sx={{
              width: '16px',
              height: '16px',
            }}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Cancelar">
        <IconButton
          size="small"
          color="secondary"
          onClick={() => {
            setAddingNewInvestment(false);
            // Limpe os estados relacionados, se necessário
            setFormattedInvestmentValue('');
            setEditedInvestments((prev) => prev.slice(0, -1)); // Remove o último investimento editado
          }}
        >
          <Clear
            sx={{
              width: '16px',
              height: '16px',
            }}
          />
        </IconButton>
      </Tooltip>
    </td>
  </tr>
);

NewInvestmentRow.propTypes = {
  editedInvestments: PropTypes.array.isRequired,
  setEditedInvestments: PropTypes.func.isRequired,
  handleInvestmentSubmit: PropTypes.func.isRequired,
  setAddingNewInvestment: PropTypes.func.isRequired,
  setFormattedInvestmentValue: PropTypes.func.isRequired,
  formattedInvestmentValueLiq: PropTypes.string.isRequired,
  formattedInvestmentValue: PropTypes.string.isRequired,
  setFormattedInvestmentValueLiq: PropTypes.func.isRequired,
};

export default NewInvestmentRow;
