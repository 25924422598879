import React, { useState, useContext, useEffect } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { getDatabase, ref, get } from 'firebase/database';
import {
  ChevronLeft,
  ExitToApp,
  Brightness4,
  AccountCircle,
  Settings,
  Paid,
  FmdGood,
  Dashboard,
  Person,
} from '@mui/icons-material/';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { ThemeToggleContext } from '../theme';
import { useUser } from '../context/UserContext';
import toast from '../utils/toast';
import ProfileModal from './account/ProfileModal';

export default function DrawerComponent({ drawerOpen, handleDrawerClose }) {
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState({});
  const { role, currentUser } = useUser();
  const navigate = useNavigate();
  const toggleTheme = useContext(ThemeToggleContext);
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);

  const handleThemeChange = () => {
    toggleTheme();
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    fetchData(userId);
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        fetchData(user.uid);
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchData = async (uid) => {
    const database = getDatabase();
    const userRef = ref(database, `users/${uid}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      setUserData(snapshot.val());
    }
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Logout failed', error);
      });
  };

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            background: '#000',
            color: theme.palette.text.primary,
            boxSizing: 'border-box',
          },
        }}
      >
        <div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft sx={{ color: '#FFF' }} />
          </IconButton>
        </div>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ color: '#FFF' }} textAlign="center">
            {role === 'admin' ? 'Olá Admin' : `Olá ${userData.name || ''}`}
          </Typography>
        </Box>
        <Divider />
        {
          <List>
            <ListItem button onClick={() => navigate('/client-register')}>
              <ListItemIcon sx={{ color: '#FFF' }}>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText sx={{ color: '#FFF' }} primary="Cadastro do Cliente" />
            </ListItem>
            <ListItem button onClick={() => navigate(`/financial-data/${currentUser}`)}>
              <ListItemIcon sx={{ color: '#FFF' }}>
                <Paid />
              </ListItemIcon>
              <ListItemText sx={{ color: '#FFF' }} primary="Dados Fin. do Cliente" />
            </ListItem>
            <ListItem button onClick={() => navigate(`/dashboard/financial-map/${currentUser}`)}>
              <ListItemIcon sx={{ color: '#FFF' }}>
                <FmdGood />
              </ListItemIcon>
              <ListItemText sx={{ color: '#FFF' }} primary="Mapa Financeiro do Cliente" />
            </ListItem>
            <ListItem button onClick={() => navigate('/inicio')}>
              <ListItemIcon sx={{ color: '#FFF' }}>
                <Dashboard />
              </ListItemIcon>
              <ListItemText sx={{ color: '#FFF' }} primary="Dashboard do Admin" />
            </ListItem>
            <ListItem button onClick={() => navigate('/no-config')}>
              <ListItemIcon sx={{ color: '#FFF' }}>
                <Settings />
              </ListItemIcon>
              <ListItemText sx={{ color: '#FFF' }} primary="Configurações" />
            </ListItem>
            <ListItem button onClick={() => navigate('/big-five-results')}>
              <ListItemIcon sx={{ color: '#FFF' }}>
                <Person />
              </ListItemIcon>
              <ListItemText sx={{ color: '#FFF' }} primary="Perfil Comp Cliente" />
            </ListItem>
          </List>
        }
        <Box display="flex" justifyContent="space-between" padding={2}>
          <IconButton sx={{ color: '#FFF' }} onClick={handleLogout}>
            <ExitToApp />
          </IconButton>
          <Button onClick={handleThemeChange}>
            <Brightness4 />
          </Button>
        </Box>
      </Drawer>
      {/* <ProfileModal open={openModal} onClose={handleCloseModal} /> */}
    </>
  );
}
