import React, {useEffect, useState} from 'react';
import { Grid, Box, Typography , LinearProgress } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomContainer from '../components/CustomContainer';
import useFetchUserDetails from '../hooks/useFetchUserDetails';

export default function DashboardMobile() {

  const [userId, setUserId] = useState(null);

  const params = useParams();
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (params && params.clientId) {
          setUserId(params.clientId);
        } else {
          setUserId(user.uid);
        }
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();

  }, []);
  const { financialData, patrimonioData, goalsData, name, totalRevenue, totalPatrimonio, totalGoalsAmount } = useFetchUserDetails(userId);


  const goalMapName = {
    aposentadoria: "Aposentadoria",
    compraImovel: "Casa",
    educacaoFilhos: "Educação dos Filhos",
    car: 'Carro',
    outrosObjetivos: "Outros"
  };
  function calculateGoalProgress(goalData) {
    if (goalData.investments && goalData.investments.registeredContributions) {
      const totalContributions = goalData.investments.registeredContributions.reduce((acc, curr) => acc + parseFloat(curr.investmentValue), 0);
      const targetValue = parseFloat(goalData.investments.targetValue);
      return (totalContributions / targetValue) * 100;
    }
    return 0;
  }
  

  return (
    <CustomContainer>
      <Grid container spacing={{ xs: 2, lg: 4 }} sx={{ marginTop: '1rem' }}>

        <Grid item xs={12}>
          <Box position="relative">
            <Box component="img" src="/assets/images/pasta-objetivos.png" alt="objetivos" width={336} height={176} />
            {Object.entries(goalsData).map(([goalName, goalData]) => {
              const displayName = goalMapName[goalName] || goalName;
              const progress = calculateGoalProgress(goalData);
              let value = '';
              switch (goalName) {
                case 'aposentadoria':
                  value = goalData.retirementAmount;
                  break;
                case 'compraImovel':
                  value = goalData.propertyValue;
                  break;
                case 'educacaoFilhos':
                  value = goalData.childCost;
                  break;
                case 'car':
                  value = goalData.valueCar;
                  break;
                case 'outrosObjetivos':
                  value = goalData.projectValue;
                  break;
                default:
                  return null;
              }

              return (
                <Box key={goalName} sx={{ marginBottom: '1rem' }}>
                  <Typography sx={{fontSize: '0.75rem'}}>{displayName}</Typography>
                  <Typography sx={{fontSize: '0.75rem'}} variant="body2">{value}</Typography>
                  <LinearProgress variant="determinate" value={progress} />
                </Box>
              );
            })}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box component="img" src="/assets/images/pasta-receitas.png" alt="receitas" width={336} height={176} />
        </Grid>

        <Grid item xs={12}>
          <Box component="img" src="/assets/images/pasta-bens.png" alt="bens" width={336} height={176} />
        </Grid>

        <Grid item xs={12}>
          <Box component="img" src="/assets/images/pasta-despesas.png" alt="despesas" width={336} height={176} />
          <Typography variant="caption1">Esse mês você já gastou:</Typography>
          <Typography>{(financialData.averageFixedAnnualExpense / 12).toFixed(2)}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Box component="img" src="/assets/images/pasta-protecoes.png" alt="protecoes" width={336} height={176} />
        </Grid>
      </Grid>
    </CustomContainer>
  );
}
