import React, { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { West as WestIcon, LockOutlined, VisibilityOff, Visibility } from '@mui/icons-material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, get, child } from 'firebase/database';
import { Avatar, Box, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useResponsive from '../hooks/useResponsive';
import CustomContainer from '../components/CustomContainer';
import toast from '../utils/toast';
import { fCurrency, fCurrencyNumber } from '../utils/formatNumber';
import { useLoader } from '../context/LoaderContext';
import { useUser } from '../context/UserContext';
import { ThemeToggleContext } from '../theme';
import { delay } from '../utils/formatTime';
import GeneratePDFButton from '../components/GeneratePDFButton';
import SvgColor from '../components/svg-color/SvgColor';

function Dashboard() {
  const [userId, setUserId] = useState(null);
  const [financialData, setFinancialData] = useState({});
  const [patrimonioData, setPatrimonioData] = useState({});
  const [name, setName] = useState('');
  const [isVisible, setIsVisible] = useState(true);
  const { showLoader, hideLoader } = useLoader();
  const [goalsData, setGoalsData] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { role, isPremium } = useUser();
  const isMobile = useResponsive('only', 'xs');
  const { setMode } = useContext(ThemeToggleContext);
  const [assetsData, setAssetsData] = useState({});
  const [policiesData, setPoliciesData] = useState({});
  const [assetCategories, setAssetCategories] = useState({});
  const [policyCategories, setPolicyCategories] = useState({});

  useEffect(() => {
    const categories = {};

    Object.values(assetsData).forEach((asset) => {
      const category = asset.categoria;
      const valor = parseFloat(asset.valorMercado) || 0;

      if (categories[category]) {
        categories[category] += valor;
      } else {
        categories[category] = valor;
      }
    });

    setAssetCategories(categories);
  }, [assetsData]);

  useEffect(() => {
    const categoryTotals = {};

    Object.values(policiesData).forEach((policy) => {
      const category = policy.categoria;
      const valorApolice = parseFloat(policy.valorApolice) || 0;

      if (categoryTotals[category]) {
        categoryTotals[category] += valorApolice;
      } else {
        categoryTotals[category] = valorApolice;
      }
    });

    setPolicyCategories(categoryTotals);
  }, [policiesData]);

  useEffect(() => {
    setMode('light');

    return () => {
      setMode('dark');
    };
  }, [setMode]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (params && params.clientId) {
          setUserId(params.clientId);
        } else {
          setUserId(user.uid);
        }
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!isPremium && role !== 'admin') {
      toast({
        type: 'info',
        text: 'Contate um planejador Ascenda para direcionar seus investimentos.',
        duration: 6000,
        position: { vertical: 'top', horizontal: 'right' },
      });
    }
  }, [isPremium]);

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!userId) return;
    showLoader();
    const db = getDatabase();
    const goalsDataRef = ref(db, `users/${userId}/goals`);
    const financialDataRef = ref(db, `users/${userId}/financial`);
    const patrimonioDataRef = ref(db, `users/${userId}/patrimonio`);
    const nameUSerRef = ref(db, `users/${userId}/name`);
    Promise.all([
      get(child(goalsDataRef, '/')),
      get(child(financialDataRef, '/')),
      get(child(patrimonioDataRef, '/')),
      get(child(nameUSerRef, '/')),
    ])
      .then(async ([goalsSnapshot, financialSnapshot, patrimonioSnapshot, nameSnapshot]) => {
        if (goalsSnapshot.exists()) {
          setGoalsData(goalsSnapshot.val());
        } else {
          toast({
            type: 'error',
            text: 'Objetivos não cadastrados',
          });
        }

        if (financialSnapshot.exists()) {
          setFinancialData(financialSnapshot.val());
        } else {
          toast({
            type: 'error',
            text: 'Dados financeiros não cadastrados',
          });
        }

        if (patrimonioSnapshot.exists()) {
          setPatrimonioData(patrimonioSnapshot.val());
        } else {
          toast({
            type: 'error',
            text: 'Dados do patrimônio não cadastrados',
          });
        }

        if (nameSnapshot.exists()) {
          setName(nameSnapshot.val());
        } else {
          toast({
            type: 'error',
            text: 'Dados do patrimônio não cadastrados',
          });
        }

        await delay(800);
      })
      .catch(() => {
        hideLoader();
      })
      .finally(() => {
        hideLoader();
      });
  }, [userId]);

  useEffect(() => {
    if (!userId) return;
    showLoader();
    const db = getDatabase();
    const assetsRef = ref(db, `users/${userId}/assets`);
    const policiesRef = ref(db, `users/${userId}/policies`);

    Promise.all([get(assetsRef), get(policiesRef)])
      .then(([assetsSnapshot, policiesSnapshot]) => {
        if (assetsSnapshot.exists()) {
          setAssetsData(assetsSnapshot.val());
        } else {
          toast({
            type: 'error',
            text: 'Bens não cadastrados',
          });
        }

        if (policiesSnapshot.exists()) {
          setPoliciesData(policiesSnapshot.val());
        } else {
          toast({
            type: 'error',
            text: 'Proteções não cadastradas',
          });
        }
      })
      .catch((error) => {
        console.error('Erro ao buscar dados:', error);
        toast({
          type: 'error',
          text: 'Erro ao buscar dados',
        });
      })
      .finally(() => {
        hideLoader();
      });
  }, [userId]);

  const goalMapName = {
    aposentadoria: 'Aposentadoria',
    compraImovel: 'Casa',
    educacaoFilhos: 'Ensino dos Filhos',
    car: 'Carro',
    outrosObjetivos: 'Outros',
    reservaEmergência: 'Reserva',
    viagensLazer: 'Viagens',
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  function calculateGoalProgress(goalData, goalName) {
    if (goalData.investments && goalData.investments.registeredContributions) {
      const totalContributions = goalData.investments.registeredContributions.reduce(
        (acc, curr) => acc + parseFloat(curr.ValorLiquido),
        0
      );
      const targetValue =
        goalName === 'reservaEmergência' ? goalData?.value : parseFloat(goalData.investments.targetValue) || 0;

      return (totalContributions / targetValue) * 100;
    }
    return 0;
  }

  function renderGoal(goalName, goalData) {
    const displayName = goalMapName[goalName] || goalName;
    const progress = calculateGoalProgress(goalData, goalName);
    let value = '';
    switch (goalName) {
      case 'aposentadoria':
        value = goalData.retirementAmount;
        break;
      case 'compraImovel':
        value = goalData.propertyValue;
        break;
      case 'educacaoFilhos':
        value = goalData.childCost;
        break;
      case 'car':
        value = goalData.valueCar;
        break;
      case 'outrosObjetivos':
        value = goalData.projectValue;
        break;
      case 'viagensLazer':
        value = goalData.travelCost;
        break;
      case 'reservaEmergência':
        value = goalData.value;
        break;
      default:
        return null;
    }

    return (
      <Grid container spacing={2} sx={{ maxWidth: '100%' }} key={goalName}>
        <Grid item xs={6} sx={{ maxHeight: '30%' }}>
          <Typography sx={{ color: '#FFF', fontSize: '0.875rem' }} variant="body2">
            {displayName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: '#FFF' }} variant="body2">
            {progress >= 100 ? 'Concluído' : `${progress.toFixed(2)}%`}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  const svgStyle = {};
  const totalRevenue = financialData.annualRevenue + parseFloat(financialData.otherRevenues || 0);
  const totalExpenses =
    parseFloat(financialData.averageFixedAnnualExpense || 0) +
    parseFloat(financialData.averageOccasionalExpense || 0) +
    parseFloat(financialData.averageVariableExpense || 0);
  const totalPatrimonio =
    parseFloat(patrimonioData.investments || 0) +
    parseFloat(patrimonioData.realEstate || 0) +
    parseFloat(patrimonioData.vehicles || 0);

  const totalGoalsAmount =
    parseFloat(goalsData.aposentadoria?.retirementAmount || 0) +
    parseFloat(goalsData.compraImovel?.propertyValue || 0) +
    parseFloat(goalsData.educacaoFilhos?.childCost || 0) +
    parseFloat(goalsData.outrosObjetivos?.projectValue || 0) +
    parseFloat(goalsData.reservaEmergência?.value || 0) +
    parseFloat(goalsData.viagensLazer?.cost || 0);

  const blockFolder = false;

  function renderAssetCategories() {
    return Object.entries(assetCategories).map(([category, totalValue]) => (
      <Grid container spacing={2} key={category} sx={{ maxWidth: '100%', mb: 1 }}>
        <Grid item xs={6}>
          <Typography sx={{ color: '#FFF', fontSize: '0.875rem' }} variant="body2">
            {category}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: '#FFF' }} variant="body2">
            {fCurrencyNumber(totalValue)}
          </Typography>
        </Grid>
      </Grid>
    ));
  }

  function renderPolicyCategories() {
    return Object.entries(policyCategories).map(([category, total]) => (
      <Grid container spacing={2} key={category} sx={{ maxWidth: '100%', mb: 1 }}>
        <Grid item xs={6}>
          <Typography sx={{ color: '#FFF', fontSize: '0.875rem' }} variant="body2">
            {category}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: '#FFF' }} variant="body2">
            {fCurrencyNumber(total)}
          </Typography>
        </Grid>
      </Grid>
    ));
  }

  function renderPolicy(policyKey, policyData) {
    return (
      <Grid container spacing={2} key={policyKey} sx={{ maxWidth: '100%', mb: 1 }}>
        <Grid item xs={6}>
          <Typography sx={{ color: '#FFF', fontSize: '0.875rem' }} variant="body2">
            {policyData.nome}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: '#FFF' }} variant="body2">
            Valor Apólice: {fCurrency(policyData.valorApolice)}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <CustomContainer
        id="testID"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          alignItems: 'flex-start',
          height: '100%',
          maxHeight: '40.75rem',
        }}
      >
        <Box sx={{ marginLeft: ['2vw', '3vw'], marginTop: '1.5rem' }}>
          <Grid container spacing={{ xs: 2, lg: 4 }}>
            <Grid container item xs={12} spacing={8}>
              <Grid item xs={4} sx={{ paddingLeft: 0 }}>
                <Link to={'/no-config'} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Stack direction="column" sx={{ marginTop: isMobile ? '0rem' : { lg: '2rem', xl: '3rem' } }}>
                    <Typography>Meu orçamento</Typography>
                    <Box
                      sx={{
                        width: {
                          xs: 230,
                          sm: 270,
                          md: 300,
                          lg: 330,
                          xl: 360,
                        },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: {
                          xs: '206px',
                          sm: '206px',
                          md: '206px',
                          lg: '180px',
                          xl: '180px',
                        },
                        borderRadius: '1rem',
                        backgroundColor: '#005BDA',
                      }}
                    >
                      <Typography sx={{ color: '#E6E6E6' }}>Ainda não implementado</Typography>
                    </Box>
                  </Stack>
                </Link>
              </Grid>
              <Grid item xs={4} sx={{ paddingLeft: 0 }}>
                <Link to={`/investments/${userId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Stack>
                    <Typography>Meus sonhos</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: {
                          xs: 230,
                          sm: 270,
                          md: 300,
                          lg: 330,
                          xl: 360,
                        },
                        height: {
                          xs: '206px',
                          sm: '206px',
                          md: '206px',
                          lg: '180px',
                          xl: '180px',
                        },
                        borderRadius: '1rem',
                        backgroundColor: '#005BDA',
                      }}
                    >
                      <Box component="div" alt="objetivos" sx={{ maxWidth: '100%', height: 'auto' }} />
                      <Box mb={3} ml={3} sx={{ maxWidth: '100%' }}>
                        {isVisible &&
                          Object.entries(goalsData).map(([goalName, goalData]) => renderGoal(goalName, goalData))}
                      </Box>
                    </Box>
                  </Stack>
                </Link>
              </Grid>
              <Grid item xs={4} sx={{ paddingLeft: 0 }}>
                <Link to={`/bens/${userId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Stack direction="column" sx={{ marginTop: isMobile ? '0rem' : { lg: '2rem', xl: '3rem' } }}>
                    <Typography>Meus bens</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: {
                          xs: 230,
                          sm: 270,
                          md: 300,
                          lg: 330,
                          xl: 360,
                        },
                        height: {
                          xs: '206px',
                          sm: '206px',
                          md: '206px',
                          lg: '180px',
                          xl: '180px',
                        },
                        borderRadius: '1rem',
                        backgroundColor: '#005BDA',
                      }}
                    >
                      <Box component="div" alt="objetivos" sx={{ maxWidth: '100%', height: 'auto' }} />
                      <Box mb={3} ml={2} sx={{ maxWidth: '100%' }}>
                        {renderAssetCategories()}
                      </Box>
                    </Box>
                  </Stack>
                </Link>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconButton onClick={toggleVisibility}>{isVisible ? <VisibilityOff /> : <Visibility />}</IconButton>
                <SvgColor sx={{ color: '#000', width: '6rem', height: '6rem' }} src={`/assets/icons/Avatar.svg`} />
              </Box>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Box display="flex" justifyContent="space-between" sx={{ width: '100%', marginTop: '-9rem' }}>
                <Link to={'/no-config'} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Stack>
                    <Typography>Minhas Dívidas</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: {
                          xs: 230,
                          sm: 270,
                          md: 300,
                          lg: 330,
                          xl: 360,
                        },
                        height: {
                          xs: '206px',
                          sm: '206px',
                          md: '206px',
                          lg: '180px',
                          xl: '180px',
                        },
                        borderRadius: '1rem',
                        backgroundColor: '#005BDA',
                      }}
                    >
                      <Typography sx={{ color: '#E6E6E6' }}>Ainda não implementado</Typography>
                    </Box>
                  </Stack>
                </Link>
                <Link to={`/apolices/${userId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Stack>
                    <Typography>Minhas proteções</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: {
                          xs: 230,
                          sm: 270,
                          md: 300,
                          lg: 330,
                          xl: 360,
                        },
                        height: {
                          xs: '206px',
                          sm: '206px',
                          md: '206px',
                          lg: '180px',
                          xl: '180px',
                        },
                        borderRadius: '1rem',
                        backgroundColor: '#005BDA',
                      }}
                    >
                      <Box component="div" alt="objetivos" sx={{ maxWidth: '100%', height: 'auto' }} />
                      <Box mb={3} ml={2} sx={{ maxWidth: '100%' }}>
                        {renderPolicyCategories()}
                      </Box>
                    </Box>
                  </Stack>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <GeneratePDFButton htmlElementId="testId" />
      </CustomContainer>
    </>
  );
}

export default Dashboard;
