import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
import { Box,Button, Container, Paper, Typography } from '@mui/material';
import BigFiveChart from './bigfive/chart/BigFiveChart';

function BigFiveProfiles() {
  const auth = getAuth();
  const [userId, setUserId] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [dependentProfile, setDependentProfile] = useState(null);
  const [userName, setUserName] = useState('Seu Perfil'); 
  const [dependentName, setDependentName] = useState('Perfil2'); 
  const [currentProfileView, setCurrentProfileView] = useState('ambos');

  useEffect(() => {
    if (!userId) return;

    const fetchData = async () => {
      const db = getDatabase();
      const userRef = ref(db, `users/${userId}`);
      const dependentRef = ref(db, `users/${userId}/dadosDependente`);

      const userSnapshot = await get(userRef);
      const dependentSnapshot = await get(dependentRef);

      if (userSnapshot.exists()) {
        setUserProfile(userSnapshot.val().bigFiveResults);
        setUserName(userSnapshot.val().name || 'Seu Perfil');
      }
      if (dependentSnapshot.exists()) {
        console.log(dependentSnapshot.val())
        setDependentProfile(dependentSnapshot.val().bigFiveResults);
        setDependentName(dependentSnapshot.val().name || 'Perfil2');
      }
    };

    fetchData();
  }, [userId]);

  const renderButtons = () => (
    <Box sx={{display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '2px'}}>
      <Button 
        size="large"
        variant="contained"
        color="secondary"
        onClick={() => setCurrentProfileView('perfil1')}
        sx={{ borderRadius: '15px'}}
      >
        {userName}
      </Button>

      <Button
        variant='contained'
        size='large'
        color ="primary"
        onClick={() => setCurrentProfileView('ambos')}
        sx={{borderRadius: '15px'}}
      >Ambos</Button>
      <Button
        variant='contained'
        color="secondary"
        onClick={() => setCurrentProfileView('perfil2')}
        sx={{borderRadius: '15px'}}
      >
        {dependentName}
      </Button>
    </Box>
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (!userId) return;

    const fetchData = async () => {
      const db = getDatabase();
      const userRef = ref(db, `users/${userId}/profile`);
      const dependentRef = ref(db, `users/${userId}/dependentes`);

      const userSnapshot = await get(userRef);
      const dependentSnapshot = await get(dependentRef);

      if (userSnapshot.exists()) {
        setUserProfile(userSnapshot.val().bigFiveResults);
      }

      if (dependentSnapshot.exists()) {
        setDependentProfile(dependentSnapshot.val().bigFiveResults);
      }
    };

    fetchData();
  }, [userId]);

  return (
    <Container 
      sx={{ 
        margin: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100vh',
        padding: '0px !important'
      }}
    >
      <Paper 
        elevation={5}
        square
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          borderRadius: '10px',
          overflow: 'hidden',
          maxHeight: '650px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: dependentProfile ? 'space-around' : 'center',  // 2. Centralizar quando tiver apenas um perfil
            alignItems: 'center',
            marginTop: '16px',
          }}
        >
          {dependentProfile && renderButtons()}
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-around',}}
        >
          <Box style={{ flex: 1}}>
          {(currentProfileView === 'perfil1' || currentProfileView === 'ambos') && (
            <>
              <Typography textAlign="center" variant='h3'>{userName}</Typography>
              <BigFiveChart results={userProfile} />
            </>
          )}
          </Box>

          <Box style={{ flex: 1 }}>
          {(dependentProfile && currentProfileView !== 'perfil1') && 
          (currentProfileView === 'perfil2' || currentProfileView === 'ambos') && (
            <>
              <Typography textAlign="center" variant='h3'>{dependentName}</Typography>
              <BigFiveChart results={dependentProfile} />
            </>
          )}

      </Box>
      </Box>
      </Paper>
    </Container>
  );
}

export default BigFiveProfiles;
