import React, { useEffect, useState } from 'react';
import { Container, Typography, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { set, ref, get } from 'firebase/database';
import { auth, database } from '../../services/firebase';
import toast from '../../utils/toast';
import CustomButton from '../button/CustomButton';

const DebtSection = ({userIdParams, onDataSaved}) => {
  const [userId, setUserId] = useState(null);
  const [debt, setDebt] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const userIdToUse = userIdParams || (user && user.uid);
      if (userIdToUse) {
        setUserId(userIdToUse);
        const userDebtRef = ref(database, `users/${userIdToUse}/goals/debt`);
        const debtSnapshot = await get(userDebtRef);
        if (debtSnapshot.exists()) {
          setDebt(debtSnapshot.val().debt || '');
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleDebtChange = (e) => {
    const { value } = e.target;
    setDebt(value);
    setIsEmpty(!value);
  };

  const handleSave = async () => {
    if (!userId) return;
    try {
      const userDebtRef = ref(database, `users/${userId}/goals/debt`);
      await set(userDebtRef, { debt });
      toast({
        type: 'success',
        text: 'Dados de dívida salvos com sucesso!'
      });
      onDataSaved();
    } catch (error) {
      toast({
        type: 'error',
        text: 'Erro ao salvar dados de dívida!'
      });
    }
  };

  return (
    <Container sx={{ marginBottom: '2rem', marginTop: '2rem' }}>
      <Typography 
        sx={{
          fontSize: '1.5625rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 1.7
        }}  
        >
          Dívidas
        </Typography>
      <Typography variant="body1">
        Pagamento de dívidas: Você pretende planejar e estruturar uma estratégia para pagar dívidas existentes, como empréstimos estudantis, financiamentos imobiliários ou cartões de crédito?
      </Typography>
      <RadioGroup name="haveDebt" value={debt} onChange={handleDebtChange}>
        <FormControlLabel value="yes" control={<Radio />} label="Sim" />
        <FormControlLabel value="no" control={<Radio />} label="Não" />
      </RadioGroup>
      <CustomButton fullWidth variant="primary"onClick={handleSave}>
        Salvar
      </CustomButton>
    </Container>
  );
};

export default DebtSection;
