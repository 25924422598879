import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { signOut , onAuthStateChanged } from "firebase/auth";
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box,Button, Link, Drawer, Typography, Avatar  } from '@mui/material';
// mock
import { ref, get } from 'firebase/database';
// hooks
import useResponsive from '../../../hooks/useResponsive';


// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import { auth, database } from '../../../services/firebase';
import OverallProgress from '../../../components/progress/OveralProgress';


// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

const [userData, setUserData] = useState('');
  const navigate = useNavigate()
  const [userProfile, setUserProfile] = useState('');
  const [userInformation, setUserInformation] = useState('');
  const [overallProgress, setOverallProgress] = useState(0);
  const [userRole, setUserRole] = useState('');

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const userRef = ref(database, `users/${userId}`);
        const snapshotUser = await get(userRef);

        if (snapshotUser.exists()) {
          const userData = snapshotUser.val();
          setUserData(userData);
        }
        
      } catch (error) {
        console.error(error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if(user) {
        fetchUserData(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const fetchUserProfile = async (userId) => {
      try {
          const userProfileRef = ref(database, `users/${userId}/profile`);
          const snapshot = await get(userProfileRef);
          if (snapshot.exists()) {
            const userProfile = snapshot.val();
            setUserProfile(userProfile.profile);
          }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUserInformation = async (userId) => {
      try {
          const userInformationRef = ref(database, `users/${userId}/dadosCadastrais`);
          const snapshot = await get(userInformationRef);
          if (snapshot.exists()) {
            const userInformation = snapshot.val();
            setUserInformation(userInformation);
          }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUserRole = async (userId) => {
      try {
          const userRoleRef = ref(database, `users/${userId}/role`);
          const snapshot = await get(userRoleRef);
          if (snapshot.exists()) {
            const userRole = snapshot.val();
            setUserRole(userRole);
          }
      } catch (error) {
        console.error(error);
      }
    };

    calculateOverallProgress().then((progress) => {
      setOverallProgress(progress);
    }).catch((error) => {
      console.error("Erro ao calcular o progresso geral:", error);
    });

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserProfile(user.uid);
        fetchUserInformation(user.uid);
        fetchUserRole(user.uid);
        calculateOverallProgress(user.uid).then((progress) => {
          setOverallProgress(progress);
        }).catch((error) => {
          console.error("Erro ao calcular o progresso assc:", error);
        });
      } else {
        console.log('No user is signed in');
      }
    });

    return unsubscribe;
  }, []);


  const calculateOverallProgress = (userId) => {
    const userGoalsRef = ref(database, `users/${userId}/goals`);
  
    return get(userGoalsRef).then((snapshot) => {
      const userGoals = snapshot.val();
  
      let overallProgress = 0;
      let totalGoals = 0;
  
      // Verifica se há objetivos definidos para o usuário
      if (userGoals) {
        const goals = Object.keys(userGoals);
  
        // Itera sobre cada objetivo
        goals.forEach((goal) => {
          const goalData = userGoals[goal];
  
          // Verifica se o objetivo possui contribuições registradas
          if (goalData.investments && goalData.investments.registeredContributions) {
            const {registeredContributions} = goalData.investments;
  
            // Calcula o progresso do objetivo com base nas contribuições registradas
            const requiredContributions = goalData.investments.requiredContributions || 0;
            const totalContributions = registeredContributions.reduce((total, contribution) => total + parseFloat(contribution.investmentValue), 0);
  
            const progress = (totalContributions / requiredContributions) * 100;
  
            // Atualiza o progresso geral e o número total de objetivos
            overallProgress += progress;
            totalGoals+=1;
          }
        });
      }
      // Calcula a média do progresso geral
      const averageProgress = overallProgress / totalGoals;
  
      // Retorna o progresso geral arredondado para duas casas decimais
      return Math.round(averageProgress * 100) / 100;
    });
  };
  
  // Exemplo de uso:
  calculateOverallProgress().then((progress) => {
    console.log(`Progresso geral do usuário: ${progress}%`);
  }).catch((error) => {
    console.error("Erro ao calcular o progresso geral:", error);
  });
  

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      
      {userRole === 'cliente' && (
      <Box sx={{ mb: 5, mx: 2.0 }}>
        <Link underline="none">
          <StyledAccount>

          <Box display='flex' gap={1}>
          <Avatar>{userData.name ? userData.name.charAt(0) : ''}</Avatar>
            <Box>
              <Typography variant="body2"mt={1} sx={{ color: 'text.secondary', padding: 0 }}>
                Perfil: {userProfile}
              </Typography>
              <Button sx={{margin: 0, padding: 0}}onClick={() => 
                navigate('/profile')
              }>
                Refazer Questionário
              </Button>
            </Box>
          </Box>
        </StyledAccount>
        </Link>
      </Box>
      )}

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 0.5 }} />
      
      { userRole === 'cliente' && (
        <Box sx={{maxWidth: 250, margin: '0 auto'}}>
        <OverallProgress/>
        </Box>
      )}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
