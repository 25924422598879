import React, { useState, useEffect, useContext } from 'react';
import { getDatabase, ref, set, onValue, push, remove, update } from 'firebase/database';
import { Typography, Container, Grid, Stack, TextField, Select, MenuItem, IconButton, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { DataGrid } from '@mui/x-data-grid';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { fCurrency, fCurrencyNumber, unformatedValue } from '../utils/formatNumber';
import CustomButton from './button/CustomButton';
import { ThemeToggleContext } from '../theme';
import toast from '../utils/toast'; // Import the toast function

const PoliciesTable = () => {
  const [policies, setPolicies] = useState([]);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const db = getDatabase();
  const { setMode } = useContext(ThemeToggleContext);
  const { clientId } = useParams(); // Get clientId from URL params

  useEffect(() => {
    // Configura o modo para claro quando o componente monta
    setMode('light');

    return () => {
      setMode('dark'); // Volta o tema para "dark"
    };
  }, [setMode]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(clientId || user.uid);
        const policiesRef = ref(db, `users/${clientId || user.uid}/policies`);
        onValue(policiesRef, (snapshot) => {
          const data = snapshot.val() || {};
          setPolicies(Object.entries(data).map(([key, value]) => ({ id: key, ...value })));
        });
      }
    });
  }, [db, clientId]);

  const addPolicy = () => {
    setPolicies((prevPolicies) => [
      ...prevPolicies,
      {
        id: uuidv4(),
        nome: '',
        seguradora: '',
        premioMensal: '',
        valorApolice: '',
        dataContratacao: '',
        vencimento: '',
        categoria: '',
      },
    ]);
  };

  const handleInputChange = (id, field, value) => {
    const newValue = field.includes('valor') || field.includes('premio') ? unformatedValue(value) : value;
    setPolicies((prevPolicies) =>
      prevPolicies.map((policy) => (policy.id === id ? { ...policy, [field]: newValue } : policy))
    );
  };

  const savePolicy = (policy) => {
    if (policy.id) {
      update(ref(db, `users/${userId}/policies/${policy.id}`), policy)
        .then(() => {
          toast({
            type: 'success',
            text: 'Apólice atualizada com sucesso.',
            duration: 4000,
          });
        })
        .catch((error) => {
          toast({
            type: 'error',
            text: 'Erro ao atualizar apólice.',
            duration: 4000,
          });
        });
    } else {
      const newRef = push(ref(db, `users/${userId}/policies`));
      policy.id = newRef.key;
      set(newRef, policy)
        .then(() => {
          toast({
            type: 'success',
            text: 'Apólice salva com sucesso.',
            duration: 4000,
          });
        })
        .catch((error) => {
          toast({
            type: 'error',
            text: 'Erro ao salvar apólice.',
            duration: 4000,
          });
        });
    }
  };

  const deletePolicy = (id) => {
    remove(ref(db, `users/${userId}/policies/${id}`))
      .then(() => {
        setPolicies((prevPolicies) => prevPolicies.filter((policy) => policy.id !== id));
        toast({
          type: 'success',
          text: 'Apólice deletada com sucesso.',
          duration: 4000,
        });
      })
      .catch((error) => {
        toast({
          type: 'error',
          text: 'Erro ao deletar apólice.',
          duration: 4000,
        });
      });
  };

  // Calculando os totais diretamente no JSX, excluindo linhas em branco
  const totalValue = policies.reduce(
    (acc, policy) => acc + (policy.valorApolice.trim() ? Number(unformatedValue(policy.valorApolice)) : 0),
    0
  );
  const totalPremium = policies.reduce(
    (acc, policy) => acc + (policy.premioMensal.trim() ? Number(unformatedValue(policy.premioMensal)) : 0),
    0
  );

  const handleGoBack = () => {
    navigate(-1);
  };
  const quantidadeApolices = policies.length;

  const columns = [
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 1,
      renderCell: (params) => (
        <TextField
          variant="standard"
          value={params.value}
          onChange={(e) => handleInputChange(params.row.id, 'nome', e.target.value)}
          fullWidth
          InputProps={{
            style: { fontSize: '0.8rem' },
          }}
        />
      ),
    },
    {
      field: 'seguradora',
      headerName: 'Seguradora',
      flex: 1,
      renderCell: (params) => (
        <TextField
          variant="standard"
          value={params.value}
          onChange={(e) => handleInputChange(params.row.id, 'seguradora', e.target.value)}
          fullWidth
          InputProps={{
            style: { fontSize: '0.8rem' },
          }}
        />
      ),
    },
    {
      field: 'premioMensal',
      headerName: 'Prêmio Mensal',
      flex: 1,
      renderCell: (params) => (
        <TextField
          variant="standard"
          value={fCurrency(params.value || 0)}
          onChange={(e) => handleInputChange(params.row.id, 'premioMensal', e.target.value)}
          fullWidth
          InputProps={{
            style: { fontSize: '0.8rem' },
          }}
        />
      ),
    },
    {
      field: 'valorApolice',
      headerName: 'Valor da Apólice',
      flex: 1,
      renderCell: (params) => (
        <TextField
          variant="standard"
          value={fCurrency(params.value || 0)}
          onChange={(e) => handleInputChange(params.row.id, 'valorApolice', e.target.value)}
          fullWidth
          InputProps={{
            style: { fontSize: '0.8rem' },
          }}
        />
      ),
    },
    {
      field: 'dataContratacao',
      headerName: 'Data da Contratação',
      flex: 1,
      renderCell: (params) => (
        <TextField
          variant="standard"
          type="date"
          value={params.value}
          onChange={(e) => handleInputChange(params.row.id, 'dataContratacao', e.target.value)}
          InputLabelProps={{ shrink: true }}
          fullWidth
          InputProps={{
            style: { fontSize: '0.8rem' },
          }}
        />
      ),
    },
    {
      field: 'vencimento',
      headerName: 'Vencimento',
      flex: 1,
      renderCell: (params) => (
        <TextField
          variant="standard"
          type="date"
          value={params.value}
          onChange={(e) => handleInputChange(params.row.id, 'vencimento', e.target.value)}
          InputLabelProps={{ shrink: true }}
          fullWidth
          InputProps={{
            style: { fontSize: '0.8rem' },
          }}
        />
      ),
    },
    {
      field: 'categoria',
      headerName: 'Categoria do Seguro',
      flex: 1,
      renderCell: (params) => (
        <Select
          variant="standard"
          value={params.value}
          onChange={(e) => handleInputChange(params.row.id, 'categoria', e.target.value)}
          displayEmpty
          fullWidth
          sx={{ fontSize: '0.8rem' }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Seguro de vida">Seguro de vida</MenuItem>
          <MenuItem value="Seguro de imóveis">Seguro de imóveis</MenuItem>
          <MenuItem value="Seguro de veículos">Seguro de veículos</MenuItem>
          <MenuItem value="Seguro de bens">Seguro de bens</MenuItem>
        </Select>
      ),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => savePolicy(params.row)}>
            <SaveIcon />
          </IconButton>
          <IconButton onClick={() => deletePolicy(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Container maxWidth={false} sx={{ px: 8 }}>
      <Grid container spacing={1} sx={{ marginTop: '0.9rem', width: '100%' }}>
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          <Typography
            textAlign="center"
            sx={{ fontSize: '1.6rem', fontFamily: 'DM Sans', alignItems: 'center', mb: 4 }}
          >
            Minhas Apólices
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              height: '100%',
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                background: '#FFF',
                alignItems: 'center',
                borderRadius: '12.625rem',
                border: '1px solid #000',
                width: ' 16rem',
                height: '5.54913rem',
                flexShrink: 0,
              }}
            >
              <Typography textAlign="center" sx={{ color: '#000', fontSize: '1rem' }}>
                {' '}
                Total das Apólices
              </Typography>
              <Typography textAlign="center" sx={{ color: '#000', fontSize: '1.3rem' }}>
                {fCurrencyNumber(totalValue)}
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                background: '#FFF',
                alignItems: 'center',
                borderRadius: '12.625rem',
                border: '1px solid #000',
                width: ' 16rem',
                height: '5.54913rem',
                flexShrink: 0,
              }}
            >
              <Typography textAlign="center" sx={{ color: '#000', fontSize: '1rem' }}>
                {' '}
                Total de bens segurados
              </Typography>
              <Typography textAlign="center" sx={{ color: '#000', fontSize: '1.3rem' }}>
                {quantidadeApolices}
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                background: '#FFF',
                alignItems: 'center',
                borderRadius: '12.625rem',
                border: '1px solid #000',
                width: ' 16rem',
                height: '5.54913rem',
                flexShrink: 0,
              }}
            >
              <Typography textAlign="center" sx={{ color: '#000', fontSize: '1rem' }}>
                {' '}
                Total de prêmios mensais
              </Typography>
              <Typography textAlign="center" sx={{ color: '#000', fontSize: '1.3rem' }}>
                {fCurrencyNumber(totalPremium)}
              </Typography>
            </div>

            <Stack direction="row" spacing={1}>
              <CustomButton
                sx={{
                  width: '150px',
                }}
                onClick={handleGoBack}
              >
                Voltar
              </CustomButton>
            </Stack>
          </div>
        </Grid>
        <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
          <Box display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
            <IconButton onClick={addPolicy}>
              <AddIcon />
            </IconButton>
          </Box>
          <div style={{ height: 600, width: '100%' }}>
            <DataGrid
              rows={policies}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
              autoHeight
              components={{
                NoRowsOverlay: () => <Typography>Nenhuma apólice cadastrada.</Typography>,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PoliciesTable;
