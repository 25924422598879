export const investmentCategories = [
  'Credito Privado',
  'Titulo Bancário',
  'Titulo Publico',
  'Fundos de Renda Fixa',
  'Fundos Multimercado',
  'Fundos de Ação',
  'Fundos Cambial',
  'Ações',
  'Derivativos',
  'Fundos imobiliários',
  'Offshore',
  'Investimentos Alternativos',
];

export const investmentColors = {
  'Credito Privado': '#AF93FF',
  'Titulo Bancário': '#22FFFF',
  'Titulo Publico': '#8CAFFF',
  'Fundos de Renda Fixa': '#FF93B2',
  'Fundos Multimercado': '#FFC793',
  'Fundos de Ação': '#93FFAF',
  'Fundos Cambial': '#FFAF93',
  Ações: '#93D4FF',
  Derivativos: '#FF93E6',
  'Fundos imobiliários': '#FFD493',
  Offshore: '#B2FF93',
  'Investimentos Alternativos': '#93FFD4',
};

export const mapGoalsName = {
  aposentadoria: 'Aposentadoria',
  compraImovel: 'Imóvel',
  car: 'Carro',
  divida: 'Dívida',
  educacaoFilhos: 'Educação dos Filhos',
  educacaoUniversitaria: 'Faculdade dos Filhos',
  outrosObjetivos: 'Outros objetivos',
  viagensLazer: 'Viagens e Lazer',
  reservaEmergência: 'Reserva de Emergência',
};

export const translateGoalsName = {
  aposentadoria: 'retirement',
  compraImovel: 'homePurchase',
  divida: 'debt',
  educacaoFilhos: 'childrenEducation',
  car: 'car',
  outrosObjetivos: 'otherGoals',
  viagensLazer: 'travelLeisure',
  reservaEmergência: 'emergencyFund',
};

export const englishGoalsName = {
  retirement: 'aposentadoria',
  homePurchase: 'Compra de Imóvel',
  debt: 'Dívida',
  childrenEducation: 'Educação dos Filhos',
  car: 'Carro',
  otherGoals: 'Outros objetivos',
  travelLeisure: 'Viagem e Lazer',
  emergencyFund: 'Reserva de emergência',
  addCustomGoal: 'Adicionar objetivo extra',
  box: 'Caixa',
};
