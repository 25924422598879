import React, { useState, useEffect } from 'react';
import b5 from '@alheimsins/b5-johnson-120-ipip-neo-pi-r';
import calculateScore from '@alheimsins/bigfive-calculate-score';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, get, getDatabase } from 'firebase/database';
import CircularProgressWithLabel from '../components/CircularProgress';
import Question from '../components/profile/bigfive/Questions';
import BigFiveResults from '../components/profile/bigfive/BigFiveResults';
import StandardLayout from '../layouts/standard/StandardLayout';
import CustomButton from '../components/button/CustomButton';

import { useUser } from '../context/UserContext';

function BigFiveTest(props) {
  const auth = getAuth();
  const [userId, setUserId] = useState(null);
  const questions = b5.getItems('pt-br') || [];
  const [answers, setAnswers] = useState({});
  const [results, setResults] = useState(null);
  const [scores, setScores] = useState(null);
  const [isResult, setIsResult] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const questionsPerPage = 2;
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [isRedo, setIsRedo] = useState(false); // Novo estado para refazer o perfil

  const { role, currentUser } = useUser();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (role === 'admin') {
          setUserId(currentUser);
        } else {
          setUserId(user.uid);
        }
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, [role, currentUser]);

  useEffect(() => {
    const fetchResultsFromDB = async () => {
      const pathToFirebase = props.isDependent ? `users/${userId}/dependentes` : `users/${userId}/profile`;
      const userProfileRef = ref(getDatabase(), pathToFirebase);
      const snapshot = await get(userProfileRef);

      if (snapshot.exists() && snapshot.val().bigFiveResults && !isRedo) {
        setIsResult(true);
        const results = snapshot.val().bigFiveResults;
        setResults(results);
        const scores = calculateScore({
          answers: results.map((r) => ({
            questionId: r.questionId,
            score: r.score,
            domain: r.domain,
            facet: r.facet,
          })),
        });
        setScores(scores);
      }
    };

    if (userId) {
      setLoading(true);
      fetchResultsFromDB();
      setLoading(false);
    }
  }, [userId, props.isDependent, isRedo]); // Adiciona isRedo como dependência

  useEffect(() => {
    const answeredQuestions = Object.keys(answers).length;
    const totalQuestions = questions.length;
    const newProgress = (answeredQuestions / totalQuestions) * 100;
    setProgress(newProgress);

    const currentQs = getCurrentQuestions();
    const allAnswered = currentQs.every((q) => answers[q.id] !== undefined);
    setCanProceed(allAnswered);
  }, [answers]);

  const handleAnswerChange = (questionId, score) => {
    setAnswers((prev) => ({ ...prev, [questionId]: Number(score) }));
  };

  const handleGenerateRandomAnswers = () => {
    const randomAnswers = generateRandomAnswers(questions);
    setAnswers(randomAnswers);
  };

  function generateRandomAnswers(questions) {
    const randomAnswers = {};

    questions.forEach((question) => {
      const randomAnswer = Math.floor(Math.random() * 5) + 1;
      randomAnswers[question.id] = randomAnswer;
    });

    return randomAnswers;
  }

  const handleSubmit = () => {
    if (Object.keys(answers).length !== questions.length) {
      alert('Por favor, responda a todas as perguntas antes de continuar.');
      return;
    }
    if (!isResult || isRedo) {
      const answersArray = Object.keys(answers).map((questionId) => {
        const question = questions.find((q) => q.id === questionId);
        if (!question) {
          throw new Error(`No question found with ID ${questionId}`);
        }
        return {
          questionId,
          score: answers[questionId],
          domain: question.domain,
          facet: question.facet,
        };
      });

      const inputForScoreCalculation = { answers: answersArray };

      const scores = calculateScore(inputForScoreCalculation);
      setScores(scores);
      setIsRedo(false); // Reset isRedo
    }
  };

  const handleRedoProfile = () => {
    setIsRedo(true);
    setIsResult(false);
    setScores(null);
    setAnswers({});
    setCurrentPage(0);
  };

  const nextQuestions = () => {
    setCurrentPage(currentPage + 1);
    setCanProceed(false);
  };
  const previousQuestions = () => {
    setCurrentPage(currentPage - 1);
    setCanProceed(true);
  };
  const getCurrentQuestions = () => {
    const start = currentPage * questionsPerPage;
    const end = start + questionsPerPage;
    return questions.slice(start, end);
  };

  const hasNextPage = currentPage < Math.ceil(questions.length / questionsPerPage) - 1;
  const hasPreviousPage = currentPage > 0;

  if (loading) {
    return <CircularProgress />;
  }

  if (scores) {
    return (
      <BigFiveResults
        scores={scores}
        isResult={isResult}
        result={results}
        startAddingDependent={props.startAddingDependent}
        isDependent={props.isDependent}
        onDisplayProfile={props.onDisplayProfile}
        onNext={props.changeTab}
        onRedoProfile={handleRedoProfile} // Passa a função handleRedoProfile como prop
      />
    );
  }

  const leftComponent = (
    <>
      <button onClick={handleGenerateRandomAnswers}>Gerar Respostas Aleatórias</button>
      <Button variant="contained" color="secondary" onClick={handleSubmit}>
        Ver Resultados
      </Button>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          marginTop: '0.585rem',
          gap: 4,
        }}
      >
        <Typography sx={{ fontSize: '2.1rem', fontWeight: 'bold', fontFamily: 'TimesNow-Light' }} textAlign="center">
          Construindo seu gêmeo digital
        </Typography>
        <CircularProgressWithLabel value={progress} />
      </div>
    </>
  );
  const currentQuestions = getCurrentQuestions();
  const rightComponent = (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginTop: '4rem',
        }}
      >
        {currentQuestions.map((question, idx) => (
          <Question
            index={currentPage * questionsPerPage + idx}
            key={idx}
            question={question}
            selectedAnswer={answers[question.id]}
            onAnswerChange={handleAnswerChange}
          />
        ))}

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '40%' }}>
          <CustomButton
            variant={!hasPreviousPage ? 'disabled' : 'secondary'}
            size="small"
            sx={{ fontSize: '1rem', width: '150px', height: '40px' }}
            disabled={!hasPreviousPage}
            onClick={previousQuestions}
          >
            Voltar
          </CustomButton>

          {hasNextPage ? (
            <CustomButton
              size="small"
              sx={{ fontSize: '1rem', width: '150px', height: '40px' }}
              variant={!hasNextPage || !canProceed ? 'disabled' : 'primary'}
              onClick={nextQuestions}
            >
              Próximo
            </CustomButton>
          ) : (
            <CustomButton
              size="small"
              variant="primary"
              sx={{ fontSize: '1rem', width: '150px', height: '40px' }}
              onClick={handleSubmit}
            >
              Ver Resultados
            </CustomButton>
          )}
        </div>
      </div>
    </>
  );

  return <StandardLayout leftComponent={leftComponent} rightComponent={rightComponent} propMargin="0px" isBackground />;
}

export default BigFiveTest;
