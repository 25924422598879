import { getDatabase, ref, get, push } from 'firebase/database';
import { format } from 'date-fns';

export const calculateAndSaveAchievements = async (userId, goals) => {
  const db = getDatabase();
  const achievementsRef = ref(db, `users/${userId}/achievements`);
  const snapshot = await get(achievementsRef);

  const currentAchievements = snapshot.exists() ? snapshot.val() : {};

  let totalContributions = 0;
  let totalValueContributed = 0;
  const totalGoals = Object.keys(goals).length;
  const totalProtectionPercentage = 0; // Suponha que você tenha um cálculo para isso

  Object.values(goals).forEach((goal) => {
    if (goal.investments && goal.investments.registeredContributions) {
      totalContributions += goal.investments.registeredContributions.length;
      totalValueContributed += goal.investments.registeredContributions.reduce(
        (acc, contribution) => acc + parseFloat(contribution.ValorLiquido),
        0
      );
    }
  });

  const newAchievements = [];
  const currentDate = format(new Date(), 'yyyy-MM-dd');

  const addAchievementIfNew = (type, rank) => {
    const existingAchievement = Object.values(currentAchievements).find((achievement) => achievement.rank === rank);
    if (!existingAchievement) {
      newAchievements.push({
        type,
        rank,
        dateAchieved: currentDate,
      });
    }
  };

  const contributionRank = getContributionRank(totalContributions);
  addAchievementIfNew('totalContributions', contributionRank);

  const valueRank = getValueRank(totalValueContributed);
  addAchievementIfNew('totalValueContributed', valueRank);

  const goalRank = getGoalRank(totalGoals);
  addAchievementIfNew('totalGoals', goalRank);

  const protectionRank = getProtectionRank(totalProtectionPercentage);
  addAchievementIfNew('totalProtectionPercentage', protectionRank);

  const pushPromises = newAchievements.map((achievement) => push(achievementsRef, achievement));
  await Promise.all(pushPromises);
};

const getContributionRank = (totalContributions) => {
  if (totalContributions >= 50) return 'Mestre dos Aportes';
  if (totalContributions >= 20) return 'Especialista em Aportes';
  if (totalContributions >= 10) return 'Aportador Dedicado';
  if (totalContributions >= 5) return 'Investidor em Ascensão';
  return 'Novato do Investimento';
};

const getValueRank = (totalValue) => {
  if (totalValue >= 1000000) return 'Investidor Qualificado';
  if (totalValue >= 500000) return 'Magnata Financeiro';
  if (totalValue >= 300000) return 'Acumulador';
  if (totalValue >= 100000) return 'Especialista dos Investimentos';
  if (totalValue >= 50000) return 'Investidor Estratégico';
  if (totalValue >= 10000) return 'Investidor Disciplinado';
  return 'Investidor Consciente';
};

const getGoalRank = (totalGoals) => {
  if (totalGoals >= 10) return 'Sonhador Estrategista';
  if (totalGoals >= 5) return 'Diversificador';
  return 'O Focado';
};

const getProtectionRank = (protectionPercentage) => {
  if (protectionPercentage >= 150) return 'Superprotetor';
  if (protectionPercentage >= 100) return 'Seguro Total';
  return 'Conservador';
};
