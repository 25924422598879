import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, set, get, child } from 'firebase/database';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';
import FinancialDataForm from './FinancialDataForm';
import toast from '../../utils/toast';

function Financial({ changeTab }) {
  const [financialData, setFinancialData] = useState({});
  const [loading, setLoading] = useState(true);

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!userId) return;

    setLoading(true);
    const db = getDatabase();
    const financialDataRef = ref(db, `users/${userId}/financial`);
    get(child(financialDataRef, '/'))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setFinancialData(snapshot.val());
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId]);

  if (loading) {
    return <div>Carregando...</div>; // ou qualquer outro componente/loading spinner que você prefira
  }

  const handleFinancialDataSubmit = (data) => {
    setFinancialData(data);

    // Obter uma referência ao banco de dados do Firebase
    const db = getDatabase();
    const financialDataRef = ref(db, `users/${data.userId}/financial`);

    // Salvar os dados financeiros no Firebase
    set(financialDataRef, {
      monthlyIncome: data.monthlyIncome || 0,
      monthlyExpense: data.monthlyExpense || 0,
      totalInvestments: data.totalInvestments || 0,
      hasDebt: data.hasDebt,
      totalDebt: data.totalDebt || 0,
    })
      .then(() => {
        toast({
          type: 'success',
          text: 'Dados financeiros registrados com sucesso!',
        });
        changeTab();
      })
      .catch(() => {
        toast({
          type: 'error',
          text: 'Erro ao salvar os dados financeiros!',
        });
      });
  };

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '2rem',
        }}
      >
        <FinancialDataForm onSubmit={handleFinancialDataSubmit} initialData={financialData} handleNextTab={changeTab} />
      </Container>
    </>
  );
}

export default Financial;

Financial.propTypes = {
  changeTab: PropTypes.func.isRequired,
};
