import React, { useState, useEffect } from 'react';
import { Container, Typography, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { set, ref, get } from 'firebase/database';
import { auth, database} from '../../services/firebase';
import toast from '../../utils/toast';
import CustomButton from '../button/CustomButton';

const SuccessionSection = ({userIdParams}) => {
  const [userId, setUserId] = useState(null);
  const [succession, setSuccession] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const userIdToUse = userIdParams || (user && user.uid);
      if (userIdToUse) {
        setUserId(userIdToUse);
        const userSuccessionRef = ref(database, `users/${userIdToUse}/goals/succession`);
        const successionSnapshot = await get(userSuccessionRef);
        if (successionSnapshot.exists()) {
          setSuccession(successionSnapshot.val().succession || '');
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSuccessionChange = (e) => {
    const {value} = e.target;
    setSuccession(value);
    setIsEmpty(!value);
  };

  const handleSave = async () => {
    if (!userId) return;
    try {
      const userSuccessionRef = ref(database, `users/${userId}/goals/succession`);
      await set(userSuccessionRef, { succession });
      toast({
        type: 'success',
        text: 'Dados de sucessão patrimonial salvos com sucesso!'
      });
    } catch (error) {
      toast({
        type: 'error',
        text: 'Erro ao salvar dados de sucessão patrimonial'
      });
      console.error('Erro ao salvar dados de sucessão patrimonial:', error);
    }
  };

  return (
    <Container sx={{marginBottom: '2rem', marginTop: '2rem'}}>
      <Typography 
        sx={{
          fontSize: '1.5625rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 1.7
        }}
        >
          Sucessão Patrimonial
        </Typography>
      <Typography variant="body1">
        Sucessão patrimonial: Você pretende planejar a transferência de patrimônio de forma eficiente para a próxima geração, minimizando impactos fiscais e protegendo os interesses da família?
      </Typography>
      <RadioGroup name="succession" value={succession} onChange={handleSuccessionChange}>
        <FormControlLabel value="yes" control={<Radio />} label="Sim" />
        <FormControlLabel value="no" control={<Radio />} label="Não" />
      </RadioGroup>
      <CustomButton fullWidth variant="primary" onClick={handleSave}>
        Salvar
      </CustomButton>
    </Container>
  );
};
export default SuccessionSection;
