import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography, IconButton, Container } from '@mui/material';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import { ref, getDatabase, runTransaction, onValue } from 'firebase/database';
import { auth } from '../../services/firebase';
import { fCurrencyNumber } from '../../utils/formatNumber';
import CustomButton from '../button/CustomButton';

function CashBoxForm({ userIdParams, onDataSaved }) {
  const [amount, setAmount] = useState('');
  const [currentBalance, setCurrentBalance] = useState(0); // State to store current balance
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      const uid = user ? user.uid : userIdParams;
      setUserId(uid);
      if (uid) {
        const cashBoxRef = ref(getDatabase(), `users/${uid}/cashBoxAmount`);
        // Subscribe to balance changes
        onValue(cashBoxRef, (snapshot) => {
          const balance = snapshot.val() || 0;
          setCurrentBalance(balance);
        });
      }
    });
    return () => unsubscribe();
  }, [userIdParams]);

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const updateCashBox = (operation) => {
    if (!userId) {
      console.error('No user id available');
      return;
    }
    const cashBoxRef = ref(getDatabase(), `users/${userId}/cashBoxAmount`);
    const contributionsRef = ref(getDatabase(), `users/${userId}/contributionsCount`);
    runTransaction(cashBoxRef, (currentValue) => {
      const numericAmount = Number(amount);
      if (!numericAmount) {
        console.error('Invalid amount:', amount);
        return undefined; // Abort transaction
      }
      currentValue = currentValue || 0;
      return operation === 'add' ? currentValue + numericAmount : currentValue - numericAmount;
    })
      .then(() => {
        console.log(operation);
        console.log('Transaction completed!');

        if (operation === 'add') {
          console.log(operation);
          runTransaction(contributionsRef, (currentValue) => {
            return (currentValue || 0) + 1;
          });
        }
      })
      .catch((error) => {
        console.error('Transaction failed: ', error);
      });
  };

  const handleAdd = () => updateCashBox('add');
  const handleSubtract = () => updateCashBox('subtract');

  return (
    <Container sx={{ marginTop: '2rem', height: '50vh', position: 'relative' }}>
      <Typography variant="h6" gutterBottom>
        Sobrou dinheiro? Recebeu recursos extras?
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Você vai fazer um investimento ou um aporte e não sabe a melhor forma de utilizar esse aporte? Não se preocupe,
        coloque nesta caixa que vamos te orientar sobre a melhor forma de aplicação.
      </Typography>
      <Typography sx={{ mb: 1, fontSize: '1.2rem', fontWeight: 'bold' }}>
        Saldo Atual: {fCurrencyNumber(currentBalance)} {/* Display current balance */}
      </Typography>
      <Typography variant="body2" sx={{ mb: 1 }}>
        Qual valor deseja adicionar ou retirar da caixa?
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          label="Valor"
          variant="outlined"
          value={amount}
          onChange={handleAmountChange}
          fullWidth
          type="number"
        />
        <IconButton onClick={handleAdd} sx={{ color: '#E6E6E6' }} aria-label="add">
          <Add sx={{ width: '3rem', height: '3rem' }} />
        </IconButton>
        <IconButton onClick={handleSubtract} sx={{ color: '#E6E6E6' }} aria-label="subtract">
          <Remove sx={{ width: '3rem', height: '3rem' }} />
        </IconButton>
      </Box>

      <CustomButton sx={{ position: 'absolute', bottom: '2rem', right: '2rem', width: '150px' }} onClick={onDataSaved}>
        Voltar
      </CustomButton>
    </Container>
  );
}

export default CashBoxForm;
